import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios';
import { Wrapper, Label, LeftDiv, Spacer, TextFieldLabel, MessageLabel, TextFieldSmall, Th, Td, TextField, TextArea, SelectField, Row, Column, RightDiv, SectionHeader, Button, SectionLabel, MandatoryLabel } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import swal from "sweetalert";
import styled from 'styled-components';
import Loader from './Loader';

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
`;

const TableContainer = styled.div`
  white-space: normal;
`;

const TableLayout = styled(TableContainer)`
  overflow: auto;
  overflow-y: hidden;
`;


const ContentDiv=styled.div`
  width: 80vw;
  overflow-y: auto;
  padding-bottom: 10px;
`;

const Table = styled.table`
  border-collapse: collapse; 
  table-layout: fixed;
  min-width: 100%;
`;


const TableData = [
    {'policy_name': 'Trust All', 'trusted': ['All Network Traffic'], 'untrusted': ['None'], 'usage_description': 'All features are accessible from all networks. \n The access control is done through the network itself such as firewalls and geteway but not by SCORER Edge.'},
    {'policy_name': 'Trust traffic on specific interfaces only', 'trusted': ['Specified Interfaces', 'Support VPN', 'Webgate VPN'], 'untrusted': ['Everything else'], 'usage_description': 'All features are accessible from the specified interfaces but forbidden from the other interfaces.'},
    {'policy_name': 'Trust traffic from specific subnets only', 'trusted': ['Specified Subnets', 'Support VPN', 'Webgate VPN'], 'untrusted': ['Everything else', '(no trusted interfaces)'], 'usage_description': 'All features are available to the specified subnets (hosts) only. No networks are trusted by physical topology.  The hosts having a choosen IP address is trusted.  For example, the IP camera with a static IP address is trusted in a network but no other hosts are allowed to access SCORER Edge from that network.'},
    {'policy_name': 'Trust the specific MAC addresses only', 'trusted': ['Specified MAC addresses', 'Support VPN', 'Webgate VPN'], 'untrusted': ['Everything else', '(no trusted interfaces)'], 'usage_description': 'All features are available to the specified MAC addresses (hosts) only. Similar to "Trust the specific subnets only", but this uses L2 addresses (MAC) instead of L3 addresses (IP) for stricter access control.'},
    {'policy_name': 'Trust VPN traffic only', 'trusted': ['User-defined VPN', 'Support VPN', 'Webgate VPN'], 'untrusted': ['Everything else', '(no trusted interfaces)'], 'usage_description': 'All features are accessible from the specified interfaces but forbidden from the other interfaces.'},
    {'policy_name': 'Trust Webgate only', 'trusted':  ['Support VPN', 'Webgate VPN'], 'untrusted':['Everything else', '(no trusted interfaces)'], 'usage_description': 'All features are available through the Webgate only. No networks are trusted except for Webgate access.  This is still meaningful for situations such as running the device in EC2 with SDK projects only (no camera inputs) or the situations like running the device with UVC camera directly connected to it.'}
]

const DEFAULT_VALUES = {
    'current_policy': 'trust_all'
}

const AccessControl = (props) => {
    const [isMobileBrowser, setIsMobileBrowser] = useState(false);
    const [readOnly, setReadOnly] = useState(true);
    const [controData, setControlData] = useState(DEFAULT_VALUES);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        var isMobileBrowser = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if(isMobileBrowser) {
        setIsMobileBrowser(isMobileBrowser);
        } else {
        setIsMobileBrowser(isMobileBrowser);
        }
    },[]);

    const getPolicy = useCallback(() => {
        axios.get(Constants.EDGE_API_ENDPOINT + '/system/network/access_control/all')
        .then(res => {
            const data = {...res.data.data}
            if(data.current_policy === 'specific_ifaces_only') {
                data.trusted_ifaces = data.services.specific_ifaces_only.trusted_ifaces.join(', ').toString();
            } else if(data.current_policy === 'specific_subnets_only') {
                data.subnets = data.services.specific_subnets_only.subnets.join(', ').toString();
            }  else if(data.current_policy === 'specific_mac_only') {
                data.mac_addr = data.services.specific_mac_only.mac_addr.join(', ').toString();
            }         
            setControlData(data);
            setLoading(false);
          })
          .catch(error => {
            console.log(error);
            setLoading(false);
          });
    },[]);

    useEffect(()=>{
        getPolicy();
    },[])

    const onEdit = () => {
        setReadOnly(false);
    }

    const handleOnchange = (event) => {
        if(event.target.name === "trusted_ifaces") {
            if (event.target.value.charAt(0) === ' ' || event.target.value.charAt(0) === ',') {
                event.target.value = event.target.value.replace(/[^\w]/g, '');
            } else {
                event.target.value = event.target.value.replace(/[&\/\\#+()=$~%.'[":*?<>{}]/g, '');
            }
        } else if(event.target.name === "subnets") {
            if (event.target.value.charAt(0) === ' ' || event.target.value.charAt(0) === ',') {
                event.target.value = event.target.value.replace(/[^\w]/g, '');
            } else {
                event.target.value = event.target.value.replace(/[^., /\d]/g, '');
            }
        } else if(event.target.name === "mac_addr") {
            if (event.target.value.charAt(0) === ' ' || event.target.value.charAt(0) === ',') {
                event.target.value = event.target.value.replace(/[^\w]/g, '');
            } else {
                event.target.value = event.target.value.replace(/[^:, /\d/\w]/g, '');
            }
        }
        setControlData({ ...controData, [event.target.name]: event.target.value });
    }

    function validateSubnetAddress(ipaddress) {
        if(ipaddress === undefined || ipaddress === ''){
          return true;
        }
        let subnet = 1;
        const ip = ipaddress.split('/')[0];
        if(ipaddress.split('/').length > 1){
          subnet = parseInt(ipaddress.split('/')[1]);
        } 
        if ((/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ip)) && ipaddress.includes('/') && (subnet<=32 && subnet>0)) {
          return true;
        } else {
          return false;
        }
      }

      function validateMacAddress(macAddress) {
        if(macAddress === undefined || macAddress === ''){
          return true;
        }
        if (/^(([A-Fa-f0-9]{2}[:]){5}[A-Fa-f0-9]{2}[,]?)+$/i.test(macAddress)) {
          return true;
        } else {
          return false;
        }
      }

    const onSave = () => {
        let data = {...controData}
        let trustedInterfaces = [];
        let trustedSubnets = [];
        let trustedMacs = [];
        if(data.current_policy === 'specific_ifaces_only') {
            if(data.trusted_ifaces === '' ||  data.trusted_ifaces === undefined){
                swal('Please enter trusted interfaces', { icon: 'error'});
                return;
            }
        }
        if(data.current_policy === 'specific_subnets_only') {
            if(data.subnets === '' ||  data.subnets === undefined){
                swal('Please enter trusted subnets', { icon: 'error'});
                return;
            }
        }
        if(data.current_policy === 'specific_mac_only') {
            if(data.mac_addr === '' ||  data.mac_addr === undefined){
                swal('Please enter trusted MAC addresses', { icon: 'error'});
                return;
            }
        }
        if(data.current_policy === 'trust_all' || data.current_policy === 'vpn_only' || data.current_policy === 'webgate_only') {
            let trustedInterface = []; 
            trustedInterfaces.push(trustedInterface);
        }
        if (data.trusted_ifaces !== undefined && data.current_policy === 'specific_ifaces_only') {
            var res = data.trusted_ifaces.charAt(data.trusted_ifaces.length-1);
            let invalidinterface = [];
            if(res === ',') {
                swal('Trusted interface names should be separated by comma, comma at the end is not allowed.', { icon: 'error'});
                return; 
            } else if(res === ' ') {
                swal('Trusted interface names should be separated by comma, comma at the end is not allowed.', { icon: 'error'});
                return;
            } else {
                let trustedInterface = data.trusted_ifaces.split(', ');
                for (let i = 0; i < trustedInterface.length; i++) {
                    console.log('commas',i, trustedInterface[i].trim()  )
                    if (trustedInterface[i].includes(' ')) {
                        invalidinterface.push(trustedInterface[i].trim());
                        swal('Trusted interface "'+ invalidinterface + '" should be separated by comma.' , { icon: 'error'});
                        return;
                    } else if( trustedInterface[i].includes(',')) {
                        invalidinterface.push(trustedInterface[i].trim());
                        swal('Invalid interface "' + invalidinterface, { icon: 'error'});
                        return;
                    } else if(trustedInterface[i].length > 15) {
                        invalidinterface.push(trustedInterface[i].trim());
                        swal('Trusted interface "' + invalidinterface + '" should not be more than 15 characters.', { icon: 'error'});
                        return;
                    }
                }
                trustedInterfaces.push(trustedInterface);
            }  
            if(data.trusted_ifaces.split(',').length > 5){
                swal('Maximum 5 numbers of trusted interfaces can be added', { icon: 'error'});
                return;
            }
        } 

        if (data.subnets !== undefined && data.current_policy === 'specific_subnets_only') {
            var res = data.subnets.charAt(data.subnets.length-1);
            let invalidsubnet = [];
            if(res === ',') {
                swal('Trusted subnets names should be separated by comma, comma at the end is not allowed.', { icon: 'error'});
                return; 
            } else if(res === ' ') {
                swal('Trusted subnets names should be separated by comma, comma at the end is not allowed.', { icon: 'error'});
                return;
            } else {
                let trustedsubnet = data.subnets.split(', ');
                for (let i = 0; i < trustedsubnet.length; i++) {
                    if(trustedsubnet[i].includes(' ')) {
                        invalidsubnet.push(trustedsubnet[i].trim());
                        swal('Trusted subnet "'+ invalidsubnet + '" should be separated by comma.' , { icon: 'error'});
                        return;
                    } else if( trustedsubnet[i].includes(',')) {
                        invalidsubnet.push(trustedsubnet[i].trim());
                        swal('Invalid subnet "' + invalidsubnet, { icon: 'error'});
                        return;
                    } else if(!validateSubnetAddress(trustedsubnet[i].trim())){
                        invalidsubnet.push(trustedsubnet[i].trim());
                        swal('Invalid subnet ' + invalidsubnet, { icon: 'error'});
                        return;
                      }
                }
                trustedSubnets.push(trustedsubnet);
            } 
            if(data.subnets.split(',').length > 5){
                swal('Maximum 5 numbers of trusted subets can be added', { icon: 'error'});
                return;
            }
        }

        if (data.mac_addr !== undefined && data.current_policy === 'specific_mac_only') {
            var res = data.mac_addr.charAt(data.mac_addr.length-1);
            let invalidMac = [];
            if(res === ',') {
                swal('Trusted MAC Addresses names should be separated by comma, comma at the end is not allowed.', { icon: 'error'});
                return; 
            } else if(res === ' ') {
                swal('Trusted MAC Addresses names should be separated by comma, comma at the end is not allowed.', { icon: 'error'});
                return; 
            } else {
                let trustedMacAddress = data.mac_addr.split(', ');
                for (let i = 0; i < trustedMacAddress.length; i++) {
                    if(trustedMacAddress[i].includes(' ')) {
                        invalidMac.push(trustedMacAddress[i].trim());
                        swal('Trusted MAC Addresses "'+ invalidMac + '" should be separated by comma.' , { icon: 'error'});
                        return;
                    }  else if( trustedMacAddress[i].includes(',')) {
                        invalidMac.push(trustedMacAddress[i].trim());
                        swal('Invalid MAC address "' + invalidMac, { icon: 'error'});
                        return;
                    } else if(!validateMacAddress(trustedMacAddress[i].trim())){
                        invalidMac.push(trustedMacAddress[i].trim());
                        swal('Invalid MAC address ' + invalidMac, { icon: 'error'});
                        return;
                    }
                }
                trustedMacs.push(trustedMacAddress);
            }
            if(data.mac_addr.split(',').length > 5){
                swal('Maximum 5 numbers of trusted MAC Addresses can be added', { icon: 'error'});
                return;
            }
        }
        
        let jsonData = {};
        jsonData.type = "access_ctl";
        if(data.current_policy === 'trust_all') {
            jsonData.trusted_ifaces = 'all';
        } else if(data.current_policy === 'specific_ifaces_only') {
            jsonData.trusted_ifaces = trustedInterfaces[0];
        } else if(data.current_policy === 'specific_subnets_only') {
            jsonData.subnets = trustedSubnets[0];
        } else if(data.current_policy === 'specific_mac_only') {
            jsonData.mac_addr = trustedMacs[0];
        } else if(data.current_policy === 'vpn_only') {
            jsonData.trusted_ifaces = 'vpn_all'
        } else if(data.current_policy === 'webgate_only') {
            jsonData.trusted_ifaces = 'webgate_all'
        } 

        if(data.current_policy === 'trust_all') {
            swal({
                text: 'Anyone can have access to Edge UI and Applications after this change. Please confirm if you are sure about changing the policy.',
                buttons: ['No', 'Yes'],
                dangerMode: true,
                icon: 'warning'
              }).then(function(isConfirm) {
                if (isConfirm) {
                axios.patch(Constants.EDGE_API_ENDPOINT + '/system/network/access_control/' + data.current_policy + '/all', jsonData)
                .then(res => {
                    swal('Policy set successfully', { icon: 'success'});
                    setReadOnly(true);
                    setTimeout(function(){ window.location.reload(); }, 2000);
                  })
                  .catch(error => {
                    if(error.response){
                      var errorObj = error.response.data;
                      swal('Error Code: ' + errorObj.error.code +'\nError Message: ' + errorObj.error.message, { icon: 'error'});
                    }else{
                      swal({text: 'Unable to connect to the edge-api service' , icon: 'error'});
                    }
                  });
                }
              })
        } else {
            swal({
                text: 'You may lose access to Edge UI after this change is applied. Please confirm if you are sure about changing the policy.',
                buttons: ['No', 'Yes'],
                dangerMode: true,
                icon: 'warning'
              }).then(function(isConfirm) {
                if (isConfirm) {
                axios.patch(Constants.EDGE_API_ENDPOINT + '/system/network/access_control/' + data.current_policy + '/all', jsonData)
                .then(res => {
                    swal('Policy set successfully', { icon: 'success'});
                    setReadOnly(true);
                    setTimeout(function(){ window.location.reload(); }, 2000);
                  })
                  .catch(error => {
                    if(error.response){
                      var errorObj = error.response.data;
                      swal('Error Code: ' + errorObj.error.code +'\nError Message: ' + errorObj.error.message, { icon: 'error'});
                    }else{
                      swal({text: 'Unable to connect to the edge-api service' , icon: 'error'});
                    }
                  });
                }
              })
        }
    }

    return (
        <>
            <ContentDiv>
                <Wrapper style={{'display':'flex', 'flexDirection':'column'}}>
                    <Row>
                        <Loader isLoading={loading} />
                        {controData && !loading &&
                        <Column style={{'width': '650px', padding:'0', 'marginLeft':(window.innerWidth === 1920 ? '7px': window.innerWidth === 1366 ?'10px':'15px')}}>
                            <div style={{'marginTop': '20px', 'display': 'flex'}}>
                                <Label style={{'width': '260px', 'margin-top': '5px'}}>Policy</Label>
                                <SelectField name="current_policy" data="current_policy" value={controData.current_policy} onChange={handleOnchange} disabled={readOnly}>
                                    <option value='trust_all'>Trust All</option>
                                    <option value='specific_ifaces_only'>Trust traffic on specific interfaces only</option> 
                                    <option value='specific_subnets_only'>Trust traffic from specific subnets only</option>
                                    <option value='specific_mac_only'>Trust the specific MAC addresses only</option>
                                    <option value='vpn_only'>Trust VPN traffic only</option>
                                    <option value='webgate_only'>Trust Webgate only</option>
                                </SelectField>
                            </div>
                             {controData.current_policy === 'specific_ifaces_only' && 
                                <div style={{'marginTop': '30px', 'display': 'flex'}} readOnly={readOnly}>
                                    <MandatoryLabel style={{'margin-top': '5px', 'width': '260px'}}>Trusted Interfaces</MandatoryLabel>
                                    <TextField id="trusted_ifaces" title={controData.trusted_ifaces} autoComplete="off" placeholder="wlan0, eth0" name="trusted_ifaces" value={controData.trusted_ifaces} disabled={readOnly} onChange={handleOnchange} style={{'cursor': 'pointer'}} />
                                </div>}
                                {controData.current_policy === 'specific_subnets_only' && 
                                <div style={{'marginTop': '30px', 'display': 'flex'}} readOnly={readOnly}>
                                    <MandatoryLabel style={{'margin-top': '5px', 'width': '260px'}}>Trusted Subnets</MandatoryLabel>
                                    <TextField id="subnets" title={controData.subnets} autoComplete="off" placeholder="192.168.10.1/24, 192.168.30.1/24" name="subnets" value={controData.subnets} disabled={readOnly} onChange={handleOnchange} style={{'cursor': 'pointer'}} />
                                </div>}
                                {controData.current_policy === 'specific_mac_only' && 
                                <div style={{'marginTop': '30px', 'display': 'flex'}} readOnly={readOnly}>
                                    <MandatoryLabel style={{'margin-top': '5px', 'width': '260px'}}>Trusted MAC Addresses</MandatoryLabel>
                                    <TextField id="mac_addr" title={controData.mac_addr} autoComplete="off" placeholder="5e:a2:d1:50:d5:38, dc:a6:32:ef:2c:75" name="mac_addr" value={controData.mac_addr} disabled={readOnly} onChange={handleOnchange} style={{'cursor': 'pointer'}} />
                                </div>}
                            {readOnly ? 
                            <ButtonWrapper>
                                <Button id='saveClockInputButton' primary onClick={onEdit}>Edit</Button>
                            </ButtonWrapper>: 
                            <ButtonWrapper>
                                <Button id='saveClockInputButton' primary onClick={onSave}>Save</Button>
                                <Button id='cancelClockInputButton' onClick={() =>  window.location.reload()}>Cancel</Button>
                            </ButtonWrapper>}
                        </Column>}
                    </Row>
                    {!loading &&
                    <TableLayout style={{'marginTop':'10px'}}>
                        <Table>
                            <tr style={{ 'background-color': '#1f303a', 'color': 'white' }}>
                                <Th style={{'text-align': 'left', 'width':'200px'}}>Policy Name</Th>
                                <Th style={{'width': isMobileBrowser ? '300px' : '200px'}}>Trusted</Th>
                                <Th style={{'width':'200px'}}>Untrusted</Th>
                                <Th style={{'width':isMobileBrowser ? '300px' : '400px'}}>Usages Description</Th>
                            </tr>
                            {Object.keys(TableData).map((idx) => {
                                return(
                                    <tr>
                                        <Td style={{'padding':'10px', 'text-align': 'left'}}>{TableData[idx].policy_name}</Td>
                                        <Td style={{'padding':'10px'}}> 
                                            {Object.keys(TableData[idx].trusted).map((id) => (
                                                <div>{TableData[idx].trusted[id]}<br/></div>
                                            ))}
                                        </Td>
                                        <Td style={{'padding':'10px'}}>
                                            {Object.keys(TableData[idx].untrusted).map((id) => (
                                                <div>{TableData[idx].untrusted[id]}<br/></div>
                                            ))}
                                        </Td>
                                        <Td style={{'text-align': 'left', 'padding':'10px', 'text-size-adjust': '100%'}}>{TableData[idx].usage_description}</Td>
                                    </tr>)
                            })}
                        </Table>
                    </TableLayout>}
                </Wrapper>
            </ContentDiv>
            
        </>
    )
}

export default AccessControl;