import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ContentDiv, Wrapper, Label, Row, Column, SectionHeader, SectionLabel, MessageLabel } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import { CircularProgress } from 'react-md';
import swal from 'sweetalert';
import ls from 'local-storage';
import styled from 'styled-components';
import format from 'date-fns/format';
import { formatDistance } from 'date-fns';
import {convertToTimeZone} from 'date-fns-timezone';


const MessageContaier = styled.div`
  padding: 5px 5px 5px 10px;
  font-size: 0.9em;
`;

const DeviceInfo = (props) => {
  const device_info_lable = Constants.DEVICE_INFO_LABLE;
  const [deviceSystemInfo, setDeviceSystemInfo] = useState();
  const [timezone, setTimeZone] = useState('');
  const [usbDevices, setUsbDevices] = useState([]);
  const [isUsbDevicesLoading, setIsUsbDevicesLoading] = useState(true);
  const [uploadedTime, setUploadedTime] = useState();
  const [vpnStatus, setVpnStatus] = useState();
  const [isMobileBrowser, setIsMobileBrowser] = useState(false);

  useEffect(()=>{
    var isMobileBrowser = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if(isMobileBrowser) {
      setIsMobileBrowser(isMobileBrowser);
    } else {
      setIsMobileBrowser(isMobileBrowser);
    }
  },[]);

  useEffect(() => {
    axios.get(Constants.EDGE_API_ENDPOINT + '/system/network/status')
    .then(res => {
      setVpnStatus(res.data.data);
    })
    .catch(error => {
      console.log(error);
    });
  },[]);

  useEffect(() => {
    axios.get(Constants.EDGE_API_ENDPOINT + '/devices/deviceinfo')
      .then(res => {
        var systemInfo = res.data;
        setDeviceSystemInfo(systemInfo.data);
        if('device_id' in res.headers){
          ls.set('device_id', res.headers.device_id);
          document.getElementById('deviceId').textContent = res.headers.device_id;
        }
        let interfaces = systemInfo.data.network.interfaces;
        let eno1, eth0, wlan0;
        Object.entries(interfaces).map(([key,value])=>{
          if(value.interface === 'eno1.inet'){
            eno1 = value.ip_address.split('/')[0];
          }
          if(value.interface === 'eth0.inet'){
            eth0 = value.ip_address.split('/')[0];
          }
          if(value.interface === 'wlan0.inet'){
            wlan0 = value.ip_address.split('/')[0];
          }
          return value;
        });
        if(eno1 !== undefined){
          ls.set('device_ip', eno1);
        } else if(eth0 !== undefined){
          ls.set('device_ip', eth0);
        } else if(wlan0 !== undefined){
          ls.set('device_ip', wlan0);
        }
      })
      .catch(error => {
        if(error.response){
          var errorObj = error.response.data;
          swal('Error Code: ' + errorObj.error.code +'\nError Message: ' + errorObj.error.message, { icon: 'error'});
        }else{
          swal(error.toString(), { icon: 'error'});
        }
      });

  }, []);

  useEffect(() => {
    axios.get(Constants.EDGE_API_ENDPOINT + '/system/usb/all')
      .then(res => {
        setUsbDevices(res.data.data);
        setIsUsbDevicesLoading(false);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);	

  useEffect(() => {
    axios.get(Constants.EDGE_API_ENDPOINT + '/system/system-services/uploader/status')
    .then(res => {
      let keys =[];
      const tz = res.data.data. env_variables[0].split('=');
      setTimeZone(tz[1]);
      const dateArray = Object.entries(res.data.data.health_status.status_detail.last_upload_time);
      dateArray.forEach(([key, value]) => {
        keys.push(value);
      });
      setUploadedTime(keys);
    }).catch(error => {
      if (error.response) {
        var errorObj = error.response.data;
        swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
      } else {
        swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
      }
    });
  },[]);

  const convertedArrayDate = () => {
    try {
      let newDates = [];
      uploadedTime.forEach(date => {
        if(date === '0' || date === '') {
          return
        }
      const splitDate = date.split(' ');
      let dateFormat = splitDate[0] + ' ' + splitDate[1];
      var d1 = new Date(dateFormat);
      newDates.push(d1);
    });
    var maxDatenew = Math.max.apply(null, newDates)
    maxDatenew = new Date(maxDatenew)
    const maxDateformatted = format(maxDatenew, "yyyy/MM/dd HH:mm:ss")
    let returnDate = '';
    uploadedTime.forEach(date => { 
      const splitDate = date.split(' ');
      const mydate = splitDate[0] + ' ' + splitDate[1];

      if(maxDateformatted === mydate) {
        returnDate = date;
      }
    })
    return returnDate;

    } catch (err) {
      console.log(err);
    }
    return '-';  
  }

  const getTimeSincerrayDate = () => {
    try{
      let newDates = [];
      uploadedTime.forEach(date => {
        if(date === '0' || date === '') {
          return '';
        }
        const splitDate = date.split(' ');
        let dateFormat = splitDate[0] + ' ' + splitDate[1];
        var d1 = new Date(dateFormat);
        newDates.push(d1);
    });
    const maxDate = new Date(Math.max.apply(null, newDates));
    const zonedTargetDate = convertToTimeZone(new Date(), { timeZone: timezone });
    const result = formatDistance(
      new Date(maxDate), zonedTargetDate,
      { addSuffix: true, includeSeconds: true }
    );
      return ' (' + result + ')';
    } catch (err) {
      console.log(err);
    }
    return '';  
  };

  const getTimeSince = (date) => {
    if(date === '0') {
      return '';
    } else {
      try {
        if (date) {
          var newStr = date.split(' ');
          let formattedDate = newStr[0] + ' ' + newStr[1];
          const result = formatDistance(
            new Date(formattedDate), new Date(),
            { addSuffix: true, includeSeconds: true }
          );
          return ' (' + result + ')';
        }
      } catch (err) {
        console.log(err);
      }
      return '';
    }
  };

  return (
    <ContentDiv style={{'overflow':'hidden'}}>
      { deviceSystemInfo === undefined &&
        <div className="loading-msg">
          <label>Loading...</label>
          <CircularProgress/>
        </div>
      }
      {deviceSystemInfo &&
      <div>
        <Wrapper>
          <SectionHeader>
            <SectionLabel>Device Information</SectionLabel>
          </SectionHeader>
          <Row>
            <Column style={{'width':'50%'}}>
              <fieldset>
                <legend>
                  System
                </legend>
                {deviceSystemInfo &&
                Object.entries(deviceSystemInfo.system).map(([key,value])=>{
                  if(key !== 'disk'){
                    if (key === 'ram'){
                      value = value.indexOf(',') !== -1 ? value.substr(7, value.indexOf(',')-7) : value;
                      var formatedValue = Number(parseFloat(value.replace('MB','')).toFixed(0)).toLocaleString('en', {minimumFractionDigits: 0}) + ' MB';
                      return(
                        <Row>
                          <Column style={{'width':'28%'}}><b>{key in device_info_lable? device_info_lable[key]:key}</b></Column>
                          <Column style={{'width':'72%'}} id={key} >{formatedValue}</Column>
                        </Row>
                      );
                    }
                    if (key === 'cpu'){
                      return(
                        <Row>
                          <Column style={{'width':'28%'}}><b>{key in device_info_lable? device_info_lable[key]:key}</b></Column>
                          <Column style={{'width':'72%'}} id={key} >{value}</Column>
                        </Row>
                      );
                    }
                    if (key === 'graphics_card') {
                      return(
                        <Row>
                          <Column style={{'width':'28%'}}><b>{key in device_info_lable? device_info_lable[key]:key}</b></Column>
                          <Column style={{'width':'72%'}} id={key} >
                            { deviceSystemInfo.system.graphics_card.map(val=>{
                              return(
                                <Row>
                                  {val}
                                </Row>
                              );})
                            }
                          </Column>
                        </Row>
                      );
                    }
                  }
                  else{
                    return(
                      <Row>
                        <Column style={{'width':'28%'}}><b>{key in device_info_lable? device_info_lable[key]:key}</b></Column>
                        <Column style={{'width':'72%'}}>
                          {
                            <table style={{'width':'100%'}}>
                              <tr style={{'line-height': '18px'}}>
                                <th>Type</th>
                                <th>Total</th>
                                <th>Used</th>
                                <th>Free</th>
                              </tr>
                              { Object.entries(deviceSystemInfo.system.disk).map(([key1,value1])=>{
                                return(
                                  <tr style={{'line-height': '12px'}}>
                                    <td style={{'padding':'7px 0px'}}>{value1.type}</td>
                                    <td style={{'padding':'7px 0px'}}>{value1.total}</td>
                                    <td style={{'padding':'7px 0px'}}>{value1.used}</td>
                                    <td style={{'padding':'7px 0px'}}>{value1.free}</td>
                                  </tr>
                                );
                              })
                              }
                            </table>
                          }
                        </Column>
                      </Row>
                    );
                  }
                  return value;
                })
                }
              </fieldset>
              <fieldset>
                <legend>
                  Network
                </legend>
                {deviceSystemInfo &&
                  Object.entries(deviceSystemInfo.network).map(([key,value])=>{
                    if(key !== 'internet connectivity'){
                      return(
                        <Row style={{'overflow-x': 'auto', 'white-space': 'nowrap', 'width': '34vw'}}>
                          <Column style={{'width':'100%'}}>
                            <table style={{'width':'100%'}}>
                              <tr  style={{'line-height': '18px'}}>
                                <th>Interface</th>
                                <th>IP Address</th>
                                <th>MAC Address</th>
                              </tr>
                              {
                                Object.entries(deviceSystemInfo.network.interfaces).map(([key1,value1])=>{
                                  if ((value1.interface.startsWith('br-') || value1.interface.startsWith('docker') || value1.interface.startsWith('veth')) === false) {
                                    let inetIndex = value1.interface.indexOf('.inet');
                                    let infName = inetIndex !== -1 ? value1.interface.substr(0, inetIndex) : value1.interface;
                                    return(
                                      <tr  style={{'line-height': '12px'}}>
                                        <td style={{'padding':'7px 0px'}} id="interface">{value1.is_ap ? '[AP] ' : ''}{infName}</td>
                                        <td style={{'padding':'7px 0px'}} id="ip_address">{value1.ip_address}</td>
                                        <td style={{'padding':'7px 0px'}} id="mac_address">{value1.mac_address}</td>
                                      </tr>
                                    );
                                  }
                                  return '';
                                })
                              }
                            </table>
                          </Column>
                        </Row>
                      );
                    }
                    return '';
                  })
                }
              </fieldset>
              <fieldset>
                <legend>
                  USB Devices
                </legend>
                <div style={{'overflow-x': 'auto', 'white-space': 'nowrap', 'width': '34vw'}}>
                  { isUsbDevicesLoading ?
                    <Label>Fetching USB devices information...</Label>
                    :
                    <table style={{'width':'100%'}}>
                      <tr  style={{'line-height': '11px'}}>
                        <th style={{'padding':'7px 10px 7px 5px'}}>Bus ID</th>
                        <th style={{'padding':'7px 10px 7px 5px'}}>Port ID</th>
                        <th style={{'padding':'7px 10px 7px 5px'}}>Type</th>
                        {/*<th style={{"padding":"7px 10px 7px 5px"}}>Name</th>*/}
                        <th style={{'padding':'7px 10px 7px 5px'}}>Description</th>
                      </tr>
                      {
                        usbDevices.length === 0 ?
                          <tr>
                            <td colSpan="5"><Label style={{'margin-top': '10px'}}>USB devices not found</Label></td>
                          </tr>
                          : usbDevices.map((device) => {
                            return(
                              <tr  style={{'line-height': '12px'}}>
                                <td style={{'padding':'7px 10px 7px 5px'}} id="device_bus_id">{device.bus_id}</td>
                                <td style={{'padding':'7px 10px 7px 5px'}} id="device_port_id">{device.port_id}</td>
                                <td style={{'padding':'7px 10px 7px 5px'}} id="device_type">{device.type}</td>
                                {/*<td style={{"padding":"7px 10px 7px 5px"}} id="device_name">{device.name}</td>*/}
                                <td style={{'padding':'7px 10px 7px 5px'}} id="device_description">{device.description}</td>
                              </tr>
                            );
                          })
                      }
                    </table>
                  }
                </div>
              </fieldset>
            </Column>
            <Column style={{'width':'50%'}}>
              <fieldset>
                <legend>
                  Uptime
                </legend>
                {deviceSystemInfo &&
                  Object.entries(deviceSystemInfo.device_uptime).map(([key,value])=>{
                    return(
                      <Row>
                        <Column style={{'width':'33%'}}><b>{key in device_info_lable? device_info_lable[key]:key}</b></Column>
                        <Column style={{'width':'67%'}} id={key}> {key==='last_boot' ? ((value === '' && value === '0') ? '-' : value ): value}</Column>
                      </Row>
                    );
                  })
                }
              </fieldset>
              {/*  */}
              <fieldset>
                <legend>
                  SCORER Edge
                </legend>
                {deviceSystemInfo &&
                  Object.entries(deviceSystemInfo.software).map(([key,value])=>{
                    return(
                      <Row>
                        <Column style={{'width':'33%'}}><b>{key in device_info_lable? device_info_lable[key]:key}</b></Column>
                        <Column style={{'width':'67%'}} id={key}>{value.replace(/-/g, '/')}</Column>
                      </Row>
                    );
                  })
                }
              </fieldset>
              <fieldset>
                <legend>
                  SCORER Cloud
                </legend>
                <Row style={{'overflow-x': 'auto', 'white-space': 'nowrap', 'width': '34vw'}}>
                  {deviceSystemInfo &&
                    <>
                    <Row>
                      <Column style={{'width':'33%'}}><b>Status</b></Column>
                      <Column style={{'width':'67%'}} >{deviceSystemInfo.scorer_cloud.status}</Column>
                    </Row>
                    {(deviceSystemInfo.scorer_cloud.status === 'Connected') &&
                        <Row>
                        <Column style={{'width':'33%'}}><b>{deviceSystemInfo.scorer_cloud.status === 'Connected' && 'Last Upload' }</b></Column>
                        <Column style={{'width':'67%'}} >{convertedArrayDate() + ' ' + getTimeSincerrayDate()}</Column>
                      </Row>
                    }
                    {(deviceSystemInfo.scorer_cloud.status === 'Disconnected') &&
                        <Row>
                        <Column style={{'width':'33%'}}><b>{deviceSystemInfo.scorer_cloud.status === 'Disconnected' && 'Last Connected' }</b></Column>
                        <Column style={{'width':'67%'}}>{deviceSystemInfo.scorer_cloud.shadow_last_update_time !== '' ? ( ((deviceSystemInfo.scorer_cloud.shadow_last_update_time === '' && deviceSystemInfo.scorer_cloud.shadow_last_update_time === '0') ? '-' : deviceSystemInfo.scorer_cloud.shadow_last_update_time )) + getTimeSince(deviceSystemInfo.scorer_cloud.shadow_last_update_time) : '-'}</Column>
                      </Row>}
                    </>
                  }
                  <Row>
                    <MessageContaier>
                      <MessageLabel>
                        <b>Connected:</b> This device is communicating with SCORER Cloud.<br/>
                        <b>Disconnected:</b> This device couldn't connect to SCORER Cloud for 10+ min.<br/>
                        <b>Not Available:</b> This device is not linked (not registered) to SCORER Cloud.<br/>
                      </MessageLabel>
                    </MessageContaier>
                  </Row>
                </Row>
              </fieldset>
              {
                vpnStatus && 
                <fieldset>
                  <legend>
                    VPN
                  </legend>
                  <Row style={{'overflow-x': 'auto', 'white-space': 'nowrap', 'width': '34vw'}}>
                  <Row>
                      <Column style={{'width': isMobileBrowser ? '44%' : '33%'}}><b>Support VPN</b></Column>
                      <Column style={{'width':isMobileBrowser ? '56%' :'67%'}} >{vpnStatus.vpn_if ? (vpnStatus.services.ip_addresses[vpnStatus.vpn_if] !== '' ? ' Connected '+ '(' + vpnStatus.vpn_if +')' : ' Disconnected' ): ' Not Available' }</Column>
                    </Row>
                    <Row>
                      <Column style={{'width': isMobileBrowser ? '44%' : '33%'}}><b>Webgate VPN</b></Column>
                      <Column  style={{'width':isMobileBrowser ? '56%' :'67%'}}>{vpnStatus.vpn_if_webgate ? (vpnStatus.services.ip_addresses[vpnStatus.vpn_if_webgate] !== '' ? ' Connected '+ '(' + vpnStatus.vpn_if_webgate +')' : ' Disconnected' ): ' Not Available' }</Column>
                    </Row>
                    <Row>
                      <Column style={{'width': isMobileBrowser ? '44%' : '33%'}}><b>User-defined VPN</b></Column>
                      <Column style={{'width':isMobileBrowser ? '56%' :'67%'}}>{vpnStatus.vpn_if_vpnclient ? (vpnStatus.services.ip_addresses[vpnStatus.vpn_if_vpnclient] !== '' ? ' Connected '+ '(' + vpnStatus.vpn_if_vpnclient +')' : ' Disconnected' ): ' Not Available' }</Column>
                    </Row>
                    <Row>
                    <MessageContaier>
                      <MessageLabel>
                        <b>Connected:</b> The IP address is assigned.<br/>
                        <b>Disconnected:</b> The endpoint is available but IP address is not assigned yet.<br/>
                        <b>Not Available:</b> The endpoint does not exist.<br/>
                      </MessageLabel>
                    </MessageContaier>
                    </Row>
                  </Row>
              </fieldset>}
            </Column>
          </Row>
        </Wrapper>
      </div>
      }
    </ContentDiv>
  );
};

export default DeviceInfo;