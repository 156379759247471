import React,{useState, useEffect} from 'react'
import { ContentDiv, Column, Row, TextField, Wrapper, Button, SectionHeader, RightDiv, SectionLabel  } from '../utils/Styles';
import { SelectionControl, CircularProgress } from 'react-md';
import PasswordField from './PasswordField.js'
import * as Constants from '../utils/Constants';
import swal from "sweetalert";
import FieldLabel from './FieldLabel'
import axios from 'axios'

const ValidateMsg ={
    listen_port:"listen port",
    data_port:"data port",
    enable_passive_mode : "passive mode",
    port_from : "port from",
    port_to:"port to",
    username:"username",
    password:"password"
}

function FTPServerSetting() {
    const [readOnly, setReadOnly] = useState(true);
    const [inputs, setInputs]=useState(Constants.FTP_SERVER_DEFAULT_VALUES);
    const [isChanged, setChanged] = useState(false);

    const handleOnChange = (event) => {
        event.persist();
        let data = {...inputs, [event.target.name]: event.target.value};
        if( event.target.name === 'password') {     
            data['password'] = event.target.value.replace(/[{}()~*?"'\\^$|#\s]/g, '');
        }
        setInputs(data);
    }

    const handleToggleChange = (value, event) => {
        if(!readOnly){
            setInputs({...inputs, [event.target.id]: value});
            setChanged(true);
        }
    };

    const handleNumberOnChange = (event) => {
        event.persist();
        let regEx = /^[1-9][0-9]*$/
        if(regEx.exec(event.target.value)){
            setInputs({...inputs, [event.target.name]: event.target.value});
            setChanged(true);
        } else {
            if(event.target.value === "" || event.target.value === "0"){
                setInputs({...inputs, [event.target.name]: ""});
                setChanged(true);
            }
        }
    }

    const editSetting =() => {
        setReadOnly(false)
    }

    const saveSetting = () => {
        let jsonData = Object.assign({}, inputs);
        Object.keys(inputs).forEach(function(key){
            if(jsonData["enable_passive_mode"] === true && (key === "port_from" || key === "port_to") ){
                if(jsonData[key] ==="" ||  jsonData[key] === undefined){
                swal(`Please enter ${ValidateMsg[key]}`);
                return;
                }
                if(parseInt(jsonData["port_from"], 10) > parseInt(jsonData["port_to"],10)){
                    swal(`Please enter valid port range`);
                    return; 
                }
            }
            if(jsonData["enable_user_login"] === true && (key === "username" || key === "password") ){
                if(jsonData[key] === undefined || jsonData[key] ===""){
                    swal(`Please enter ${ValidateMsg[key]}`);
                    return;
                }
            }
           if(( jsonData[key] === undefined || jsonData[key] ==="") && (key !=="port_from" && key !== "port_to" && key !== "username" && key !== "password" )){
                swal(`Please enter ${ValidateMsg[key]}`);
                return;
            }
        });

        jsonData["type"] = "internal_ftp";
        axios.put(Constants.EDGE_API_ENDPOINT + '/system/services/internal_ftp', jsonData)
        .then(res => {
            swal("FTP server setting saved successfully.", { icon: "success"});
            setReadOnly(true);
            setTimeout(function(){ window.location.reload() }, 2000);
        })
        .catch(error => {
            if(error.response){
                var errorObj = error.response.data;
                swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
            }else{
                swal({text: "Unable to connect to the edge-api service" , icon: "error"});
            }
        });
    }

    const cancelSetting = () => {
        if(!isChanged){
            setReadOnly(readOnly => (true));
            return;
        } else {
            window.location.reload();
        }
    }

    useEffect(() => {
        axios.get(Constants.EDGE_API_ENDPOINT + '/system/services/internal_ftp')
        .then(res => {
            let ftpData = res.data['data'];
            if(Object.keys(ftpData).length > 0){
                setInputs(ftpData);
            } else {
                setReadOnly(false);
            }
        }).catch(error => {
            console.log(error);
        });
    }, []);


    return (
        <ContentDiv>
            { (inputs === undefined) && 
                <div className="loading-msg">
                    <label>Loading...</label>
                    <CircularProgress/>
                </div>
            }
            { inputs &&
            <Wrapper style={{"padding": "20px"}}>
            <SectionHeader>
                <SectionLabel>FTP Server</SectionLabel>
                </SectionHeader>
                <Row>
                    <Column>
                        <fieldset style={{"padding": "10px 15px 20px 15px"}}>
                        <legend>
                            Server Configuration
                        </legend>
                            <div style={{display:"flex",paddingTop:"20px"}}>
                                <FieldLabel required={true}>Listen Port</FieldLabel>
                                <TextField style={{"width": (window.innerWidth <=  1024 ? '180px' : "190px"), marginLeft: "40px"}} id="listen_port" name="listen_port" value={inputs.listen_port} maxLength="5" onChange={handleNumberOnChange} disabled={true}/>
                            </div>
                            <div style={{display:"flex",paddingTop:"20px"}}>
                                <FieldLabel required={true}>Data Port</FieldLabel>
                                <TextField style={{"width": (window.innerWidth <=  1024 ? '180px' : "190px"), marginLeft: "47px"}} id="data_port" name="data_port" value={inputs.data_port} maxLength="5" onChange={handleNumberOnChange} disabled={true}/>
                            </div>
                            <SelectionControl id="enable_passive_mode" type="switch" label="Enable Passive Mode" name="enable_passive_mode" labelBefore="true"  style={{paddingTop:"23px"}} checked={inputs.enable_passive_mode || false} onChange={handleToggleChange}/>
                            {inputs.enable_passive_mode && 
                            <div style={{display:"flex",paddingTop:"23px", paddingBottom:"10px"}}>
                                <FieldLabel required={inputs.enable_passive_mode}>Port Range</FieldLabel>
                                <TextField style={{"width": "75px", "marginLeft": "30px"}} id="port_from" name="port_from" value={inputs.port_from} maxLength="5" onChange={handleNumberOnChange} disabled={true}/>
                                <span style={{padding:"0px 14px 0px 14px"}}>To</span>
                                <TextField style={{"width": "75px"}} id="port_to" name="port_to" maxLength="5" value={inputs.port_to} onChange={handleNumberOnChange} disabled={true}/>
                            </div>}
                        </fieldset>
                    </Column>
                    <Column>
                    <fieldset style={{"padding": "10px 15px 20px 15px"}}>
                        <legend>
                            Login Details
                        </legend>
                            <SelectionControl id="enable_user_login" type="switch" label="Enable User Login" name="enable_user_login" labelBefore="true" style={{paddingTop:"23px",marginBottom: "10px"}} checked={inputs.enable_user_login || false} onChange={handleToggleChange}/>
                            <div style={{display:"flex",paddingTop:"20px"}}>
                                <FieldLabel required={true}>Username</FieldLabel>
                                <TextField id="username" name="username" value={inputs.username} maxLength="100" style={{"marginLeft":"25px", width:"250px"}} onChange={handleOnChange} disabled={!inputs.enable_user_login || readOnly}/>
                            </div>
                            <div style={{display:"flex", paddingTop:"20px"}}>
                                <FieldLabel required={true}> Password</FieldLabel>
                                <div style={{"marginLeft":"29px"}}>
                                    <PasswordField id="password" name="password" value={inputs.password} setInput={handleOnChange} width ="250px" readOnly={!inputs.enable_user_login || readOnly} />
                                </div>
                            </div>
                            <SelectionControl id="enable_anonymous_access" type="switch" label="Allow Anonymous Access" name="enable_anonymous_access" labelBefore="true" style={{paddingTop:"23px",marginBottom: "10px"}} checked={inputs.enable_anonymous_access || false} onChange={handleToggleChange}/>
                    </fieldset>
                    <RightDiv>
                    { readOnly === true &&
                        <Button id="editRecorderButton" primary onClick={editSetting}>Edit</Button>
                    }
                    { readOnly === false &&
                        <div>
                        <Button id="saveRecorderButton" primary onClick={saveSetting}>Save</Button>
                        <Button id="cancelRecorderButton" onClick={cancelSetting}>Cancel</Button>
                        </div>
                    }
                    </RightDiv>
                    </Column>
                </Row>
            </Wrapper>
            }
        </ContentDiv>
    )
}

export default FTPServerSetting
