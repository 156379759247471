import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { SelectionControl, CircularProgress } from 'react-md';
import { Row, Button, SectionHeader, SectionLabel, CopyButton, Link, CenterDiv, RightDiv, TextField, SelectField, TextArea, Label, Td, Th, overlayStyle, TableContainer, PopupButtons, PopupContainer, TextFieldLabel, TextFieldSmall, MandatoryLabel, Link1, LinkIcon } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import { generateRandomPassword, clickToCopy, isEmpty, dateFormat, timeZone } from '../utils/Utils';
import swal from 'sweetalert';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import SelectOptions from './SelectOptions';
import ls from 'local-storage';
import format from 'date-fns/format';
import { Tabs, Tab, TabList, TabPanel } from 'react-web-tabs';


const TableLayout = styled(TableContainer)`
  overflow: auto;
  overflow-y: hidden;
`;

const Wrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  text-align: left;
  display: inline-block;
`;

const ContentDiv = styled.div`
  width: 82vw;
  overflow-y: auto;
  padding-bottom: 10px;
`;

const Container = styled.div`
  padding: 0px 20px 20px 20px;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ContainerHost = styled.div`
  display: flex;
  justify-content: center;
`;


const InnerHostContainer = styled.div`
  display: flex;
  margin-right: 3px;
  padding-left: 2px;
`;

const NetworkContainer = styled.div`
  display: flex;
  justify-content: center;
`;

var projectData = {}

const getEnvironmentVariable = () => {
  return {
    'key': '',
    'value': '',
  };
};

const Project = (props) => {
  const [isEditPopup, setEditPopup] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [projectList, setProjectList] = useState()
  const [selectedProject, setSelectedProject] = useState({});
  const deviceID = ls.get('device_id') === undefined ? '' : ls.get('device_id') + '.local';

  const contentStyle = {
    margin: 'auto',
    background: 'rgb(255, 255, 255)',
    width: 'fit-content',
    height: 'auto',
    padding: '5px',
    minWidth: '30%',
    border: '2px solid #d7d7d7'
  };

  const projNameStyle = {
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    maxWidth: '200px'
  };

  const descriptionStyle = {
    textAlign: 'center',
    padding: '10px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    maxWidth: '300px'
  };

  const hostStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    maxWidth: '350px'
  };

  const editProject = () => {
    setEditPopup(true);
    setModalOpen(true);
  };

  const closePopup = (event) => {
    setModalOpen(false);
  };

  const addNewProject = (event) => {
    setEditPopup(false);
    setSelectedProject(undefined);
    setModalOpen(true);
  };

  const radioButtonOnChange = (name) => {
    let data = Object.assign({}, projectList.stacks[name].services.project1);
    data.project_name = name;
    data.description = projectList.stacks[name].description;
    data.nickname = projectList.stacks[name].nickname;
    setSelectedProject(data)
    setEditPopup(true);         
  };

  const enableToggle = (value, event ) => {
    let project_name = event.target.name;
    var jsonData = {
        'namespace': Constants.NAMSPACES.SDK,
        'action': value ? Constants.SDK_ACTIONS.ENABLE : Constants.SDK_ACTIONS.DISABLE,
        'type': 'project',
    }

    axios.post(Constants.EDGE_API_ENDPOINT + '/sdk/project/' + project_name + "/all", jsonData)
      .then(res => {
          swal('Instance ' + (value ? 'enable' : 'disable') + ' request submitted successfully.', { icon: 'success' })
          setTimeout(function () { window.location.reload() }, 2000)
      })
      .catch(error => {
          swal('Error in ' + (value ? 'stop' : 'start') + ' project', { icon: 'error' })
      })
  };

  function getDisplayLabel(data, key) {
    return (data[key] !== undefined ? data[key] : key);
  }

 

  useEffect(() => {
    if (document.getElementById('btnRefreshProject')) {
      document.getElementById('btnRefreshProject').textContent = 'Refresh';
    }
  }, [projectList]);

  const getProjectData = useCallback(() => {
    axios.get(Constants.EDGE_API_ENDPOINT + '/sdk/project/all')
    .then(res => {
      let data = res.data.data;
        if(Object.keys(res.data.data.stacks).length === 0) {
          setEditPopup(false);
        } 
        let sortedData = [];
        if('stacks' in data){
          Object.keys(data.stacks).map((stackName) => {
            let stackData = data.stacks[stackName];
            stackData.name = stackName;
            sortedData.push(stackData);
            return stackName;
          });
          sortedData.sort(function(a,b){
            const dateFormatB = b.created_time.split(' ');
            const dateFormatA = a.created_time.split(' ');
            let dateA = dateFormatA[0] + ' ' + dateFormatA[1];
            let dateB = dateFormatB[0] + ' ' + dateFormatB[1];
            return new Date(dateB) - new Date(dateA);
          });
        }
        data["sorted_list"] = sortedData;
        if(Object.keys(data.sorted_list).length !== 0) {
          let name = data.sorted_list[0].name;
          let instData = Object.assign({}, data.stacks[name].services.project1);
          instData.instance_id = name;
          instData.description = data.sorted_list[0].description;
          instData.nickname = data.sorted_list[0].nickname;
          setSelectedProject(instData);
          setEditPopup(true);
        }

        axios.get(Constants.EDGE_API_ENDPOINT + '/sdk/project/status/all')
          .then(res => {
            let projectStatus = res.data.data;
            if (projectStatus && 'stacks' in projectStatus) {
              let projectStatusList = projectStatus.stacks;
              Object.keys(projectStatusList).map((projName) => {
                if (projName in data.stacks) {
                  data.stacks[projName].status = projectStatusList[projName];
                }
                return projName;
              });
              setProjectList(data);
            } else {
              setProjectList(data);
            }
            setLoading(false);
            setRefreshLoading(false);
          })
          .catch(err => {
            console.log(err);
            setProjectList(data);
            setLoading(false);
            setRefreshLoading(false);
          });
      setLoading(false);
      setRefreshLoading(false);
    }) .catch(error => {
      console.log(error);
      setProjectList([]);
      setLoading(false);
      setRefreshLoading(false);
    });
  },[]);

  const refreshProject = (event) => {
    setRefreshLoading(true);
    document.getElementById('btnRefreshProject').textContent = 'Refreshing...';
    getProjectData();
  };

  useEffect(() => {
    getProjectData();
  }, [getProjectData])

  function getEnvironmentDisplayName(environment) {
    let env = '-';
    const envs = Constants.ENV_LIST;
    envs.map((obj) => {
      if (obj.value === environment) {
        env = obj.name;
      }
      return obj;
    });
    return env;
  }

  return (
    <ContentDiv>
      { (projectList === undefined) &&
        <div className="loading-msg">
          <label>Loading...</label>
          <CircularProgress />
        </div>
      }
      {projectList && !loading &&
        <Wrapper style={{ 'padding': '20px' }}>
          <SectionHeader>
            <SectionLabel>Project</SectionLabel>
          </SectionHeader>
          <Row>
            <ActionButtonContainer>
              { isEditPopup &&
                <Button primary id="btnEditProject" name="btnEditProject" onClick={() => editProject()}>Edit</Button>
              }
              {projectList && Object.keys(projectList.stacks).length > 0 &&
                <Button primary id="btnRefreshProject" name="btnRefreshProject" disabled={refreshLoading} onClick={() => refreshProject()}>Refresh</Button>
              }
              <Button primary id="btnAddProject" name="btnAddProject" onClick={() => addNewProject()}>Add</Button>
              <Popup modal open={modalOpen} onClose={closePopup} {...{ contentStyle, overlayStyle }}>
                <ProjectPopup isEditPopup={isEditPopup} closePopup={closePopup} selected_project={selectedProject} projectList={projectList} project={selectedProject}  />
              </Popup>
            </ActionButtonContainer>
          </Row>
          {projectList.stacks && Object.keys(projectList.stacks).length > 0 &&
            <TableLayout>
              <table style={{ 'min-width': '100%', 'border-collapse': 'collapse' }}>
                <tr style={{ 'background-color': '#1f303a', 'color': 'white' }}>
                  <Th style={{'width': '50px'}}></Th>
                  <Th style={{'text-align': 'left'}}>Nickname <br /> (Instance ID)</Th>
                  <Th>Status</Th>
                  <Th>Created</Th>
                  <Th>Environment</Th>
                  <Th>Description</Th>
                  <Th>Access</Th>
                  <Th>Host<br/>(Docker Network)</Th>
                  <Th>Dashboard</Th>
                  <Th>Enabled</Th>
                </tr>

                { projectList && Object.keys(projectList.sorted_list).map((stackName) => {
                    let instName = projectList.sorted_list[stackName].name;
                    let stackData = projectList.sorted_list[stackName].services.project1;
                    let nickname =  projectList.stacks[instName].nickname ?  projectList.stacks[instName].nickname : '';
                    let environment = stackData.environment;
                    let formattedDate = projectList.stacks[instName].created_time === undefined ? '-' :projectList.stacks[instName].created_time.split(' ');
                    let date = formattedDate[0];
                    let time = formattedDate[1];
                    let standared = formattedDate[2];
                    let networkAccess = Constants.PROJECT_NETWORK_ACCESS[stackData.network_access];
                    let projStatus = 'recovering';
                    if ('status' in projectList.stacks[instName]) {
                      projStatus = projectList.stacks[instName].status.running_status.status;
                    }
                    let enabled = projectList.stacks[instName].enabled;
                    let description = projectList.stacks[instName].description
                    var port =  stackData.external_port
                    var ref = window.location.protocol + '//' + window.location.host + '/websocket-' + port;
                    let host;
                    let dockerName;
                    let env = getEnvironmentDisplayName(environment)
                    let envSplit = env.split('(');
                    if(stackData.network_access === 'deny_all'){
                      host = '127.0.0.1';
                      dockerName = instName + '_default'
                    } else if(stackData.network_access === 'allow_all'){
                      host = deviceID;
                      dockerName = 'host';
                    } else if(stackData.network_access === 'allow_inside_device'){
                      host = instName;
                      dockerName = 'sdk_bridge_network';
                    }
                    return (
                        <tr>
                            <Td><input type="radio" id="select_radio" style={{ 'cursor': 'pointer' }} defaultChecked={stackName === '0'} name="select_radio" value={stackData.project_name} onClick={() => radioButtonOnChange(stackData.project_name)}></input></Td>
                            <Td>
                              <div style={{'display': 'flex', 'flexDirection': 'column'}}>
                                  <div style={projNameStyle} title={nickname}>{nickname}</div>
                                  <div style={projNameStyle} title={instName}>({instName})</div>
                              </div>
                            </Td>
                            <Td style={{ 'text-align': 'center', 'white-space': 'nowrap' }}>
                              {projStatus === '-' ?
                                '-' :
                                <label className={projStatus + '-sdk'}>
                                  {getDisplayLabel(Constants.STATUS_TYPES, projStatus)}
                                </label>}
                            </Td>
                            <Td>{date}<br />{time} {standared}</Td>
                            <Td>{envSplit[0]}<br/> {'(' + envSplit[1]}</Td>
                            <Td title={description} style={descriptionStyle}>{description === '' ? '-' : description}</Td>
                            <Td>{networkAccess}</Td>
                            <Td>
                              <ContainerHost>
                                <InnerHostContainer title={host}><div style={hostStyle} id={instName + 'host'}> {host} </div><CopyButton primar onClick={() => clickToCopy(instName + 'host')} className={'small-button'} style={{ 'marginLeft': 'auto' }}>Copy</CopyButton></InnerHostContainer>
                              </ContainerHost>
                              <NetworkContainer title={dockerName}>(<div style={hostStyle}>{dockerName}</div>)</NetworkContainer>
                            </Td>
                            <Td>
                            {enabled
                              ? <><LinkIcon /><Link1 href={ref} target='_blank'>Open Dashboard</Link1></>
                              : '-'}
                            </Td>
                            <Td><SelectionControl type="switch" labelBefore="true" id={stackData.project_name} name={stackData.project_name} value={projectList.stacks[instName].enabled} checked={projectList.stacks[instName].enabled} onChange={enableToggle}  style={{ 'display': 'block', 'paddingTop': '10px' }} /></Td>
                        </tr>
                    );
                })}
              </table>
            </TableLayout>}
          
          {projectList && Object.keys(projectList.stacks).length === 0 &&
            <CenterDiv>
              <Label>No instance found.</Label>
            </CenterDiv>} 
        </Wrapper>}
    </ContentDiv>
  );
};

function getDefaultProjectData() {
  return Constants.POPUP_DEFAULT_STATUS;
}

const ProjectPopup = (props) => {
  const { isEditPopup, closePopup, selected_project, projectList} = props;
  const [projectSettings, setProjectSettings] = useState(selected_project === undefined ? getDefaultProjectData() : selected_project);
  const [index, setIndex] = useState(0);
  const [selectedRow, setSelectedRow] = useState();
  const [refreshPopUpTable, setRefreshPopUpTable] = useState(false);

  useEffect(() => {
    let pData = {...projectSettings}
    if (pData.env_vars) {
      let envData = pData.env_vars;
      let sortedData = [];
      Object.keys(envData).map((idx) => {
        let data = {};
        Object.keys(envData[idx]).map((i) => {
          data.key = i;
          data.value = envData[idx][i];
        })
        sortedData.push(data);
        return idx;
      });
      pData.env_vars = sortedData;
    }
    if(pData.project_name === ''){
      if(projectList.sorted_list.length !== undefined ){
        pData.project_name = 'project'+(projectList.sorted_list.length+1)
      } else {
        pData.project_name = 'project1';
      }
    }
    setProjectSettings(pData);
    setIndex(Object.keys(pData.env_vars).length);
  },[]);

  const handleOnChange = (event) => {
    if(event.target.name === 'project_name'){
      if (event.target.value.charAt(0) === '_') {
        return event.target.value.replace(/[^\w]/g, '');
      } else {
        event.target.value = event.target.value.replace(/[^\w]/g, '');
      }
    } else if(event.target.name === 'nickname') {
      if (event.target.value.charAt(0) === ' ') {
        event.target.value = event.target.value.replace(/[^\w]/g, '');
      }
    }
    setProjectSettings({ ...projectSettings, [event.target.name]: event.target.value });
  };

  const handleRadioChange = (event) => {
    event.persist();
    let data = { ...projectSettings };
    data[event.target.name] = event.target.value;
    setProjectSettings(data);
  };

  const saveProject = (event) => {
    let jsonData = Object.assign({}, projectSettings);
    var projectName = jsonData.project_name;
    let env_vars = [];
    let action = isEditPopup ? 'updated' : 'added';
    if (event) { event.preventDefault() }
    if (jsonData.project_name === undefined || jsonData.project_name.trim() === ''){
        swal('Please enter instance id.', { icon: 'error' })
        return;
    }
    if(jsonData.environment === 'select') {
      swal('Please select environment.', { icon: 'error' })
      return;
    }

    var projectNameRegex = /^[a-zA-Z0-9][a-zA-Z0-9_]*$/
    if(!projectNameRegex.test(jsonData.project_name)) {
        swal("Invalid project name. Allowed character are [a-zA-Z0-9_] and should start with alpha-numeric")
        return
    }

    if( jsonData.description == undefined || jsonData.description.trim() == "") {
        jsonData.description = ""
    }
    if( jsonData.nickname == undefined || jsonData.nickname.trim() == "") {
      jsonData.nickname = ""
    }

    for(var i=0; i < projectSettings.env_vars.length; i++){
      for(var j=i+1; j < projectSettings.env_vars.length; j++){
        if(projectSettings.env_vars[i].key === projectSettings.env_vars[j].key){
          swal('Duplicate key.', {icon: 'error'});
          document.getElementById('key-' + j).style.borderColor = 'red';
          document.getElementById('value-' + j).style.borderColor = 'red';
          return;
        }
      }
    }

    for(var k=0; k < projectSettings.env_vars.length; k++){
      if(document.getElementById('env_vars_row-' + k)){
        let envData = Object.assign({}, projectSettings.env_vars[k]);
        let key = envData.key;
        let value = envData.value;
        if(key === ''){
          swal('Please enter key', {icon: 'error'});
          document.getElementById('key-' + k).style.borderColor = 'red';
          return;
        }
        if(value === ''){
          swal('Please enter value', {icon: 'error'});
          document.getElementById('value-' + k).style.borderColor = 'red';
          return;
        }
        let env = {}
        env[key]= value;
        env_vars[k] = env;
      }
    }

    let finalJson ={}
    finalJson.description = jsonData.description;
    finalJson.nickname = jsonData.nickname;
    finalJson.enabled = projectList.stacks[projectName] === undefined ? true : projectList.stacks[projectName].enabled;
    finalJson.namespace = Constants.NAMSPACES.SDK;
    finalJson.type = 'project';
    finalJson.services = {};
    delete jsonData.description;
    delete jsonData.enabled;
    delete jsonData.nickname;
    finalJson.services.project1 = Object.assign({}, jsonData);
    finalJson.services.project1.type = 'jupyter_lab';
    finalJson.services.project1.enabled = true;
    finalJson.services.project1.env_vars = env_vars;

    if(action === 'updated') {
      axios.patch(Constants.EDGE_API_ENDPOINT + '/sdk/project/' + projectName + '/all', finalJson)
        .then(res => {
            swal('Instance updated successfully.', { icon: 'success' })
            setTimeout(function () { window.location.reload() }, 2000)
      }).catch(error => {
        if (error.response) {
          var errorObj = error.response.data;
          swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
        } else {
          swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
        }
      });
    } else {
      axios.put(Constants.EDGE_API_ENDPOINT + '/sdk/project/' + projectName + '/all', finalJson)
        .then(res => {
            swal('Instance added successfully.', { icon: 'success' })
            setTimeout(function () { window.location.reload() }, 2000)
        }).catch(error => {
          if (error.response) {
            var errorObj = error.response.data;
            swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
          } else {
            swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
          }
        });
    }
  };

  const deleteProject = (event) => {
    let projectName = projectSettings.project_name;
    swal({
      text: 'Enter instance id to delete',
      content: 'input',
      buttons: ['No', 'Yes'],
      dangerMode: true,
      icon: 'warning'
    })
    .then((name) => {
      // user has cancle the delete operation by clicking No
      if (name === null) {
        return;
      }
      if (name === projectName) {
        axios.delete(Constants.EDGE_API_ENDPOINT + '/sdk/project/' + projectName + '/all')
          .then(res => {
            swal('Instance delete request submitted successfully.', { icon: 'success' });
            setTimeout(function () { window.location.reload(); }, 2000);
          })
          .catch(error => {
            if (error.response) {
              var errorObj = error.response.data;
              swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
            } else {
              swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
            }
          });
      } else {
        return swal({ text: 'Instance id not match.', icon: 'error' });
      }
    });
  };

  const handleAddRow = () => {
    if(Object.keys(projectSettings.env_vars).length >= 20){
      swal('Maximum 20 variables can be configured.', {icon: 'error'});
      return;
    }
    setRefreshPopUpTable(false);
    let newIndex = index + 1;
    setIndex(newIndex);
    projectSettings.env_vars.push(getEnvironmentVariable());
    setProjectSettings(projectSettings);
  };

  const radioOnChangeInPopup = (idx) => {
    setSelectedRow(idx);
    setRefreshPopUpTable(false);
  };

  const handleDeleteEnv  = () =>  {
    projectSettings.env_vars.splice(selectedRow, 1);
    setProjectSettings(projectSettings);
    setRefreshPopUpTable(true);
    setSelectedRow('');
  };

  const updateEnvironmentSetting = (id, name, value) => {
    let idx = id.substring(id.indexOf('-') + 1);
      let data = { ...projectSettings };
      if(data.env_vars[idx]){
        data.env_vars[idx][name] = value;
        setProjectSettings(data);
      }
  };

  const handleOnChangeInnerInput = (event, idx) => {
    let pElement = document.getElementById(event.target.id);
    if(pElement){
      pElement.style.borderColor = 'lightgray';
    }
    if((event.target.id === 'key-'+ idx || event.target.id === 'value-'+ idx) && event.target.value.includes(' ')){
      event.target.value = event.target.value.replace(/\s/g, '');
    } else {
      if(event.target.id === 'key-'+ idx ) {
        if (event.target.value.charAt(0) === '_' || (/^[0-9]/.test(event.target.value.charAt(0)))) {
          return event.target.value.replace(/[^\w]/g, '');
        } else {
          event.target.value = event.target.value.replace(/__/g, '_');
          event.target.value = event.target.value.replace(/[^a-zA-Z_]/g, '');
        }
      } else if(event.target.id === 'value-'+ idx) {
        event.target.value = event.target.value.replace(/[^\w/:,]/g, '');
      }
    }
    if(event.target.id === 'key-'+ idx) {
      updateEnvironmentSetting(event.target.id, event.target.name, event.target.value.toUpperCase());
    } else {
      updateEnvironmentSetting(event.target.id, event.target.name, event.target.value);
    }
  };

  return (
    <div>
      {projectSettings !== undefined &&
        <PopupContainer>
          <SectionHeader style={{ 'display': 'flex' }}>
            <SectionLabel>{isEditPopup === true ? 'Edit' : 'Add'} Project</SectionLabel>
            {isEditPopup &&
              <div style={{ 'marginLeft': 'auto' }}>
                <Button danger id="btnDeleteProject" name="btnDeleteProject" style={{ 'margin': '0px' }} onClick={deleteProject}>Delete</Button>
              </div>
            }
          </SectionHeader>
          <Tabs defaultTab="general" style={{ 'padding': '6px' }}>
            <TabList>
              <Tab tabFor="general" className={'rwt__tab'}>General</Tab>
              <Tab tabFor="environment_variable" className={'rwt__tab'}>Environment Variable</Tab>
            </TabList>
            <TabPanel tabId="general">
            <div style={{ 'height': '390px', 'padding': '0px 0px 0px 20px', width: '560px', overflow:'auto'}}>
            <Row>
              <MandatoryLabel style={{width: '150px'}}>Instance ID</MandatoryLabel>
              <TextFieldSmall id="project_name" autoComplete="off" name="project_name" maxLength="63" disabled={isEditPopup} value={projectSettings.project_name} onChange={handleOnChange} style={{'width':'360px'}} />
            </Row>
            <Row>
              <TextFieldLabel style={{width: '150px'}}>Nickname</TextFieldLabel>
              <TextFieldSmall id='nickname' autoComplete='off' name='nickname'  value={projectSettings.nickname} onChange={handleOnChange} maxLength='100' style={{'width':'360px'}}/>
            </Row>
            <Row>
              <MandatoryLabel style={{width: '150px'}}>Environment</MandatoryLabel>
              <SelectField id="environment" name="environment" style={{ 'margin-top': '10px', 'width':'360px' }} value={projectSettings.environment} onChange={handleOnChange}>
                <SelectOptions items={Constants.ENV_LIST}/>
              </SelectField>
            </Row>
            <Row style={{'display': 'flex'}}>
              <TextFieldLabel style={{width: '150px'}}>Description</TextFieldLabel>
              <div style={{'marginTop':'20px', width: '360px', 'height':'auto', overflow:'auto'}}>
                <TextArea id="description" autoComplete="off" name="description" maxLength="255" value={projectSettings.description}  onChange={handleOnChange} />    
              </div>
            </Row>
            <Row>
                <fieldset style={{ 'margin-top': '20px', 'margin-left': '-1px', 'width': '510px'}}>
                  <legend>Network Access</legend>
                  <Row style={{ 'marginTop': '15px' }}>
                    <div style={{ 'display': 'flex', 'paddingBottom': '15px' }}>
                      <div>
                        <input type="radio" id="network_access" name="network_access" value="deny_all" checked={projectSettings.network_access === 'deny_all' || false} onChange={handleRadioChange} /> {Constants.PROJECT_NETWORK_ACCESS.deny_all}
                      </div>
                      <div style={{ 'marginLeft': '30px' }}>
                        <input type="radio" id="network_access" name="network_access" value="allow_inside_device" checked={projectSettings.network_access === 'allow_inside_device' || false} onChange={handleRadioChange} /> {Constants.PROJECT_NETWORK_ACCESS.allow_inside_device}
                      </div>
                      <div style={{ 'marginLeft': '30px' }}>
                        <input type="radio" id="network_access" name="network_access" value="allow_all" checked={projectSettings.network_access === 'allow_all' || false} onChange={handleRadioChange} /> {Constants.PROJECT_NETWORK_ACCESS.allow_all}
                      </div>
                    </div>
                    </Row>
                  </fieldset>
                </Row>
          </div>
            </TabPanel>
            <TabPanel tabId="environment_variable">
            <div style={{'height': '390px', width: '560px'}}>
              <div style={{'padding': '10px 2%'}}>
              <Row>
                <RightDiv style={{'display': 'inline-block', 'marginRight': '2px'}}>
                {selectedRow && <Button id="btnDeleteEnvironment" name="btnDeleteEnvironment" onClick={handleDeleteEnv}>Delete</Button>}
                  <Button primary id="btnEnv" name="btnEnv" style={{'marginRight': '0'}} onClick={handleAddRow}>Add</Button>
                </RightDiv>
              </Row>
                <Row >
                  <TableLayout style={{'overflow': 'auto', 'height': '250px'}}>
                  {(refreshPopUpTable || projectSettings) && 
                  <table style={{'min-width': '100%', 'border-collapse': 'collapse'}}>
                    <tr style={{'background-color': '#1f303a', 'color': 'white'}}>
                      <Th></Th>
                      <Th style={{'textAlign': 'left'}}>Key</Th>
                      <Th>Value</Th>
                    </tr>
                    { Object.keys(projectSettings.env_vars).map((idx) => (
                      <tr id={'env_vars_row-' + idx}>
                        <Td>
                          <input type="radio" id={'environment_row_radio-'} value={idx} name={'environment_row_radio'} style={{'cursor':'pointer'}} onClick={()=> radioOnChangeInPopup(idx)}></input>
                        </Td>
                        <Td style={{'padding': '5px'}}><TextField autoComplete="off" maxLength="32" id={'key-' + idx } name={'key'} value={projectSettings.env_vars[idx].key} disabled={projectSettings.env_vars[idx].disabled} onChange={(e) => handleOnChangeInnerInput(e, idx)} /></Td>
                        <Td style={{'padding': '5px'}}><TextField autoComplete="off" maxLength="32" id={'value-' + idx} name={'value'} value={projectSettings.env_vars[idx].value} onChange={(e) => handleOnChangeInnerInput(e, idx)} /></Td>
                      </tr>))}
                    </table>}
                  </TableLayout>
                </Row>
              </div>
              </div>
            </TabPanel>
            </Tabs>
          <Row>
            <PopupButtons>
              <Button primary onClick={saveProject}>Save</Button>
              <Button id="btnCancelProject" danger onClick={() => { closePopup(); }}>Cancel</Button>
            </PopupButtons>
          </Row>
        </PopupContainer>
      }
    </div>
  );
};

export default Project;
