import React, { useState, useEffect } from 'react'
import { Tabs, Tab, TabList } from 'react-web-tabs';
import axios from 'axios';
import { ContentDiv, Wrapper, Label, LeftDiv, Spacer, TextField, TextArea, CopyButton, TextFieldLabel, ButtonSmall, TextFieldSmall, MandatoryLabel, SelectField, Row, Column, RightDiv, SectionHeader, Button, SectionLabel } from '../utils/Styles';
import SelectOptions from '../components/SelectOptions';
import * as Constants from '../utils/Constants';
import "../css/react-tabs.css"
import { SelectionControl } from 'react-md';
import swal from "sweetalert";
import styled from 'styled-components';
import { isEmpty } from '../utils/Utils';
import Loader from './Loader';


const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
`;

const MessageLabel = styled(Label)`
    width: 100%;
    color: gray;
    font-size: 0.9em;
`;

const MessageContaier = styled.div`
    padding-top: 15px;
`;

const DEFAULT_CONNECTION_DATA = {
    'type': 'safie_settings',
    'enabled':true,
    'auth_code': '',
    'client_id':'',
    'client_secret':''
}

const SafieCameraSettings = (props) => {
   
    const [configure, setConfigure] = useState(false);
    const [readOnly, setReadOnly] = useState(true);
    const [connectionData, setConnectionData] = useState(DEFAULT_CONNECTION_DATA);
    const [isgetData, setgetData] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isStreamPresent, setStreamPresent] = useState(false);


    const getStreamList = () => {
        axios.get(Constants.EDGE_API_ENDPOINT + '/stacks/all')
        .then(res => {
            const stacks = res.data["data"]["stacks"];
            Object.keys(stacks).map((stack_name) => {
                if(stacks[stack_name]["action"] === "DeleteStack"){
                    delete stacks[stack_name];
                } else if(stacks[stack_name]["services"]["recorder1"]["camera_type"] !== "SAFIE"){
                    delete stacks[stack_name];
                } else if(stacks[stack_name] !== undefined) {
                    if(stacks[stack_name].enabled === false){
                        delete stacks[stack_name];
                    }
                }
            });
            if(Object.keys(stacks).length === 0){
                setStreamPresent(false);
            } else {
                setStreamPresent(true);
            }
        }).catch(error => {
           console.log(error);
           setStreamPresent(true);
        });
    }

    const getConfigDetails = () => {
        axios.get(Constants.EDGE_API_ENDPOINT + '/system/safie/all')
        .then(res => {
            if(res.data.data.action === 'DeleteStack') {
                setLoading(false);
                setgetData(false);
                setConfigure(false);
            } else {
                if(Object.keys(res.data.data).length !== 0) {
                    let inputs = Object.assign({}, res.data.data.services.safie_settings1);
                    inputs.enabled =  res.data.data.enabled;
                    setConnectionData(inputs)
                    setLoading(false);
                    setgetData(true);
                    setConfigure(true);
                } else {
                    setLoading(false);
                    setgetData(false);
                    setConfigure(false);
                }
            }
        })
        .catch(error => {
            console.log(error);
            setgetData(false);
            setConfigure(false);
            setLoading(false);
        });
    }

    useEffect(() => {
        getConfigDetails();
        getStreamList();
    },[]);

    const onEdit = () => {
        setReadOnly(false);
    }

    const onConfigure = () => {
        setReadOnly(false);
        setConfigure(true);
        setConnectionData(DEFAULT_CONNECTION_DATA);
    }

    const onHandleToggle = (value, event) => {
        const name = event.target.name;
        if(!readOnly) {
            setConnectionData({ ...connectionData, [name]: value });
        }
    }

    const handleOnChange = (event) => {
        if (!readOnly) {
            if (event.target.name === 'auth_code' || event.target.name === 'client_id' || event.target.name === 'client_secret' ) {     
                if (event.target.value.charAt(0) === '_') {
                    return event.target.value.replace(/[^\w]/g, '');
                } else {
                    event.target.value = event.target.value.replace(/[^\w\-]/g, '');
                }
            }
            setConnectionData({ ...connectionData, [event.target.name]: event.target.value });  
        }
    };

    const onSave = () => {
        let jsonData = Object.assign({}, connectionData);
        let auth_code = jsonData.auth_code;
        let clientId = jsonData.client_id;
        let clientSecret = jsonData.client_secret;
        let action = isgetData ? 'updated' : 'added';
        let safieEnable = jsonData.enabled;
        
        if (isEmpty(clientId)) {
            swal('Please enter client id', { icon: 'error' });
            return;
        }
        if (isEmpty(clientSecret)) {
            swal('Please enter client secret', { icon: 'error' });
            return;
        }
        if (isEmpty(auth_code)) {
            swal('Please enter authorization code', { icon: 'error' });
            return;
        }

        let finalJson = {};
        finalJson.type = 'safie';
        finalJson.namespace = "system";
        finalJson.enabled = safieEnable;
        finalJson.services = {};
        finalJson.services.safie_settings1 = {};
        finalJson.services.safie_settings1.enabled = true;
        finalJson.services.safie_settings1.type = 'safie_settings';
        finalJson.services.safie_settings1.auth_code = auth_code;
        finalJson.services.safie_settings1.client_id = clientId;
        finalJson.services.safie_settings1.client_secret = clientSecret;

      if (action === 'added') {
            axios.put(Constants.EDGE_API_ENDPOINT + '/system/safie/all', finalJson)
            .then(res => {
                swal('Safie connector added successfully.', { icon: 'success' });
                setReadOnly(true);
                setgetData(true);
            })
            .catch(error => {
                if(error.response){
                    var errorObj = error.response.data;
                    swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
                }else{
                    swal({text: "Unable to connect to the edge-api service" , icon: "error"});
                }
            });
        } else {
            if(isStreamPresent) {
                swal('Please disable all safie streams before updating safie account information.', { icon: 'error' });
                return;
            } else {
                axios.patch(Constants.EDGE_API_ENDPOINT + '/system/safie/all', finalJson)
                .then(res => {
                    swal('Safie connector updated successfully.', { icon: 'success' });
                    setReadOnly(true);
                    setgetData(true);
                })
                .catch(error => {
                    if(error.response){
                        var errorObj = error.response.data;
                        swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
                    }else{
                        swal({text: "Unable to connect to the edge-api service" , icon: "error"});
                    }
                });
            }
        }  
    }

    const deleteConfiguration = () => {
        if(isStreamPresent) {
            swal('Please disable all safie streams before deleting safie account information.', { icon: 'error' });
            return;
        } else {
            swal({
                text: 'Are you sure you want to delete this account',
                buttons: ['No', 'Yes'],
                dangerMode: true,
                icon: 'warning'
              })
            .then((isConfirm) => {
                if (isConfirm) {
                    axios.delete(Constants.EDGE_API_ENDPOINT + '/system/safie/all')
                    .then(res => {
                        swal('Safie connector deleted successfully.', { icon: 'success' });
                        setReadOnly(true);
                        setgetData(false);
                        setConfigure(false);
                    })
                    .catch(error => {
                        if(error.response){
                            var errorObj = error.response.data;
                            swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
                        }else{
                            swal({text: "Unable to connect to the edge-api service" , icon: "error"});
                        }
                    });
                } else {
                    setReadOnly(true);
                }
            });
        }
    };
    const clickToCopy = () => {
        const textarea = document.createElement('textarea');
        textarea.id = 'copyTextArea';
        textarea.value =  document.getElementById('client_id_label').innerText + ':\n' + document.getElementById('client_id').value + '\n\n' +  document.getElementById('client_secret_label').innerText + ':\n' + document.getElementById('client_secret').value + '\n\n' + document.getElementById('auth_code_label').innerText + ':\n' + document.getElementById('auth_code').value + '\n';
        textarea.setAttribute('readonly', '');
        textarea.style.position = 'absolute';
        textarea.style.width = '0px';
        textarea.style.height = '0px';
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.getElementById('copyTextArea').remove();
      };


    return (
            <ContentDiv>
                <Wrapper>
                    <SectionHeader>
                        <SectionLabel>Safie Connector</SectionLabel>
                    </SectionHeader>
                    <Loader isLoading={loading} />
                    {!configure && !loading &&
                    <Row>
                        <Column style={{'paddingLeft': '0'}}>
                            <Label style={{'margin-left': '20px', 'margin-top': '5px' }}>Safie Connector is not configured.</Label><br />
                            <Button style={{'margin-left': '20px'}} primary onClick={onConfigure}>Configure</Button>
                        </Column>
                    </Row>}
                    {configure && connectionData && !loading &&
                        <Row>
                            <Column style={{'width':'580px'}}>
                                <div style={{'display':'flex', width:'100%'}}>
                                    {/* <SelectionControl id="enable" type="switch" label={connectionData.enabled ? "Enabled" : "Disabled"} name="enabled" labelBefore="true" checked={connectionData.enabled || false} onChange={onHandleToggle} style={{'display': 'none'}}/> */}
                                    {isgetData && !readOnly &&
                                    <div style={{'display':'flex', 'justifyContent':'flex-end', width:'100%'}}>
                                    <ButtonWrapper style={{'marginRight': '2px'}}>
                                        <Button danger id='btnCancle' name="btnDeleteInstance" style={{'margin':'0'}} onClick={deleteConfiguration}>Delete</Button>
                                    </ButtonWrapper>
                                    </div>}
                                    {readOnly && <div style={{'display':'flex', 'justifyContent':'flex-end', width:'100%'}}><ButtonWrapper style={{'marginRight': '1px'}}><CopyButton primary onClick={() => clickToCopy()} style={{ 'float': 'right', 'cursor': 'pointer' }}>Copy</CopyButton></ButtonWrapper></div>}
                                </div>
                                <div>
                                    <MandatoryLabel id='client_id_label' style={{'width':'160px'}}>Client ID</MandatoryLabel>
                                    <TextFieldSmall id="client_id" autoComplete="off" name="client_id" style={{'width':'355px'}} disabled={readOnly} value={connectionData.client_id} onChange={handleOnChange} />
                                </div>
                                <div>
                                    <MandatoryLabel id='client_secret_label' style={{'width':'160px'}}>Client Secret</MandatoryLabel>
                                    <TextFieldSmall id="client_secret" autoComplete="off" name="client_secret" style={{'width':'355px'}} disabled={readOnly} value={connectionData.client_secret} onChange={handleOnChange} />
                                </div>
                                <div>
                                    <MandatoryLabel id='auth_code_label' style={{'width':'160px'}}>Authorization Code</MandatoryLabel>
                                    <TextField id="auth_code" autoComplete="off" name="auth_code" style={{'width':'355px'}} disabled={readOnly} value={connectionData.auth_code} onChange={handleOnChange} />
                                </div>
                                {readOnly ? 
                                    <ButtonWrapper style={{'marginRight': '2px'}}>
                                        {isStreamPresent ? 
                                            <Button primary style={{"background":'lightgray', "border":"none" }}>Edit</Button> :
                                            <Button primary onClick={onEdit}>Edit</Button> }
                                    </ButtonWrapper>: 
                                    <ButtonWrapper style={{'marginRight': '2px'}}>
                                        <Button primary onClick={onSave}>Save</Button>
                                        <Button id='btnCancle' danger onClick={() =>  window.location.reload()}>Cancel</Button>
                                    </ButtonWrapper>}
                                    {isStreamPresent && 
                                        <MessageContaier>
                                            <MessageLabel style={{"color": "#ff4343"}}>You need to delete all "Safie Camera Streams" before changing the safie connector setting.</MessageLabel>
                                        </MessageContaier>}
                            </Column>
                        </Row>}
                </Wrapper>
            </ContentDiv>
            )
}

export default SafieCameraSettings;