import styled, {css} from 'styled-components';
import { ReactComponent as InfoSVG } from '../images/info.svg';
import { ReactComponent as ThreeDotSVG } from '../images/three_dot.svg';
import { ReactComponent as AddSVG } from '../images/add.svg';
import { ReactComponent as CopySVG } from '../images/copy.svg';
import { ReactComponent as LinkSVG } from '../images/link.svg';
import { ReactComponent as InfoEyeSVG } from '../images/infoeye.svg';

export const Image=styled.img`
  width: 480px;
  height: 320px;
  background-image: url('./images/no_image.png')
`;

export const ImageDiv=styled.div`
  float: left;
  padding: 1%;
  width: 22%;
`;

export const ImageIcon=styled.img`
  width: 50px;
  height: 25px;
  padding-right: 20px;
`;

export const ImageIconThin=styled.img`
  width: 45px;
  height: 25px;
  padding-right: 20px;
`;

export const ImageIconMatch=styled.img`
  width: 45px;
  height: 19px;
  padding-right: 20px;
  border: 4px;
`;

export const Link=styled.a`
  color: #0097f1;
  cursor: pointer;
`;

export const RightDiv=styled.div`
  float: right;
`;

export const LeftDiv=styled.div`
  float: left;
  padding-right: 20px;
`;

export const CenterDiv=styled.div`
  text-align: center;
  padding: 10px 10px 0px 10px;
  font-size: 1.1em;

`;

export const LeftDiv1=styled.div`
  float: left;
  width: 80%;
`;

export const StreamContentDiv=styled.div`
  padding: 10px;
`;

export const RotateLabel=styled.label`
  /* Safari */
  -webkit-transform: rotate(-90deg);
  /* Firefox */
  -moz-transform: rotate(-90deg);
  /* IE */
  -ms-transform: rotate(-90deg);
  /* Opera */
  -o-transform: rotate(-90deg);
  float: left;
  font-weight: bold;
`;

export const Spacer=styled.div`
  height: 15px;
  background-color: #eef3fa;
  margin-left: 10px;
  margin-right: 10px;
`;

export const ContentSpacer=styled.div`
  width: 100%;
  height: 30px;
`;

export const Wrapper=styled.div`
  width: 98%;
  background-color: #ffffff;
  text-align: left;
  display: inline-block;
  margin-left: 1%;
  margin-right: 1%;
`;

export const ContentDiv=styled.div`
  width: 82vw;
  overflow-y: auto;
  padding-bottom: 10px;
`;

export const ButtonWrapper=styled.div`
  text-align:center;
  margin-left: 3%;
  margin-right: 3%;
`;

export const SectionLabel = styled.label`
  display:inline-block;
  font-size: 1.3em;
  color: #0097f1
`;

export const SubSectionLabel = styled.label`
  margin: 5px;
  padding: 5px 5px 10px 15px;
  display:inline-block;
  font-size: 1.3em;
  color: #0097f1
`;

export const Label = styled.label`
  display:inline-block;
  margin-bottom:5px;
`;

export const LabelBold = styled.label`
  display:inline-block;
  margin-bottom:5px;
  font-weight:bold;
  background-color: #ffffff;
`;

export const Label2 = styled.label`
  display:inline-block;
  padding-right:20px;
  vertical-align: top;
  float:left;
  margin-top:-3px;
`;

export const Label3 = styled.label`
  display:inline-block;
  padding-top:10px;
  vertical-align: top;
`;

export const TextFieldLabel = styled(Label)`
  width: 190px;
  margin-top: 25px;
  
`;

export const SelectFieldLabel = styled(Label)`
  width: 190px;
  margin-top: 25px;
`;

export const FieldSet=styled.fieldset`
  width:100%;
  margin-top: 15px;
  padding: 0px 10px 10px 10px;
`;

export const TableContainer = styled.div`
  white-space: nowrap;
  width: 100%;
`;

export const Td = styled.td`
  border: 1px solid #ddd;
  text-align:center;
  padding-left: 5px;
  padding-right: 5px;
`;

export const Th = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  text-align:center;
`;

export const PopupContainer = styled.div`
  padding: 10px;
  text-align:left;
`;

export const PopupButtons = styled.div`
  display: flex;
  justify-content: center;
`;

export const overlayStyle = {
  background: 'rgba(0,0,0,0.1)' ,
  width: 'auto',
  height: 'auto',
  overflow:'auto'
};

export const TextField = styled.input`
  width: 100%;
  font-size: 1.0em;
  padding: 6px 8px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  border-color: ${props => props.error ? 'red' : 'lightgray'};
  margin: 0;
  &:disabled {
    color: #000000;
    background: #f2f2f2;
  }
  &:focus {
    outline: none;
    border-color: rgb(137, 188, 225);
  }
  &:read-only:: placeholder {
    color: #f2f2f2;
  }
`;

export const TextFieldSmall = styled(TextField)`
  width: 205px;
 
`;

export const TextArea = styled.textarea`
  max-width:100%;
  min-width:100%;
  min-height: 50px;
  max-height: 120px;
  font-size: 1.0em;
  padding: 6px 8px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  border-color: ${props => props.error ? 'red' : 'lightgray'};
  margin: 0;
  &:disabled {
    color: #000000;
    background: #f2f2f2;
  }
  &:focus {
    outline: none;
    border-color: rgb(137, 188, 225);
  }
`;

export const SelectField = styled.select`
  width: 100%;
  font-size: 1em;
  padding: 6px 8px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  border-color: ${props => props.error ? 'red' : 'lightgray'};
  margin: 0;
  &:disabled {
    color: #000000;
    background: #f2f2f2;
  };
  &:enabled {
    background: #ffffff;
  }
  &:focus {
    outline: none;
    border-color: rgb(137, 188, 225);
  }
`;

export const SelectFieldSmall = styled(SelectField)`
  width:205px;
`;

export const Row = styled.div`
  width: 100%;
  clear:both;
`;

export const Column = styled.div`
  width: 50%;
  float:left;
  padding: 10px 2%
`;

export const SectionHeader=styled.div`
  margin: 5px;
  padding: 5px 5px 10px 15px;
  border-bottom: 1px solid lightgray;
  background-color: #ffffff;
`;

export const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? '#0097f1' : '#FF7F50'};
  color: #ffffff;
  font-size: 1em;
  margin: 1em 0em 1em 1em;
  padding: 0.4em 1em;
  border: 2px solid ${props => props.primary ? '#0097f1' : '#FF7F50'};
  border-radius: 3px;
  opacity: 0.7
  :hover {
    cursor: pointer;
    opacity: 1.0;
  }
  :focus {
    outline:none;
  }
`;

export const ButtonSmall = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? '#0097f1' : '#FF7F50'};
  color: #ffffff;
  font-size: 1em;
  padding: 0.3em 0.6em;
  border: 2px solid ${props => props.primary ? '#0097f1' : '#FF7F50'};
  border-radius: 3px;
  opacity: 0.7;
  :hover {
    cursor: pointer;
    opacity: 1.0;
  }
  :focus {
    outline:none;
  }
`;

export const TableButton = styled(Button)`
  font-size: 12px;
  padding: 5px 5px;
  margin: 0px 5px;
`;

export const MdnsIpButton = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? '#f2ebeb' : '#FF7F50'};
  color: #0097f1;;
  font-size: 0.7em !important;
  margin: 0em !important;
  padding: 0em !important;
  border: 1px solid ${props => props.primary ? '#f2ebeb' : '#FF7F50'};
  border-radius: 3px;
  :hover {
    cursor: pointer;
    opacity: 0.9;
  }
  :focus {
    outline:none;
  }
`;

export const Separator = styled.div`
  clear:both;
  display: block;
  height: 1px;
  border: 0;
  border-bottom: 1px solid #ddd;
  margin: 1% 3%;
  padding-top: 1%;
`;

export const Box = styled.div`
  clear: both;
  border: 1px solid lightgray;
  padding-left: 10px;
  width: 100%;
`;

export const BoxTitle = styled.div`
  margin-top:-13px;
  font-weight: bold;
  background: #FFFFFF;
  width: 55px;
  text-align: center;
`;

export const BoxLabel = styled.label`
  display:inline-block;
  margin-bottom:5px;
  float:left;
  padding-top: 10px;
  width: 150px;
`;

export const SliderValue = styled.div`
  margin-top: -10px;
  text-align: center;
`;

export const RunningLabel = styled.label`
  display:inline-block;
  color: green;
  padding-left: ${window.innerWidth <= 1024 ? '3px' : '20px'};
  padding-top: 5px;
  font-weight: ${window.innerWidth <= 1024 ? 'normal' : 'bold'};
`;

export const DisabledLabel = styled.label`
  display:inline-block;
  color: gray;
  padding-left: ${window.innerWidth <= 1024 ? '7px' : '20px'};
  padding-top: 5px;
  font-weight: ${window.innerWidth <= 1024 ? 'normal' : 'bold'};
`;

export const PausedLabel = styled.label`
  display:inline-block;
  color: #0097f1;
  padding-left: ${window.innerWidth <= 1024 ? '7px' : '20px'};
  padding-top: 5px;
  font-weight: ${window.innerWidth <= 1024 ? 'normal' : 'bold'};
`;

export const RecoveringLabel = styled.label`
  display:inline-block;
  color: orange;
  padding-left: ${window.innerWidth <= 1024 ? '7px' : '20px'};
  padding-top: 5px;
  font-weight: ${window.innerWidth <= 1024 ? 'normal' : 'bold'};
`;

export const FailedLabel = styled.label`
  display:inline-block;
  color: red;
  padding-left: ${window.innerWidth <= 1024 ? '7px' : '20px'};
  padding-right: ${window.innerWidth <= 1024 ? '0px' : '10px'};
  padding-top: 5px;
  font-weight: ${window.innerWidth <= 1024 ? 'normal' : 'bold'};
`;

export const ListHeader=styled.h3`
  padding: 20px 5px 5px 15px;
  background-color: #ffffff;
  color: #0097f1;
  font-weight: bold;
  font-size: 18px;
`;

export const InfoIcon = styled(InfoSVG)`
  height: 17px;
  width: 20px;
  padding-left: 4px;
  margin-top: 4px;
  object-fit: contain;
  cursor: pointer;
  margin-top: 6px;
`;

export const ThreeDotMenu = styled(ThreeDotSVG)`
  height: 14px;
  width: 16px;
  padding-left: 3px;
  object-fit: contain;
  cursor: pointer;
  opacity: 0.5;
`;

export const AddMenu = styled(AddSVG)`
  height: 32px;
  width: 32px;
  margin-left: -6px;
  margin-top: -6px;
  object-fit: contain;
  cursor: pointer;
  opacity: 0.5;
`;

export const CopyButton = styled(CopySVG)`
  height: 18px;
  width: 18px;
  object-fit: contain;
  cursor: pointer;
  opacity: 0.5;
`;

export const InfoEyeButton = styled(InfoEyeSVG)`
  height: 17px;
  width: 20px;
  padding-left: 4px;
  margin-top: 4px;
  object-fit: contain;
  cursor: pointer;
  margin-top: 6px;
`;

export const AddButton = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? '#0097f1' : '#FF7F50'};
  color: #ffffff;
  font-size: 1em;
  margin: 0.4em 0.6em;
  padding: 0.3em 0.6em;
  border: 2px solid ${props => props.primary ? '#0097f1' : '#FF7F50'};
  border-radius: 3px;
  opacity: 0.7
  :hover {
    cursor: pointer;
    opacity: 1.0;
  }
  :focus {
    outline:none;
  }
`;

export const MessageLabel = styled(Label)`
  width: 100%;
  margin-top: 5px;
  color: gray;
  font-size: 0.9em;
`;

export const ProjectTextArea = styled.textarea`
  width: 100%;
  font-size: 1.0em;
  padding: 6px 8px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  border-color: ${props => props.error ? 'red' : 'lightgray'};
  margin: 0;
`;

export const MandatoryLabel = styled(TextFieldLabel)`
  &:after {
    font-weight: bold;
    content:" *";
    color: #EE4B2B;
  }
`;

export const MandatoryLabelStream = styled(Label)`
  &:after {
    font-weight: bold;
    content:" *";
    color: #EE4B2B;
  }
`;

export const MandatoryLabelBroadcaster = styled(Label3)`
  &:after {
    font-weight: bold;
    content:" *";
    color: #EE4B2B;
  }
`;

export const Link1=styled.a`
  color: #0097f1;
  cursor: pointer;
  text-decoration: none;
`;

export const LinkIcon = styled(LinkSVG)`
  width: 12px;
  height: 17px;
  padding-top: 5px;
  margin-right: 5px;
`;
