import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { ContentDiv, Spacer, Wrapper, SectionHeader, SectionLabel  } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import Schedule from "../components/Schedule";
import { Tabs, Tab, TabList, TabPanel } from 'react-web-tabs';
import { useHistory, useLocation } from 'react-router-dom';


const ScheduleSettings = (props) => {
    const [scheduleRebootData, setScheduleRebootData] = useState();
    const [scheduleShutdownData, setScheduleShutdownData] = useState();
    const [selectedTab, setSelectedTab] = useState('reboot');
    const params = useLocation().search;
    const [historyParams] = useState(params);
    const history = useHistory();
    const scheduleTypeReboot="Reboot";
    const scheduleTypeShutdown="Shutdown";

    const setParams = useCallback(() => {
        const params = '?' +
          `${selectedTab === 'reboot' ? 'reboot' : '&selectedTab=' + selectedTab}`
        if (params === '?') {
          return '';
        }
        return params;
      }, [selectedTab]);
    
      const fetchHistoryParams = useCallback(() => {
        const string = new URLSearchParams(historyParams);
        let newParams = {};
        newParams.selectedTab = string.get('selectedTab');
    
        if (newParams.selectedTab) {
          setSelectedTab(newParams.selectedTab);
        }
      }, [historyParams]);
    
      useEffect(()=>{
        fetchHistoryParams();
      },[fetchHistoryParams]);
    
      useEffect(() => {
        history.push('/schedule' + setParams());
      }, [history, setParams]);

      function onTabChange(tabId){
        setSelectedTab(tabId);
      }

    function getScheduleData(scheduleData, scheduleType){
        let data = {}
        let scheduleDayList = scheduleData.weekday.split(",");
        data["hour" + scheduleType] = scheduleData.hour;
        data["minute" + scheduleType] = scheduleData.minute;
        Object.keys(Constants.WEEK_DAYS).map((week_day) => {
            if(scheduleDayList.includes(Constants.WEEK_DAYS[week_day])) {
                data[week_day + scheduleType] = true;
            } else {
                data[week_day + scheduleType] = false;
            }
        });

        if(scheduleData.enabled === true) {
            data["enabled" + scheduleType] = true;
        } else {
            data["enabled" + scheduleType] = false;
        }
        return data;
    }

    useEffect(() => {
        axios.get(Constants.EDGE_API_ENDPOINT + '/devices/cron')
        .then(res => {
            let scheduleData = res.data['data'];
            setScheduleRebootData(getScheduleData(scheduleData["schedule_reboot"], scheduleTypeReboot));
            setScheduleShutdownData(getScheduleData(scheduleData["schedule_shutdown"], scheduleTypeShutdown));
        })
     }, []);

    return (
        <ContentDiv>
            <Wrapper>
                <SectionHeader>
                    <SectionLabel>Schedule</SectionLabel>
                </SectionHeader>
                <Tabs defaultTab={selectedTab} onChange={onTabChange}>
                    <TabList>
                        <Tab tabFor="reboot" className={'rwt__tab'}>Reboot</Tab>
                        <Tab tabFor="shutdown" className={'rwt__tab'}>Shutdown</Tab>
                    </TabList>
                    <TabPanel tabId='reboot'>
                        <Schedule data={scheduleRebootData} scheduleType={scheduleTypeReboot}/>
                    </TabPanel>
                    <TabPanel tabId='shutdown'>
                        <Schedule data={scheduleShutdownData} scheduleType={scheduleTypeShutdown}/>
                    </TabPanel>
                </Tabs>
            </Wrapper>
        </ContentDiv>
    )
}

export default ScheduleSettings;
