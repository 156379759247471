import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Loader from './Loader';
import { Column, Row,  Wrapper, Button, MessageLabel } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import { SelectionControl } from 'react-md';
import swal from 'sweetalert';
import styled from 'styled-components';

const ContentDiv=styled.div`
  width: 80vw;
  overflow-y: auto;
  padding-bottom: 10px;
`;

const MessageContaier = styled.div`
  padding: 0 5px 1px 15px;
`;

const RecentActivitiesSettings = () => {
  const [mode, setMode] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [loading, setLoading] = useState(true);

  const getMode = useCallback(() => {
    axios.get(Constants.EDGE_API_ENDPOINT + '/system/services/netdata/all')
      .then(res => {
        const data = res.data.data.mode;
        let modeSet = data === 'normal' ? false : true;
        setMode(modeSet);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setMode(true);
        setLoading(false);
      });
  },[]);

  useEffect(() => {
    getMode();
  },[]);

  const handleToggle = (value) => {
    if(!readOnly) {
      setMode(value);
    }
  };

  const onSave = () => {
    let jsonData={
        'action':  'change_mode', 
        'source': 'edge_ui', 
        'data': {
          'mode': mode ? 'debug' : 'normal'
        }
      };
      let modeLabel = mode ? 'enabled' : 'disabled';
      swal({
        text: 'Are you sure you want to change the mode?',
        buttons: ['No', 'Yes'],
        dangerMode: true,
        icon: 'warning'
      })
        .then((isConfirm) => {
          if (isConfirm) {
            axios.post(Constants.EDGE_API_ENDPOINT + '/system/services/netdata/all', jsonData)
              .then(res => {
                swal('Debug mode ' + modeLabel + ' successfully.', { icon: 'success', timer: '3000' });
                setReadOnly(true);
              })
              .catch(error => {
                if (error.response) {
                  var errorObj = error.response.data;
                  swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
                } else {
                  swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
                }
              });
          }
        });  
  }

  return(
    <ContentDiv>
      <Wrapper>
      <Loader isLoading={loading} />
      {!loading && 
        <>
          <Row>
            <Column style={{'paddingLeft': '0', display:'flex'}}>
              <SelectionControl id='mode' type='switch' label='Debug Mode' name='mode' labelBefore='true' value={mode} checked={mode} onChange={handleToggle} readonly={readOnly} style={{'margin-left': '16px', 'marginTop': '10px'}} />
              {readOnly ? <Button id='editButton' primary onClick={() => setReadOnly(false)}>Edit</Button> : 
                <div>
                  <Button id='saveButton' primary onClick={onSave}>Save</Button>
                  <Button id='cancelButton' onClick={() =>  window.location.reload()}>Cancel</Button>
                </div>}
            </Column>
          </Row>
          <Row>
            <Column style={{'paddingLeft': '0', display:'flex'}}>
              <MessageContaier>
                <MessageLabel style={{'text-size-adjust': '100%'}}>
                  <b>Note:</b> When debug mode is enabled more detailed information is shown such as container memory usage, slab details, high mem usage, interrupts, and so forth.<br/>
                </MessageLabel>
              </MessageContaier>
            </Column>
          </Row>
        </>}
      </Wrapper>
    </ContentDiv>
  );
};

export default RecentActivitiesSettings;
