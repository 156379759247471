import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { SelectionControl, CircularProgress } from 'react-md';
import { Row, Button, SectionHeader, SectionLabel, FieldSet, CenterDiv, Label, TableContainer, SelectField, MdnsIpButton, Td, Th, overlayStyle, PopupContainer, TextFieldLabel, TextFieldSmall, PopupButtons, MandatoryLabel, LinkIcon, Link1 } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import { generateRandomPassword, isEmpty } from '../utils/Utils';
import SelectOptions from '../components/SelectOptions';
import swal from 'sweetalert';
import Popup from 'reactjs-popup';
import { Tabs, Tab, TabList, TabPanel } from 'react-web-tabs';
import PasswordField from './PasswordField.js';
import ls from 'local-storage';
import styled from 'styled-components';
import format from 'date-fns/format';


const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const RightDiv = styled.div`
  margin-left: auto;
`;

const TableLayout = styled(TableContainer)`
  overflow: auto;
  overflow-y: hidden;
`;

const Wrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  text-align: left;
  display: inline-block;
`;

const ContentDiv = styled.div`
  width: 82vw;
  overflow-y: auto;
  padding-bottom: 10px;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const NetworkContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const HostPortContainer = styled.div`
  display: flex;
  margin-right: 3px;
  padding-left: 2px;
`;

const Table = styled.table`
  border-collapse: collapse; 
  table-layout: fixed;
  min-width: 100%;
`;

const FieldSetInnerContainer = styled.div`
  padding-bottom: 15px;
`;

const Rdb = (props) => {
  const [isEditPopup, setEditPopup] = useState(true);
  const [rdbData, setRdbData] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedInstance, setSelectedInstance] = useState({});
  const [loading, setLoading] = useState(false);
  const deviceIP = ls.get('device_ip') === undefined ? window.location.hostname : ls.get('device_ip');
  const deviceID = ls.get('device_id') === undefined ? '' : ls.get('device_id') + '.local';

  const contentStyle = {
    margin: 'auto',
    background: 'rgb(255, 255, 255)',
    width: 'fit-content',
    height: 'auto',
    padding: '5px',
    minWidth: '26%',
    border: '2px solid #d7d7d7'
  };

  const instNameStyle = {
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    maxWidth: '200px'
  };
  
  const nwNameStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    maxWidth: '200px'
  };

  const hostStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    maxWidth: '350px'
  };

  const editInstance = () => {
    setEditPopup(true);
    setModalOpen(true);
  };

  const closePopup = (event) => {
    setModalOpen(false);
  };

  const addNewInstance = (event) => {
    setEditPopup(false);
    setSelectedInstance(undefined);
    setModalOpen(true);
  };

  const radioButtonOnChange = (name) => {
    let data = Object.assign({}, rdbData.stacks[name].services.rdb1);
    data.instance_id = name;
    data.nickname = rdbData.stacks[name].nickname;
    setSelectedInstance(data);
    setEditPopup(true);
  };

  const handleHostChange = (instName, port) => {
    let btnElement = document.getElementById(instName + '_mdns_button');
    let hostElement = document.getElementById(instName + 'host_port');
    if(btnElement.innerText === 'mDNS'){
      hostElement.innerText = deviceIP +':'+ port;
      btnElement.innerText = 'IP';
    } else {
      hostElement.innerText = deviceID +':'+ port;
      btnElement.innerText = 'mDNS';
    }
  };

  const enableToggle = (value, event) => {
    let rdbName = event.target.name;
    var jsonData = {
      'namespace': Constants.NAMSPACES.SDK,
      'type': Constants.SDK_SERVICE_TYPES.RDB_MYSQL,
      'action': value ? Constants.SDK_ACTIONS.ENABLE : Constants.SDK_ACTIONS.DISABLE
    };

    axios.post(Constants.EDGE_API_ENDPOINT + '/sdk/rdb/' + rdbName + '/all', jsonData)
      .then(res => {
        swal('Instance ' + (value ? 'enable' : 'disable') + ' request submitted successfully.', { icon: 'success' });
        setTimeout(function () { window.location.reload(); }, 2000);
      })
      .catch(error => {
        if (error.response) {
          var errorObj = error.response.data;
          swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
        } else {
          swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
        }
      });
  };


  const getRdb = useCallback(() => {
    axios.get(Constants.EDGE_API_ENDPOINT + '/sdk/rdb/all')
      .then(res => {
        let data = res.data.data;
        if(Object.keys(res.data.data.stacks).length === 0) {
          setEditPopup(false);
        }
        let sortedData = [];
        if('stacks' in data){
          Object.keys(data.stacks).map((stackName) => {
            let stackData = data.stacks[stackName];
            stackData.name = stackName;
            sortedData.push(stackData);
            return stackName;
          });
          sortedData.sort(function(a,b){
            const dateFormatB = b.created_time.split(' ');
            const dateFormatA = a.created_time.split(' ');
            let dateA = dateFormatA[0] + ' ' + dateFormatA[1];
            let dateB = dateFormatB[0] + ' ' + dateFormatB[1];
            return new Date(dateB) - new Date(dateA);
          });
        }
        data["sorted_list"] = sortedData;
        if(Object.keys(data.sorted_list).length !== 0) {
          let name = data.sorted_list[0].name;
          let instData = Object.assign({}, data.stacks[name].services.rdb1);
          instData.instance_id = name;
          instData.nickname = data.sorted_list[0].nickname;
          setSelectedInstance(instData);
          setEditPopup(true);
        }

        axios.get(Constants.EDGE_API_ENDPOINT + '/sdk/rdb/status/all')
          .then(res => {
            let rdbStatusData = res.data.data;
            if (rdbStatusData && 'stacks' in rdbStatusData) {
              let rdbStatusList = rdbStatusData.stacks;
              Object.keys(rdbStatusList).map((rdbName) => {
                if (rdbName in data.stacks) {
                  data.stacks[rdbName].status = rdbStatusList[rdbName];
                }
                return rdbName;
              });
              setRdbData(data);
            } else {
              setRdbData(data);
            }
            setLoading(false);
          })
          .catch(err => {
            console.log(err);
            setRdbData(data);
            setLoading(false);
          });
      }).catch(error => {
        console.log(error);
        setRdbData([]);
        setLoading(false);
      });
  },[]);

  const refreshInstances = (event) => {
    setLoading(true);
    document.getElementById('btnRefrehInstance').textContent = 'Refreshing...';
    getRdb();
  };

  function getDisplayLabel(data, key) {
    return (data[key] !== undefined ? data[key] : key);
  }

  function getRDBVersionDisplayName(version) {
    let rdbVersion = '-';
    const rdbVersions = Constants.AD_HW_VPU === 'MMAL' ? Constants.RDB_VERSION_RP4 : Constants.RDB_VERSION;
    rdbVersions.map((obj) => {
      if (obj.value === version) {
        rdbVersion = obj.name;
      }
      return obj;
    });
    return rdbVersion;
  }

  useEffect(() => {
    if (document.getElementById('btnRefrehInstance')) {
      document.getElementById('btnRefrehInstance').textContent = 'Refresh';
    }
  }, [rdbData]);

  useEffect(() => {
    getRdb();
  }, [getRdb]);

  return (
    <ContentDiv>
      { (rdbData === undefined) &&
        <div className="loading-msg">
          <label>Loading...</label>
          <CircularProgress />
        </div>
      }
      {rdbData &&
        <Wrapper style={{ 'padding': '20px' }}>
          <Row>
            <SectionHeader>
              <SectionLabel>Relational Database</SectionLabel>
            </SectionHeader>
          </Row>
          <Row>
            <ActionButtonContainer>
              { isEditPopup &&
                <Button primary id="btnEditInstance" name="btnEditInstance" onClick={() => editInstance()}>Edit</Button>
              }
              {rdbData.stacks && Object.keys(rdbData.stacks).length > 0 &&
                <Button primary id="btnRefrehInstance" name="btnRefrehInstance" disabled={loading} onClick={() => refreshInstances()}>Refresh</Button>
              }
              <Button primary id="btnAddInstance" name="btnAddInstance" onClick={() => addNewInstance()}>Add</Button>
              <Popup modal open={modalOpen} onClose={closePopup} {...{ contentStyle, overlayStyle }}>
                <RdbPopup isEditPopup={isEditPopup} closePopup={closePopup} selected_rdb={selectedInstance} rdbData={rdbData} />
              </Popup>
            </ActionButtonContainer>
          </Row>
          {rdbData.stacks && Object.keys(rdbData.stacks).length > 0 &&
            <TableLayout>
              <Table>
                <tr style={{ 'background-color': '#1f303a', 'color': 'white' }}>
                  <Th></Th>
                  <Th style={{'text-align': 'left'}}>Nickname <br /> (Instance ID)</Th>
                  <Th>Status</Th>
                  <Th>Version</Th>
                  <Th>Created</Th>
                  <Th>Access</Th>
                  <Th>Host:Port<br/>(Docker Network)</Th>
                  <Th>Dashboard</Th>
                  <Th>Enabled</Th>
                </tr>
                { Object.keys(rdbData.sorted_list).map((index) => {
                  let instName = rdbData.sorted_list[index].name;
                  let stackData = rdbData.sorted_list[index].services.rdb1;
                  let rdbName = stackData.instance_id;
                  let nickname =  rdbData.stacks[instName].nickname ?  rdbData.stacks[instName].nickname : '';
                  let formattedDate = rdbData.stacks[instName].created_time.split(' ');
                  let enabled = rdbData.stacks[instName].enabled;
                  let date = formattedDate[0];
                  let time = formattedDate[1];
                  let standared = formattedDate[2];
                  let rdbStatus = 'recovering';
                  if ('status' in rdbData.stacks[instName]) {
                    rdbStatus = rdbData.stacks[instName].status.running_status.status;
                  }
                  let rdbVersion = getRDBVersionDisplayName(stackData.version);
                  let networkAccess = Constants.RDB_NETWORK_ACCESS[stackData.network_access];
                  let host = '-';
                  let port = '-';
                  let adminerServerParam = Constants.RDB_DEFAULT_HOST + ':' + Constants.RDB_DEFAULT_PORT;
                  if(stackData.network_access === 'deny_all'){
                    host = Constants.RDB_DEFAULT_HOST;
                    port = Constants.RDB_DEFAULT_PORT;
                    adminerServerParam = Constants.RDB_DEFAULT_HOST + ':' + port;
                  } else if(stackData.network_access === 'allow_all'){
                    host = deviceID;
                    port = stackData.port;
                    adminerServerParam = deviceID + ':' + port;
                  } else if(stackData.network_access === 'allow_inside_device'){
                    host = rdbName;
                    port = Constants.RDB_DEFAULT_PORT;
                    adminerServerParam = rdbName + ':' + port;
                  }

                  return (
                    <tr key={index}>
                      <Td><input type="radio" id="select_radio" style={{ 'cursor': 'pointer' }} name="select_radio" defaultChecked={index==='0'} value={rdbName} onClick={() => radioButtonOnChange(rdbName)}></input></Td>
                      <Td>
                        <div style={{'display': 'flex', 'flexDirection': 'column'}}>
                            <div style={instNameStyle} title={nickname}>{nickname}</div>
                            <div style={instNameStyle} title={instName}>({instName})</div>
                        </div>
                      </Td>
                      <Td style={{ 'text-align': 'center', 'white-space': 'nowrap' }}>
                        {rdbStatus === '-' ?
                          '-' :
                          <label className={rdbStatus}>
                            {getDisplayLabel(Constants.STATUS_TYPES, rdbStatus)}
                          </label>}
                      </Td>
                      <Td>{rdbVersion}</Td>
                      <Td>{date}<br />{time} {standared}</Td>
                      <Td>{networkAccess}</Td>
                      <Td>
                        <Container>
                          <HostPortContainer id={instName + 'host_port'}><div id={'host_port'} style={hostStyle}>{host}:{port}</div></HostPortContainer>
                          { stackData.network_access === 'allow_all' &&
                          <MdnsIpButton primary id={instName + '_mdns_button'} className={'small-button'} onClick={() => handleHostChange(instName, port)}>mDNS</MdnsIpButton>
                          }
                          </Container>
                        <NetworkContainer>(<div style={nwNameStyle}>{instName + Constants.NETWORK_CONTAINER_SUFFIX}</div>)</NetworkContainer>
                      </Td>
                      <Td>
                        { stackData.enable_adminer ?
                        <> {enabled ? 
                          <div>
                          <LinkIcon />
                          <Link1 target="_blank" href={window.location.protocol + '//' + window.location.host + '/adminer-' + rdbData.stacks[instName].services.adminer1.port + '/adminer.php?server=' + adminerServerParam + '&username=' + rdbData.stacks[instName].services.rdb1.root_username}>Open Dashboard</Link1>
                          </div>: '-'}
                          </>
                          :
                          <span style={{'color': 'gray'}}> Not Available</span>
                        }
                      </Td>
                      <Td><SelectionControl type="switch" id={rdbName} name={rdbName} value={rdbData.stacks[instName].enabled} checked={rdbData.stacks[instName].enabled} labelBefore="true" onChange={enableToggle} style={{ 'display': 'block', 'paddingTop': '10px' }} /></Td>
                    </tr>
                  ); 
                })}
              </Table>
            </TableLayout>
          }
          {rdbData.stacks && Object.keys(rdbData.stacks).length === 0 &&
            <CenterDiv>
              <Label>No instance found.</Label>
            </CenterDiv>
          }
        </Wrapper>
      }
    </ContentDiv>
  );
};

function getDefaultRDBData() {
  let defaultRDBData = Constants.RDB_DEFAULT_VALUE;
  defaultRDBData.root_password = generateRandomPassword(12);
  return defaultRDBData;
}

const RdbPopup = (props) => {
  const { isEditPopup, closePopup, selected_rdb, rdbData } = props;
  const [rdbSettings, setRdbSettings] = useState(selected_rdb === undefined ? getDefaultRDBData() : selected_rdb);

  useEffect(()=>{
    if(rdbSettings.instance_id === ''){
      if(rdbData.sorted_list.length !== undefined){
        setRdbSettings({...rdbSettings, instance_id:'testdb'+(rdbData.sorted_list.length+1)});
      } else {
        setRdbSettings({...rdbSettings, instance_id:'testdb1'});
      }
    }
  },[]);

  const handleCheckBoxChange = (event) => {
    setRdbSettings({ ...rdbSettings, [event.target.name]: event.target.checked });
  };

  const handleRadioChange = (event) => {
    event.persist();
    let data = { ...rdbSettings };
    data[event.target.name] = event.target.value;
    setRdbSettings(data);
  };

  const handleOnChange = (event) => {
    if (event.target.name === 'instance_id') {
      if (event.target.value.charAt(0) === '_') {
        return event.target.value.replace(/[^\w]/g, '');
      } else {
        event.target.value = event.target.value.replace(/[^\w]/g, '');
      }
    } else if (event.target.name === 'log_retention') {
      event.target.value = event.target.value.replace(/[^0-9.]/g, '');
    } else if ((event.target.name === 'root_username' || event.target.name === 'root_password' ) && event.target.value.includes(' ')) {     
      event.target.value = event.target.value.replace(/\s/g, '');    
    } else if ((event.target.name === 'root_username' || event.target.name === 'root_password' ) && (event.target.value.includes('$'))) {     
      event.target.value = event.target.value.replace('$', ''); 
    }  else if ( event.target.name === 'root_password') {     
      event.target.value = event.target.value.replace(/[{}()~*?"'\\^$|#\s]/g, '');
    } else if ( event.target.name === 'metadata_locks_cache_size') {     
      event.target.value = event.target.value.replace(/^0/, "")
      event.target.value = event.target.value.replace(/[^\w]/g, '');
      event.target.value = event.target.value.replace(/[^\d.-]/g, '');
    } else if ( (event.target.name === 'query_cache_limit') || (event.target.name === 'query_cache_size') || (event.target.name === 'query_cache_min_res_unit') || (event.target.name === 'query_cache_type') || (event.target.name === 'query_cache_wlock_invalidate') || (event.target.name === 'host_cache_size') ||  (event.target.name === 'metadata_locks_cache_size') ) {     
      event.target.value = event.target.value.replace(/[^\w]/g, '');
      event.target.value = event.target.value.replace(/[^\d.-]/g, '');
    } else if(event.target.name === 'nickname') {
      if (event.target.value.charAt(0) === ' ') {
        return event.target.value.replace(/[^\w]/g, '');
      }
    }
    setRdbSettings({ ...rdbSettings, [event.target.name]: event.target.value });
  };

  const handleSelectChange = (event) => {
    event.persist();
    setRdbSettings({ ...rdbSettings, [event.target.name]: event.target.value });
  };

  const saveInstance = (event) => {
    let jsonData = Object.assign({}, rdbSettings);
    let rdbName = jsonData.instance_id;
    let action = isEditPopup ? 'updated' : 'added';

    if (isEmpty(rdbName)) {
      swal('Please enter instance id.', { icon: 'error' });
      return;
    }
    if (isEmpty(jsonData.root_username)) {
      swal('Please enter root user.', { icon: 'error' });
      return;
    }
    if (isEmpty(jsonData.root_password)) {
      swal('Please enter root password.', { icon: 'error' });
      return;
    }
    if (isEmpty(jsonData.query_cache_limit)) {
      swal('Please enter cache limit.', { icon: 'error' });
      return;
    }
    if (isEmpty(jsonData.query_cache_size)) {
      swal('Please enter cache size.', { icon: 'error' });
      return;
    }
    if (isEmpty(jsonData.query_cache_min_res_unit)) {
      swal('Please enter cache min res unit.', { icon: 'error' });
      return;
    }
    if (isEmpty(jsonData.query_cache_type)) {
      swal('Please enter cache type.', { icon: 'error' });
      return;
    }
    if (isEmpty(jsonData.query_cache_wlock_invalidate)) {
      swal('Please enter cache wlock invalidate.', { icon: 'error' });
      return;
    }
    if (isEmpty(jsonData.host_cache_size)) {
      swal('Please enter host cache size.', { icon: 'error' });
      return;
    }
    if (isEmpty(jsonData.metadata_locks_cache_size)) {
      swal('Please enter metadata locks cache size.', { icon: 'error' });
      return;
    }
    if (isEmpty(jsonData.log_retention)) {
      swal('Please enter log retention day(s).', { icon: 'error' });
      return;
    }
    if (jsonData.query_cache_limit > 4294967295) {
      swal('Please enter cache limit less than or equal to 4294967295', { icon: 'error' });
      return;
    }
    if (jsonData.query_cache_size % 1024 !== 0) {
      swal('Please enter cache size in multiple of 1024', { icon: 'error' });
      return;
    }
    if (jsonData.query_cache_min_res_unit > 4294967295) {
      swal('Please enter cache min res unit less than or equal to 4294967295', { icon: 'error' });
      return;
    }
    if (jsonData.query_cache_type > 2) {
      swal('Please enter cache type less than or equal to 2', { icon: 'error' });
      return;
    }
    if (jsonData.query_cache_wlock_invalidate > 1) {
      swal('Please enter cache wlock invalidate less than or equal to 1', { icon: 'error' });
      return;
    }
    if (jsonData.host_cache_size > 65536) {
      swal('Please enter host cache Size less than or equal to 65536', { icon: 'error' });
      return;
    }
    if (jsonData.metadata_locks_cache_size > 1048576) {
      swal('Please enter metadata locks cache size less than or equal to 1048576', { icon: 'error' });
      return;
    }

    let finalJson = {};
    finalJson.nickname = jsonData.nickname;
    finalJson.enabled = rdbData.stacks[rdbName] === undefined ? true : rdbData.stacks[rdbName].enabled;
    finalJson.namespace = Constants.NAMSPACES.SDK;
    finalJson.type = Constants.SDK_SERVICE_TYPES.RDB_MYSQL;
    finalJson.services = {};
    delete jsonData.nickname;
    finalJson.services.rdb1 = Object.assign({}, jsonData);

    if (action === 'added') {
      axios.put(Constants.EDGE_API_ENDPOINT + '/sdk/rdb/' + rdbName + '/all', finalJson)
        .then(res => {
          swal('Instance ' + action + ' successfully.', { icon: 'success' });
          setTimeout(function () { window.location.reload(); }, 2000);
        })
        .catch(error => {
          if (error.response) {
            var errorObj = error.response.data;
            swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
          } else {
            swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
          }
        });
    } else {
      axios.patch(Constants.EDGE_API_ENDPOINT + '/sdk/rdb/' + rdbName + '/all', finalJson)
        .then(res => {
          swal('Instance ' + action + ' successfully.', { icon: 'success' });
          setTimeout(function () { window.location.reload(); }, 2000);
        })
        .catch(error => {
          if (error.response) {
            var errorObj = error.response.data;
            swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
          } else {
            swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
          }
        });
    }
  };

  const deleteRdb = (event) => {
    let rdbName = rdbSettings.instance_id;
    swal({
      text: 'Enter instance id to delete',
      content: 'input',
      buttons: ['No', 'Yes'],
      dangerMode: true,
      icon: 'warning'
    })
      .then((name) => {
        // user has cancle the delete operation by clicking No
        if (name === null) {
          return;
        }
        if (name === rdbName) {
          axios.delete(Constants.EDGE_API_ENDPOINT + '/sdk/rdb/' + rdbName + '/all')
            .then(res => {
              swal('Instance deleted successfully.', { icon: 'success' });
              setTimeout(function () { window.location.reload(); }, 2000);
            })
            .catch(error => {
              console.log('in error');
              if (error.response) {
                var errorObj = error.response.data;
                swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
              } else {
                swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
              }
            });
        } else {
          return swal({ text: 'Instance id not match.', icon: 'error' });
        }
      });
  };

  return (
    <div>
      {rdbSettings !== undefined &&
        <PopupContainer>
          <SectionHeader style={{ 'display': 'flex' }}>
            <SectionLabel>{isEditPopup === true ? 'Edit' : 'Add'} Instance</SectionLabel>
            {isEditPopup &&
              <div style={{ 'marginLeft': 'auto' }}>
                <Button danger id="btnDeleteInstance" name="btnDeleteInstance" style={{ 'margin': '0px' }} onClick={deleteRdb} >Delete</Button>
              </div>
            }
          </SectionHeader>
          <Tabs defaultTab="general" style={{ 'padding': '6px' }}>
            <TabList>
              <Tab tabFor="general" className={'rwt__tab'}>General</Tab>
              <Tab tabFor="advanced" className={'rwt__tab'}>Advanced</Tab>
            </TabList>
            <TabPanel tabId="general">
              <div style={{ 'height': '580px', 'padding': '0px 20px 20px 20px'}}>
                <Row>
                  <MandatoryLabel>Instance ID</MandatoryLabel>
                  <TextFieldSmall id="instance_id" autoComplete="off" name="instance_id" value={rdbSettings.instance_id} onChange={handleOnChange} disabled={isEditPopup} maxLength="63" />
                </Row>
                <Row>
                  <TextFieldLabel>Nickname</TextFieldLabel>
                  <TextFieldSmall id="nickname" autoComplete="off" name="nickname" value={rdbSettings.nickname} onChange={handleOnChange} maxLength="100" />
                </Row>
                <Row>
                  <TextFieldLabel>Version</TextFieldLabel>
                  <SelectField id="version" name="version" style={{ 'margin-top': '10px', 'width': '205px' }} value={rdbSettings.version} onChange={handleSelectChange} disabled={isEditPopup}>
                    <SelectOptions items={Constants.AD_HW_VPU === 'MMAL' ? Constants.RDB_VERSION_RP4 : Constants.RDB_VERSION} />
                  </SelectField>
                </Row>
                <Row>
                  <MandatoryLabel>Root User</MandatoryLabel>
                  <TextFieldSmall id="root_username" autoComplete="off" name="root_username" value={rdbSettings.root_username} onChange={handleOnChange} maxLength="100" />
                </Row>
                <Row>
                  <MandatoryLabel>Root Password</MandatoryLabel>
                  <PasswordField id="root_password" type="text" width="205px" name="root_password" value={rdbSettings.root_password} setInput={handleOnChange} maxLength="100" />
                </Row>
                <Row>
                  <fieldset style={{ 'margin-top': '20px', 'margin-left': '-1px' }}>
                    <legend>Network Access</legend>
                    <Row style={{ 'marginTop': '15px' }}>
                      <div style={{ 'display': 'flex', 'paddingBottom': '15px' }}>
                        <div>
                          <input type="radio" id="network_access" name="network_access" value="deny_all" checked={rdbSettings.network_access === 'deny_all' || false} onChange={handleRadioChange} /> {Constants.RDB_NETWORK_ACCESS.deny_all}
                        </div>
                        <div style={{ 'marginLeft': '15px' }}>
                          <input type="radio" id="network_access" name="network_access" value="allow_inside_device" checked={rdbSettings.network_access === 'allow_inside_device' || false} onChange={handleRadioChange} /> {Constants.RDB_NETWORK_ACCESS.allow_inside_device}
                        </div>
                        <div style={{ 'marginLeft': '15px' }}>
                          <input type="radio" id="network_access" name="network_access" value="allow_all" checked={rdbSettings.network_access === 'allow_all' || false} onChange={handleRadioChange} /> {Constants.RDB_NETWORK_ACCESS.allow_all}
                        </div>
                      </div>
                    </Row>
                  </fieldset>
                </Row>
                <Row style={{ 'marginTop': '20px'}}>
                  <input type="checkbox" id="enable_adminer" name="enable_adminer" style={{ 'marginTop': '0', 'marginLeft': '0px' }} value={rdbSettings.enable_adminer} checked={rdbSettings.enable_adminer} onChange={handleCheckBoxChange} /> Enable Adminer
                </Row>
              </div>
            </TabPanel>
            <TabPanel tabId="advanced">
              <div style={{ 'height': '580px', 'padding': '5px 20px 20px 20px'}}>
                <FieldSet>
                    <legend>RDB Query</legend>
                    <FieldSetInnerContainer>
                      <Row>
                        <MandatoryLabel style={{'width': '230px'}}>Cache Limit</MandatoryLabel>
                        <TextFieldSmall id="query_cache_limit" autoComplete="off" maxLength='10' name="query_cache_limit" value={rdbSettings.query_cache_limit} onChange={handleOnChange} style={{width: '140px'}} />
                      </Row>
                      <Row>
                        <MandatoryLabel style={{'width': '230px'}}>Cache Size</MandatoryLabel>
                        <TextFieldSmall id="query_cache_size" autoComplete="off" name="query_cache_size" value={rdbSettings.query_cache_size} onChange={handleOnChange} style={{width: '140px'}} />
                      </Row>
                      <Row>
                        <MandatoryLabel style={{'width': '230px'}}>Cache Min Res Unit</MandatoryLabel>
                        <TextFieldSmall id="query_cache_min_res_unit" autoComplete="off" maxLength='10' name="query_cache_min_res_unit" value={rdbSettings.query_cache_min_res_unit} onChange={handleOnChange} style={{width: '140px'}} />
                      </Row>
                      <Row>
                        <MandatoryLabel style={{'width': '230px'}}>Cache Type</MandatoryLabel>
                        <TextFieldSmall id="query_cache_type" autoComplete="off" maxLength='1' name="query_cache_type" value={rdbSettings.query_cache_type} onChange={handleOnChange} style={{width: '140px'}} />
                      </Row>
                      <Row>
                        <MandatoryLabel style={{'width': '230px'}}>Cache Wlock Invalidate</MandatoryLabel>
                        <TextFieldSmall id="query_cache_wlock_invalidate" autoComplete="off" maxLength='1' name="query_cache_wlock_invalidate" value={rdbSettings.query_cache_wlock_invalidate} onChange={handleOnChange} style={{width: '140px'}} />
                      </Row>
                      <Row>
                        <MandatoryLabel style={{'width': '230px'}}>Host Cache Size</MandatoryLabel>
                        <TextFieldSmall id="host_cache_size" autoComplete="off" maxLength='5' name="host_cache_size" value={rdbSettings.host_cache_size} onChange={handleOnChange} style={{width: '140px'}} />
                      </Row>
                      <Row>
                        <MandatoryLabel style={{'width': '230px'}}>Metadata Locks Cache Size</MandatoryLabel>
                        <TextFieldSmall id="metadata_locks_cache_size" autoComplete="off" maxLength='7' name="metadata_locks_cache_size" value={rdbSettings.metadata_locks_cache_size} onChange={handleOnChange} style={{width: '140px'}} />
                      </Row>
                    </FieldSetInnerContainer>
                </FieldSet>
                <Row>
                  <MandatoryLabel style={{'marginTop': '20px'}}>Log Retention (in Days)</MandatoryLabel>
                  <TextFieldSmall id="log_retention" autoComplete="off" style={{'width': '220px'}} maxLength='4' name="log_retention" value={rdbSettings.log_retention} onChange={handleOnChange} />
                </Row>
                <Row>
                  <TextFieldLabel style={{ 'color': 'grey', 'display': 'flex-wrap', 'width': '400px', 'marginTop': '20px'}}>Logs will expire and deleted after this amount of time to avoid the local storage full.</TextFieldLabel>
                </Row>
              </div>
            </TabPanel>
          </Tabs>
          <Row>
            <PopupButtons>
              <Button onClick={saveInstance} primary >Save</Button>
              <Button id="btnCancelInstance" danger onClick={() => { closePopup(); }}>Cancel</Button>
            </PopupButtons>
          </Row>
        </PopupContainer>
      }
    </div>
  );
};

export default Rdb;