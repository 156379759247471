import React, { useEffect, useState, useCallback } from 'react';
import { ContentDiv, Wrapper, SectionHeader, SectionLabel } from '../utils/Styles';
import { Tabs, Tab, TabList, TabPanel } from 'react-web-tabs';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import RecentActivitiesSettings from './RecentActivitiesSettings';

const UpperWrapper = styled(Wrapper)`
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const MonitoringSettings = () => {
  const [selectedTab, setSelectedTab] = useState('recent-activity');
  const params = useLocation().search;
  const [historyParams] = useState(params);
  const history = useHistory();

  function onTabChange(tabId){
    setSelectedTab(tabId);
  }

  const setParams = useCallback(() => {
    const params = '?' +
          `${selectedTab === 'recent-activity' ? 'recent-activity' : '&selectedTab=' + selectedTab}`;
    if (params === '?') {
      return '';
    }
    return params;
  }, [selectedTab]);
    
  const fetchHistoryParams = useCallback(() => {
    const string = new URLSearchParams(historyParams);
    let newParams = {};
    newParams.selectedTab = string.get('selectedTab');
    
    if (newParams.selectedTab) {
      setSelectedTab(newParams.selectedTab);
    }
  }, [historyParams]);
    
  useEffect(()=>{
    fetchHistoryParams();
  },[fetchHistoryParams]);
    
  useEffect(() => {
    history.push('/monitoring-settings' + setParams());
  }, [history, setParams]);

  return(
    <ContentDiv>
      <UpperWrapper>
        <SectionHeader>
          <SectionLabel>Monitoring</SectionLabel>
        </SectionHeader>
        <Tabs defaultTab={selectedTab} onChange={onTabChange}>
          <TabList>
            <Tab tabFor="recent-activity" className={'rwt__tab'}>Recent Activity</Tab>
          </TabList>
          <TabPanel tabId="recent-activity">
            <RecentActivitiesSettings />
          </TabPanel>
        </Tabs>
      </UpperWrapper>
    </ContentDiv>
  );
};
export default MonitoringSettings;