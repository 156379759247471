import React, { Component } from "react";
import PropTypes from "prop-types";
import { ListItem, MenuButton } from 'react-md';
import swal from "sweetalert";
import axios from 'axios';
import { RotateLabel, Label } from '../utils/Styles';
import * as Constants from '../utils/Constants';


class AccordionSection extends Component {
    static propTypes = {
        children: PropTypes.instanceOf(Object).isRequired,
        isOpen: PropTypes.bool.isRequired,
        label: PropTypes.string.isRequired,
        statusType: PropTypes.string.isRequired,
        stackType: PropTypes.string.isRequired,
        disableMenu: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired
    };

    onClick = () => {
        this.props.onClick(this.props.label);
    };

    deleteStack = (stackType, stackSubType, stackName) => {
        var stackLabel = (stackType === "streams" ? "Stream" : "Project")
        var apiUrl = (stackType === "streams" ? Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName + "/all" : Constants.EDGE_API_ENDPOINT + '/projects/' + stackName + "/all")      
        if(stackType === "streams"){
            stackLabel = "stream";
            apiUrl = Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName + "/all"
        } else if(stackType === "sdk"){
            if(stackSubType === 'project'){
                stackLabel = "project";
                apiUrl = Constants.EDGE_API_ENDPOINT + '/sdk/project/' + stackName + '/all';
            } else if(stackSubType === 'bi-metabase'){
                stackLabel = "metabase";
                apiUrl = Constants.EDGE_API_ENDPOINT + '/sdk/metabase/' + stackName + '/all';
            } else if(stackSubType === 'rdb-mysql'){
                stackLabel = "relational database";
                apiUrl = Constants.EDGE_API_ENDPOINT + '/sdk/rdb/' + stackName + '/all';
            }  else if(stackSubType === 'email-client'){
                stackLabel = "email client";
                apiUrl = Constants.EDGE_API_ENDPOINT + '/sdk/email_clients/' + stackName + '/all';
            } else {
                return;
            }
        }

        swal({text: "Are you sure you want to delete this " + stackLabel + "?",
              buttons: ['No', 'Yes'],
              dangerMode: true
        })
        .then((isConfirm) => {
            if (isConfirm) {
                axios.delete(apiUrl)
                    .then(res => {
                        swal(stackLabel[0].toUpperCase() + stackLabel.substring(1) +" delete request submitted successfully.", { icon: "success"});
                        setTimeout(function(){ window.location.reload() }, 2000);
                    })
                    .catch(error => {
                        if(error.response){
                            var errorObj = error.response.data;
                            swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
                        }else{
                            swal({text: "Unable to connect to the edge-api service" , icon: "error"});
                        }
                    });
            }
        });
    };

    enableDisableStack = (stackType, stackSubType, stackName, actionLabel) => {
        let action = '';
        let stackLabel = '';
        let jsonData = {};
        let apiUrl = '';
        if(stackType === "streams"){
            action = (actionLabel === "Enable" ? "start" : "stop")
            stackLabel = "Stream";
            jsonData = {
                "namespace": "default",
                "action": action,
                "source": "Edge UI"
            }
            apiUrl = Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName;
        } else if(stackType === "sdk"){
            if(stackSubType === 'project'){
                action = (actionLabel === "Enable" ? "start" : "stop")
                stackLabel = "Project";
                jsonData = {
                    "namespace": "sdk",
                    "type": "project",
                    "action": (actionLabel === "Enable" ? Constants.SDK_ACTIONS.ENABLE : Constants.SDK_ACTIONS.DISABLE),
                    "source": "Edge UI"
                }
                apiUrl = Constants.EDGE_API_ENDPOINT + '/sdk/project/' + stackName + '/all';
            } else if(stackSubType === 'bi-metabase'){
                action = (actionLabel === "Enable" ? "start" : "stop")
                stackLabel = "Metabase";
                jsonData = {
                    "namespace": "sdk",
                    "type": "bi-metabase",
                    "action": (actionLabel === "Enable" ? Constants.SDK_ACTIONS.ENABLE : Constants.SDK_ACTIONS.DISABLE),
                    "source": "Edge UI"
                }
                apiUrl = Constants.EDGE_API_ENDPOINT + '/sdk/metabase/' + stackName + '/all';
            } else if(stackSubType === 'rdb-mysql'){
                action = (actionLabel === "Enable" ? "start" : "stop")
                stackLabel = "Relational Database";
                jsonData = {
                    "namespace": "sdk",
                    "type": "rdb-mysql",
                    "action": (actionLabel === "Enable" ? Constants.SDK_ACTIONS.ENABLE : Constants.SDK_ACTIONS.DISABLE),
                    "source": "Edge UI"
                }
                apiUrl = Constants.EDGE_API_ENDPOINT + '/sdk/rdb/' + stackName + '/all';
            } else if(stackSubType === 'email-client'){
                action = (actionLabel === "Enable" ? "start" : "stop")
                stackLabel = "Email Client";
                jsonData = {
                    "namespace": "sdk",
                    "type": "email-client",
                    "action": (actionLabel === "Enable" ? Constants.SDK_ACTIONS.ENABLE : Constants.SDK_ACTIONS.DISABLE),
                    "source": "Edge UI"
                }
                apiUrl = Constants.EDGE_API_ENDPOINT + '/sdk/email_clients/' + stackName + '/all';
            } else {
                return;
            }
        }
        
        swal({text: "Are you sure you want to " + actionLabel.toLowerCase() + " this " + stackLabel.toLowerCase() + "?",
             buttons: ['No', 'Yes'],
             dangerMode: true,
            }).then(function(isConfirm) {
                if (isConfirm) {
                    axios.post(apiUrl, jsonData)
                        .then(res => {
                            swal(stackLabel + " " + actionLabel.toLowerCase() + " request submitted successfully.", { icon: "success"});
                            setTimeout(function(){ window.location.reload() }, 2000);
                        })
                        .catch(error => {
                            if(error.response){
                                var errorObj = error.response.data;
                                swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
                            }else{
                                swal({text: "Unable to connect to the edge-api service" , icon: "error"});
                            }
                        });
                }
        });
    };

    render() {
        const { onClick, deleteStack, enableDisableStack, props: { isOpen, label, stackName, statusType, stackType, stackSubType, disableMenu, deleteButtonDisable } } = this;
        const enabledMenuLabel = statusType === "disabled" ? "Enable" : "Disable";

        return (
            <div style={{"background": "#fff", "border": "1px solid #d3d3d38a", "borderBottom": "1px solid #d3d3d38a", "padding":isOpen? "0px" : "5px 5px", "color":"#000", "marginBottom":"5px"}}>
            <div style={{cursor: "pointer", background: isOpen ? "#d3d3d38a" : "#fff", padding: "5px"}}>
            {isOpen && <span onClick={onClick} style={{"display": "inline-block", "marginRight": "15px", "color":"#4bb5f4"}}>&#9660;</span>}
            {!isOpen && <span onClick={onClick} style={{"display": "inline-block", "marginRight": "15px", "color":"#4bb5f4"}}>&#9654;</span>}
            <div onClick={onClick} style={{"width":"70%", "display":"inline-block"}}>
                <label style={{"fontWeight":"bold", "cursor":"pointer", "marginRight": "-15px"}}>{label} </label>
            </div>
            <div style={{"width":"30%", "display":"contents"}}>
            <div style={{float: "right", "marginTop":"-6px"}}>
            { (this.props.showMenu === true) &&
                <MenuButton style={{"padding":"5px 8px 8px 8px"}}
                    id="menu-button"
                    icon
                    menuItems={[
                        <ListItem key={1} primaryText={enabledMenuLabel} onClick={() => enableDisableStack(stackType, stackSubType, stackName, enabledMenuLabel)} disabled={disableMenu}/>,
                        <ListItem key={2} primaryText="Delete" onClick={() => deleteStack(stackType, stackSubType, stackName)} disabled={deleteButtonDisable} />,
                    ]}
                    centered
                    anchor={{
                        x: MenuButton.VerticalAnchors.CENTER,
                        y: MenuButton.VerticalAnchors.CENTER,
                    }}
                    >
                    <RotateLabel>...</RotateLabel>
                </MenuButton>
            }
            </div>
            <Label onClick={onClick} style={{cursor: "pointer", float: "right"}} className={statusType}>{Constants.STATUS_TYPES[statusType] !== undefined ? Constants.STATUS_TYPES[statusType] : statusType}</Label>
            </div>
            </div>

            {isOpen && (
            <div style={{"background": "#fff", "marginTop": 0, "padding": "10px 20px", "color": "#000"}}>
                {this.props.children}
            </div>
            )}
        </div>
        );
    }
}

export default AccordionSection;
