import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Row, Button, SectionHeader, ContentDiv, SectionLabel, Wrapper, Column, CopyButton, TextArea, CenterDiv, TextField, Label, TableContainer, MessageLabel, SelectField, MandatoryLabel, Td, Th, overlayStyle, PopupContainer, TextFieldLabel, TextFieldSmall, PopupButtons } from '../utils/Styles';
import swal from 'sweetalert';
import styled from 'styled-components';
import axios from 'axios';
import { SelectionControl } from 'react-md';
import { Tabs, Tab, TabList, TabPanel } from 'react-web-tabs';
import * as Constants from '../utils/Constants';
import { useHistory, useLocation } from 'react-router-dom';
import Loader from './Loader';
import SelectOptions from '../components/SelectOptions';

import MapGL, { Marker, NavigationControl } from 'react-map-gl';
import ControlPanel from './ControlPanel';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const navStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  padding: '10px'
};

//eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default


 //Access key: pk.eyJ1IjoiZnRpLXNtYXNrZSIsImEiOiJja3FxYzRvN3kwcGthMndwYjNncXVid29tIn0.oTWwf89LJJvbqJHCLKI0cQ

const LOCATION_DEFAULT = {
  'longitude': '139.83947',
  'latitude': '35.65283',
  'enable_cloud_upload': true
}

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  margin-top: 10px;
`;

const Location = (props) => {
    const [locationInputs, setLocationInputs] = useState();
    const [isConfigure, setConfigured] = useState(false);
    const [readOnly, setReadonly] = useState(true);
    const [accessReadOnly, setAccessReadOnly] = useState(true);
    const [locationLoading, setLocationLoading] = useState(true);
    const [accessTokenLoading, setAccessTokenLoading] = useState(true);
    const [accessTokenInput, setAccessTokenInput] = useState();
    const [showMap, setShowMap] = useState(true);
    const [mapToken, setMapToken] = useState('');
    const [mapService, setMapService] = useState('');
    const params = useLocation().search;
    const [historyParams] = useState(params);
    const history = useHistory();
    const [showDeleteButton, setShowDeleteButton] = useState(true);
    const [selectedTab, setSelectedTab] = useState('general');
    const [selectMode, setSelectMode] = useState('mapbox://styles/mapbox/streets-v11')
    const [viewport, setViewport] = useState({
        latitude: 35.65283,
        longitude: 139.83947,
        zoom: 8.5,
        bearing: 0,
        pitch: 0
      });
    const [marker, setMarker] = useState({
        latitude: 35.65283,
        longitude: 139.83947
    });
    const [events, logEvents] = useState({ 
        latitude: 35.65283,
        longitude: 139.83947
    });
   

    function round5(value) {
        return (Math.round(value * 1e5) / 1e5).toFixed(5);
    }
    
    const setParams = useCallback(() => {
        const params = '?' + `${selectedTab === 'general' ? 'general' : '&selectedTab=' + selectedTab}`
        if (params === '?') {
            return '';
        }
        return params;
    }, [selectedTab]);
    
    const fetchHistoryParams = useCallback(() => {
        const string = new URLSearchParams(historyParams);
        let newParams = {};
        newParams.selectedTab = string.get('selectedTab');

        if (newParams.selectedTab) {
            setSelectedTab(newParams.selectedTab);
        }
    }, [historyParams]);
    
    useEffect(()=>{
        fetchHistoryParams();
    },[fetchHistoryParams]);

    useEffect(() => {
        history.push('/location' + setParams());
    }, [history, setParams]);

    const getLocation = () => {
        try {
            navigator.geolocation.getCurrentPosition(
                function(position) {
                    let data = { ...locationInputs };
                    data.latitude = position.coords.latitude;
                    data.longitude = position.coords.longitude;
                    data.browser_location = true; 
                    setLocationInputs(data);
                    setMarker({
                        longitude: parseFloat(data.longitude),
                        latitude: parseFloat(data.latitude)
                    });
                    setViewport({
                        latitude:  parseFloat(data.latitude),
                        longitude:  parseFloat(data.longitude),
                        zoom: 8.5,
                        bearing: 0,
                        pitch: 0
                    });
                    logEvents({
                        longitude: parseFloat(data.longitude),
                        latitude: parseFloat(data.latitude)
                    });
                },
                function(error) {
                    let data = { ...locationInputs };
                    data.longitude = '139.83947';
                    data.latitude = '35.65283';
                    data.browser_location = true; 
                    setLocationInputs(data);
                    setMarker({
                        longitude: parseFloat(data.longitude),
                        latitude: parseFloat(data.latitude)
                    });
                    setViewport({
                        latitude:  parseFloat(data.latitude),
                        longitude:  parseFloat(data.longitude),
                        zoom: 8.5,
                        bearing: 0,
                        pitch: 0
                    });
                    logEvents({
                        longitude: parseFloat(data.lon),
                        latitude: parseFloat(data.lat)
                    });
                }
            );
        } catch (err) {
            let data = { ...locationInputs };
            data.longitude = '139.83947';
            data.latitude = '35.65283';
            data.browser_location = true; 
            setLocationInputs(data);
            setMarker({
                longitude: parseFloat(data.longitude),
                latitude: parseFloat(data.latitude)
            });
            setViewport({
                latitude:  parseFloat(data.latitude),
                longitude:  parseFloat(data.longitude),
                zoom: 8.5,
                bearing: 0,
                pitch: 0
            });
            logEvents({
                longitude: parseFloat(data.lon),
                latitude: parseFloat(data.lat)
            });
        } 
    }
  
    const configuredHandle = () =>{
        setConfigured(false);
        setShowDeleteButton(false);
        setReadonly(false);
        setLocationInputs(LOCATION_DEFAULT);
        setViewport({
            latitude:  35.65283,
            longitude: 139.83947,
            zoom: 8.5,
            bearing: 0,
            pitch: 0
        })
        logEvents({
            longitude: 139.83947,
            latitude: 35.65283
        });
        setMarker({
            latitude: 35.65283,
            longitude: 139.83947
        })
        if(mapService === undefined || mapService === '' || mapService === ' ') {
            setMapService('default');
        } else if(mapToken !== ''){
            setMapToken(mapToken)
        } else {
            setMapToken('')
        }
    }

    function validateText(str) {
        var tarea = str;
        if (tarea.indexOf("http://") == 0) {
            return true;
        } else if (tarea.indexOf("https://") == 0) {
            return false;
        }
    }

    const handleBrowserToggle = (value, event) => {
        if(!readOnly){
        const Url = window.location.href;
        const isHttp = validateText(Url);
        if(isHttp) {
            if(event.target.id === 'browser_location' && value){
                swal({ text: "Browser's Location can be used only when site is accessed through HTTPS", icon: 'error' });
                let data = {...locationInputs, [event.target.id]: false};
                setLocationInputs(data);
            }
        } else {
            if(event.target.id === 'browser_location' && value){
                getLocation();
            } 
            let data = {...locationInputs, [event.target.id]: value};
            setLocationInputs(data);
        }
        }
    };

    const handleToggle = (value, event) => {
        if(!readOnly){
            let data = {...locationInputs, [event.target.id]: value};
            setLocationInputs(data);
        }
    };

    const handleOnChange = (event) => {
        let data = { ...locationInputs };
        if(event.target.name === 'latitude') {
            event.target.value = event.target.value.replace(/[^.-\d]/g, '');
            if((event.target.value > 90  ||  event.target.value <= -90)) {
                return '';
            } else if(event.target.value.split('.').length>2){
                event.target.value = event.target.value.replace(/\.+$/,"");
            } else if(event.target.value.split('-').length>2){
                event.target.value = event.target.value.replace(/\.+$/,"");
            } else if(event.target.value.charAt(event.target.value.length) && event.target.value.length > 1 && event.target.value.includes('-')){
                event.target.value = event.target.value.replace('-', '');
            } else {
                data[event.target.name] = event.target.value;
                setLocationInputs(data); 
            }
        } else if(event.target.name === 'longitude') {
            event.target.value = event.target.value.replace(/[^.-\d]/g, '');
            if((event.target.value > 180  ||  event.target.value < -180)) {
                return '';
            } else if(event.target.value.split('.').length>2){
                event.target.value = event.target.value.replace(/\.+$/,"");
            } else if(event.target.value.split('-').length>2){
                event.target.value = event.target.value.replace(/\.+$/,"");
            } else if(event.target.value.charAt(event.target.value.length) && event.target.value.length > 1 && event.target.value.includes('-')){
                event.target.value = event.target.value.replace('-', '');
            } else {
                data[event.target.name] = event.target.value;
                setLocationInputs(data);
            }
        } else {
            data[event.target.name] = event.target.value;
            setLocationInputs(data); 
        }
    };

    const onBlurInputs = (event) => {
        let data = {...locationInputs};
        if (event.target.id === 'latitude' && (event.target.value.length === 1 && event.target.value === '-')) {
            document.getElementById(event.target.id).style.borderColor = 'lightgray';
            return;
        } else if (event.target.id === 'longitude' && (event.target.value.length === 1 && event.target.value === '-')) {
            document.getElementById(event.target.id).style.borderColor = 'lightgray';
            return;
        } else if (event.target.id === 'longitude' && event.target.value === '') {
            document.getElementById(event.target.id).style.borderColor = 'lightgray';
            data[event.target.id] = 0;
            setLocationInputs(data);
            return;
        } else if (event.target.id === 'latitude' && event.target.value === '') {
            document.getElementById(event.target.id).style.borderColor = 'lightgray';
            data[event.target.id] = 0;
            setLocationInputs(data);
            return;
        } else {
            data[event.target.id] = event.target.value;
            setMarker({
                longitude: parseFloat(data.longitude),
                latitude: parseFloat(data.latitude)
            });
            setViewport({
                latitude: parseFloat(data.latitude),
                longitude: parseFloat(data.longitude),
                zoom: 8.5,
                bearing: 0,
                pitch: 0
            });
            logEvents({
                longitude: parseFloat(data.longitude),
                latitude: parseFloat(data.latitude)
            });
        }
    }

    const editMap = () => {
        setReadonly(false);
    }

    const editAccessToken =() => {
        setAccessReadOnly(false);
    }

    const deleteLocation = () => {
        swal({
            text: 'Are you sure you want to remove this location?',
            buttons: ['No', 'Yes'],
            dangerMode: true,
            icon: 'warning'
        })
      .then((isConfirm) => {
        if (isConfirm) {
          axios.delete(Constants.EDGE_API_ENDPOINT + '/system/device_location')
            .then(res => {
              swal('Location removed successfully.', { icon: 'success' });
              setTimeout(function () { window.location.reload(); }, 2000);
            })
            .catch(error => {
              if (error.response) {
                var errorObj = error.response.data;
                swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
              } else {
                swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
              }
            });
        } else {
            setReadonly(true);
        }
      });
    }

    const getLocationApi = useCallback((browser_location) => {
        axios.get(Constants.EDGE_API_ENDPOINT + '/system/device_location')
        .then(res =>{
          if(res.data.data.action === 'DeleteStack'){
            setLocationInputs(LOCATION_DEFAULT);
            let data = LOCATION_DEFAULT;
            setConfigured(true);
            setShowDeleteButton(false);
            setLocationLoading(false);
            setMarker({
                longitude: parseFloat(data.longitude),
                latitude: parseFloat(data.latitude)
            });
            setViewport({
                latitude:  parseFloat(data.latitude),
                longitude:  parseFloat(data.longitude),
                zoom: 8.5,
                bearing: 0,
                pitch: 0
            });
            logEvents({
                longitude: parseFloat(data.lon),
                latitude: parseFloat(data.lat)
            });
          } else {
            let data = res.data.data;
            data.latitude = data.lat;
            data.longitude = data.lon;
            data.browser_location = browser_location;
            if(data.lat === ' ' && data.lon === ' ') {
                setLocationInputs(data)
                setConfigured(true);
                setShowDeleteButton(true);
                setLocationLoading(false); 
                delete data.browser_location;
                setMarker({
                    longitude: parseFloat(data.lon),
                    latitude: parseFloat(data.lat)
                });
                setViewport({
                    longitude: parseFloat(data.lon),
                    latitude: parseFloat(data.lat),
                    zoom: 8.5,
                    bearing: 0,
                    pitch: 0
                });
                logEvents({
                    longitude: parseFloat(data.lon),
                    latitude: parseFloat(data.lat)
                });
            } else {
                setLocationInputs(data)
                setConfigured(false);
                setShowDeleteButton(true);
                setLocationLoading(false);
                delete data.browser_location;
                setMarker({
                    longitude: parseFloat(data.lon),
                    latitude: parseFloat(data.lat)
                });
                setViewport({
                    longitude: parseFloat(data.lon),
                    latitude: parseFloat(data.lat),
                    zoom: 8.5,
                    bearing: 0,
                    pitch: 0
                });
                logEvents({
                    longitude: parseFloat(data.lon),
                    latitude: parseFloat(data.lat)
                });
            }
          }
        })
        .catch(err => {
          console.log(err);
          setConfigured(true);
          setLocationLoading(false);
        });
    },[locationInputs]);

    const getAccessToken = useCallback( () => {
        axios.get(Constants.EDGE_API_ENDPOINT + '/system/device_location/map/all')
        .then(res =>{
            if(res.data.data.access_token === ' ' || res.data.data.access_token === ''){
                setAccessTokenInput(res.data.data)
                setShowMap(false)
                setAccessTokenLoading(false)
                setMapToken('')
                setMapService(res.data.data.service_provider)
            } else {
                setAccessTokenInput(res.data.data)
                if(res.data.data.service_provider === 'default' ) {
                    setShowMap(false)
                } else {
                    setShowMap(true)
                }
                setAccessTokenLoading(false)
                setMapToken(res.data.data.access_token)
                setMapService(res.data.data.service_provider)
            }
        }).catch(err => {
            console.log(err);
            let data = {};
            data.access_token = '';
            data.service_provider = 'default';
            setAccessTokenInput(data)
            setShowMap(false)
            setAccessTokenLoading(false)
            setMapToken('')
            setMapService(data.service_provider)
          });
    },[]);

    useEffect(() => {
        getLocationApi(false);
        getAccessToken();
    },[getAccessToken]);

    const onSave = () => {
        let jsonData = Object.assign({}, locationInputs);
        let input = Object.assign({}, locationInputs);
        let lat = jsonData.latitude;
        let lon = jsonData.longitude;
        input.longitude = round5(input.longitude.toString());
        input.latitude = round5(input.latitude.toString());

        if(lat === undefined || lat === '' || lat === '0.00000' || lat === '0.0000'|| lat === '0.000'|| lat === '0.00' || lat === '0.0' || lat === '0' ){
            swal('Please enter latitude.', { icon: 'error'});
            return;
        }
        if(lon === undefined || lon === '' || lon === '0.00000' || lon === '0.0000'|| lon === '0.000'|| lon === '0.00'|| lon === '0.0'|| lon === '0'){
            swal('Please enter longitude.', { icon: 'error'});
            return;
        }
        if(lat.length === 1 && lat === '-'){
            swal('Invalid Latitude.', { icon: 'error'});
            return;
        }
        if(lon.length === 1 && lon === '-'){
            swal('Invalid Longitude.', { icon: 'error'});
            return;
        }
        jsonData.namespace= 'system'
        jsonData.lat = round5(jsonData.latitude.toString());
        jsonData.lon = round5(jsonData.longitude.toString());
        delete jsonData.latitude;
        delete jsonData.longitude;
        delete jsonData.access_token;
        delete jsonData.browser_location;
        axios.patch(Constants.EDGE_API_ENDPOINT + '/system/device_location', jsonData)
            .then(res => {
                swal('Location updated successfully.', { icon: 'success', timer: '3000'});
                setReadonly(true);
                setShowDeleteButton(true);
                getLocationApi(input.browser_location);
                setLocationInputs(input);
            })
            .catch(error => {
                if(error.response){
                var errorObj = error.response.data;
                swal('Error Code: ' + errorObj.error.code +'\nError Message: ' + errorObj.error.message, { icon: 'error'});
                }else{
                swal({text: 'Unable to connect to the edge-api service' , icon: 'error'});
                }
        });
    }

    const saveAcessKey = () => {
        let jsonData = Object.assign({}, accessTokenInput);
        if(accessTokenInput.service_provider === 'map-box') {
            console.log('in mapbox api',accessTokenInput.access_token )
            if(accessTokenInput.access_token === '' || accessTokenInput.access_token === ' ' ) {
                swal({text: 'Please enter access token' , icon: 'error'});
                return;
            }
            axios.get(`https://api.mapbox.com/tokens/v2?access_token=${accessTokenInput.access_token}`)
            .then(res => {
               let data = res.data;
               if(data.code !== 'TokenValid') {
                swal({text:'Access token not valid', icon: 'error'})
                return;
               } else {
                jsonData.namespace = 'system'
                axios.put(Constants.EDGE_API_ENDPOINT + '/system/device_location/map/all', jsonData)
                    .then(res => {
                        swal('Map service saved successfully.', { icon: 'success', timer: '2000'});
                        setAccessReadOnly(true);
                        setShowMap(true);
                        setMapToken(accessTokenInput.access_token);
                        setMapService(accessTokenInput.service_provider);
                    })
                    .catch(error => {
                        if(error.response){
                            var errorObj = error.response.data;
                            swal('Error Code: ' + errorObj.error.code +'\nError Message: ' + errorObj.error.message, { icon: 'error'});
                        } else {
                            swal({text: 'Unable to connect to the edge-api service' , icon: 'error'});
                        }
                });
               }
            })
            .catch(error => {
                console.log(error)
            });
        } 
        if(accessTokenInput.service_provider === 'default') { 
            jsonData.namespace = 'system'
            jsonData.access_token = ''
            axios.put(Constants.EDGE_API_ENDPOINT + '/system/device_location/map/all', jsonData)
                .then(res => {
                    swal('Map service saved successfully.', { icon: 'success', timer: '2000'});
                    setAccessReadOnly(true);
                    setMapToken('');
                    setMapService(accessTokenInput.service_provider)
                    setShowMap(false);
                    let data = {...accessTokenInput};
                    data.access_token = '';
                    setAccessTokenInput(data) 
                })
                .catch(error => {
                    if(error.response){
                        var errorObj = error.response.data;
                        swal('Error Code: ' + errorObj.error.code +'\nError Message: ' + errorObj.error.message, { icon: 'error'});
                    } else {
                        swal({text: 'Unable to connect to the edge-api service' , icon: 'error'});
                    }
                });
        }
    }

    const onChangeAccessToken = (event) => {
        if (event.target.name === 'access_token') { 
            event.target.value = event.target.value.replace(/\s/g, '');  
        }
        setAccessTokenInput({...accessTokenInput, [event.target.name]: event.target.value }); 
    }

    const clickToCopy = () => {
        const textarea = document.createElement('textarea');
        textarea.id = 'copyTextArea';
        textarea.value = document.getElementById('access_token').value
        textarea.setAttribute('readonly', '');
        textarea.style.position = 'absolute';
        textarea.style.width = '0px';
        textarea.style.height = '0px';
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.getElementById('copyTextArea').remove();
      };
    
      const onMarkerDragStart = useCallback(event => {
        logEvents({ 
            longitude: event.lngLat[0],
            latitude: event.lngLat[1]
        });
      }, []);
    
      const onMarkerDrag = useCallback(event => {
        logEvents({ 
            longitude: event.lngLat[0],
            latitude: event.lngLat[1]
        });
      }, []);
    
      const onMarkerDragEnd = useCallback(event => {
        logEvents({
            longitude: event.lngLat[0],
            latitude: event.lngLat[1]
        });
        setMarker({
          longitude: event.lngLat[0],
          latitude: event.lngLat[1]
        });
        let data = {...locationInputs}
        data.longitude = round5(event.lngLat[0])
        data.latitude = round5(event.lngLat[1])
        setLocationInputs(data);
      }, [locationInputs]);

      function onTabChange(tabId){
        setSelectedTab(tabId);
      }

      const getMode = (childData) => {
        setSelectMode(childData);
      }

      return (
        <ContentDiv>
            <Wrapper>
                <SectionHeader>
                    <SectionLabel>Location</SectionLabel>
                </SectionHeader>
                <Tabs defaultTab={selectedTab} onChange={onTabChange}>
                    <TabList style={{'padding-left': '15px'}}>
                        <Tab tabFor="general" className={'rwt__tab'}>General</Tab>
                        <Tab tabFor="map-service" className={'rwt__tab'}>Map Service</Tab>
                    </TabList>
                    <TabPanel tabId="general">
                        <Loader isLoading={locationLoading} />
                        {isConfigure && !locationLoading && marker && 
                        <>
                            <Row>
                            <Column>
                                <Label style={{'margin-left': '15px', 'margin-top': '5px' }}>Location is not configured.</Label><br />
                                <Button primary onClick={configuredHandle}>Configure</Button>
                            </Column>
                            </Row>
                        </>}
                        {!isConfigure && locationInputs && marker && mapService && (mapToken || !mapToken) && selectMode &&
                            <>
                                <Row style={{width: 'auto'}}>
                                    <Column  style={{'display': 'flex', 'float': 'left', 'padding': '10px 2%', width: 'auto', display: 'flex', 'flexDirection':'column'}} >
                                        <div>
                                        <SelectionControl id="browser_location" type="switch" label="Browser's Location"  name="browser_location" checked={locationInputs.browser_location} onChange={handleBrowserToggle} labelBefore="true" style={{marginTop: '10px', 'display': (readOnly ? 'none' : '')}} />
                                        </div>
                                        <div style={{'display': 'flex'}}>
                                            <div>
                                                <TextFieldLabel style={{'width': '80px'}}>Latitude</TextFieldLabel>
                                                <TextFieldSmall id="latitude" name='latitude' title={locationInputs.latitude} maxLength={8} disabled={readOnly ? true : locationInputs.browser_location} value={locationInputs.latitude} onChange={handleOnChange} onBlur={onBlurInputs} style={{'width': '150px', 'cursor':'pointer'}} />
                                            </div>
                                            <div style={{'marginLeft': '20px'}}>
                                                <TextFieldLabel style={{'width': '80px'}}>Longitude</TextFieldLabel>
                                                <TextFieldSmall id="longitude" name='longitude' title={locationInputs.longitude} maxLength={9} disabled={readOnly ? true : locationInputs.browser_location} value={locationInputs.longitude} onChange={handleOnChange} onBlur={onBlurInputs} style={{'width': '150px', 'cursor':'pointer'}} /> 
                                            </div>
                                        </div>
                                    </Column>
                                    <div style={{'clear':'both'}}>
                                        <Column style={{'display': 'flex', width: '100%', justifyContent:'space-between'}}>
                                            <SelectionControl id="enable_cloud_upload" type="switch" label={locationInputs.enable_cloud_upload ? 'Upload to SCORER Cloud': 'Do not upload to SCORER Cloud'} value={locationInputs.enable_cloud_upload} checked={locationInputs.enable_cloud_upload } name="enable_cloud_upload" onChange={handleToggle} labelBefore="true" style={{marginTop: '10px'}} />
                                            {(!readOnly && showDeleteButton && showMap) && <Button onClick={deleteLocation}>Remove Location</Button>}
                                        </Column>
                                    </div>
                                        {(showMap && mapToken)? 
                                        <Row style={{'marginTop': '30px'}}>
                                        <div style={{margin: '20px'}}>
                                        <MapGL
                                            {...viewport}
                                            width="100%"
                                            height="500px"
                                            mapStyle={selectMode}
                                            onViewportChange={setViewport}
                                            mapboxApiAccessToken={(mapToken === ' ' || mapToken === '') ? 'nomap' : mapToken}
                                        >
                                        <Marker
                                            longitude={marker.longitude}
                                            latitude={marker.latitude}
                                            offsetTop={-20}
                                            offsetLeft={-10}
                                            draggable={!readOnly}
                                            onDragStart={onMarkerDragStart}
                                            onDrag={onMarkerDrag}
                                            onDragEnd={onMarkerDragEnd}
                                        >
                                        <div class="marker"></div>
                                        <span class="beacon"></span>
                                        </Marker>
                                
                                        <div className="nav" style={navStyle}>
                                        <NavigationControl />
                                        </div>
                                        <ControlPanel events={events} mode={getMode} />
                                    </MapGL>
                                    
                                        </div>
                                    </Row> : <Row><Column style={{'width': 'auto'}}><MessageLabel>To see location on map, please specify Map API Access Token in Map Service tab.<br/>For generating Access Token you can visit <a href='https://www.mapbox.com' target='_blank'>https://www.mapbox.com</a>/</MessageLabel></Column></Row>}
                                </Row>
                                <Row style={{'width':  ((showMap || mapService !== 'default')? 'auto' :(window.innerWidth === 1920 ? '550px': (window.innerWidth === 1366 ? '540px': '538px')))}}>
                                {readOnly ?
                                    <ButtonWrapper>
                                        <Button primary onClick={editMap}>Edit</Button>
                                    </ButtonWrapper> :
                                    <ButtonWrapper>
                                        {(mapService === 'default' && !readOnly && showDeleteButton) && <Button onClick={deleteLocation}>Remove Location</Button>}
                                        <Button primary onClick={onSave}>Save</Button>
                                        <Button id='btnCancle' danger onClick={() =>  window.location.reload()}>Cancel</Button>
                                    </ButtonWrapper>}
                                </Row>
                            </>}
                    </TabPanel>
                    <TabPanel tabId='map-service'>
                        <Loader isLoading={accessTokenLoading} />
                        {accessTokenInput && !accessTokenLoading &&
                        <>
                            <Row style={{'width': 'auto', display:'flex', 'flexDirection': 'column'}}>
                                <div style={{'width': 'auto', marginLeft:'25px'}}>
                                    <TextFieldLabel  style={{'width': '150px'}}>Service Provider</TextFieldLabel>
                                    <SelectField id="service_provider" name="service_provider" style={{'width': '400px' }} value={accessTokenInput.service_provider} disabled={accessReadOnly} onChange={onChangeAccessToken}>
                                        <SelectOptions items={Constants.MAP_SERVICES} />
                                    </SelectField>
                                </div>
                                {accessTokenInput.service_provider === 'map-box' &&
                                    <div style={{'width': 'auto', marginLeft:'25px'}}>
                                        <TextFieldLabel style={{'width': '150px'}}>Access Token</TextFieldLabel>
                                        <TextFieldSmall title={accessTokenInput.access_token} id="access_token" name='access_token' disabled={accessReadOnly} style={{width: '400px', 'cursor': 'pointer'}} value={accessTokenInput.access_token} onChange={onChangeAccessToken} />
                                        {accessReadOnly && <CopyButton primary onClick={() => clickToCopy()} style={{ marginTop: '25px', 'marginLeft':'5px' }}>Copy</CopyButton>}
                                    </div>}
                            </Row>
                            <Row style={{'width': '595px'}}>
                            {accessReadOnly ?
                                <ButtonWrapper>
                                    <Button primary onClick={editAccessToken}>Edit</Button>
                                </ButtonWrapper> :
                                <ButtonWrapper>
                                    <Button primary onClick={saveAcessKey}>Save</Button>
                                    <Button id='btnCancle' danger onClick={() =>  window.location.reload()}>Cancel</Button>
                                </ButtonWrapper>}
                            </Row>
                        </>}
                    </TabPanel>
                </Tabs>
            </Wrapper>
        </ContentDiv>
      );
};

export default Location;
