import React, { Component } from "react";
import PropTypes from "prop-types";

import AccordionSection from "./AccordionSection";

class Accordion extends Component {
    static propTypes = {
        allowMultipleOpen: PropTypes.bool,
        children: PropTypes.instanceOf(Object).isRequired,
    };


    static defaultProps = {
        allowMultipleOpen: false,
    };

    constructor(props) {
        super(props);
        const openSections = {};
        this.props.children.forEach(child => {
            if (child.props.isOpen) {
                openSections[child.props.label] = true;
                openSections[child.props.buttonLable] = true;
            }
        });
        this.state = { openSections };
  }

    onClick = label => {
        const { props: { allowMultipleOpen }, state: { openSections } } = this;
        const isOpen = !!openSections[label];
        if (allowMultipleOpen) {
            this.setState({
                openSections: {
                    ...openSections,
                    [label]: !isOpen
                }
            });
        } else {
            this.setState({
                openSections: {
                    [label]: !isOpen
                }
          });
        }
    };

    render() {
        const {onClick, props: { children }, state: { openSections }} = this;
        return (
            <div>
            { children.map(child => (
                <AccordionSection
                    isOpen={!!openSections[child.props.label]}
                    label={child.props.label}
                    statusType={child.props.status}
                    stackType={child.props.stackType}
                    stackSubType={child.props.stackSubType}
                    stackName={child.props.stackName}
                    disableMenu={child.props.disableMenu}
                    deleteButtonDisable={child.props.deleteButtonDisable}
                    showMenu={this.props.showMenu}
                    onClick={onClick}
                >
                {child.props.children}
                </AccordionSection>
            ))}
            </div>
        );
    }
}

export default Accordion;
