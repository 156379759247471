import React, { useEffect, useState, useRef} from 'react';
import axios from 'axios';
import { ContentDiv, Link, Wrapper, LeftDiv1, RightDiv, StreamContentDiv, SectionHeader, SectionLabel, RunningLabel, FailedLabel, RecoveringLabel, DisabledLabel, PausedLabel, Label, SelectField, ThreeDotMenu } from '../utils/Styles';
import "../css/react-metismenu-standard.css";
import * as Constants from '../utils/Constants';
import CameraImage from '../components/CameraImage';
import { GridList, Card, Media, ListItem, MenuButton, CircularProgress } from 'react-md';
import Popup from "reactjs-popup";
import swal from "sweetalert";
import AutoRefresh from '../components/AutoRefresh';
import ls from 'local-storage';
import styled from 'styled-components';
import downarrow from '../images/downarrow.png';
import uparrow from '../images/uparrow.png';

const Dropdown = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 80%;
    left: auto;
    width: 170px;
    z-index: 2;
    background: #fff;
    padding-bottom: 5px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
`;

const StyledLink = styled(Link)`
    padding: 10px;
    text-decoration: none;
    color: black;
    width: auto;
    margin: 5px 5px 0 5px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.14);
      cursor: pointer;
    }
`;

const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? '#0097f1' : '#FF7F50'};
  color: #ffffff;
  font-size: 1em;
  margin: 1em 0em 1em 1em;
  padding: 5px 0px 5px 0px;
  border: 2px solid ${props => props.primary ? '#0097f1' : '#FF7F50'};
  border-radius: 3px;
  opacity: 0.7
  :hover {
    cursor: pointer;
    opacity: 1.0;
  }
  :focus {
    outline:none;
  }
`;

const StreamList = ({history})=> {
    const [stackList, setStackList] = useState();
    const [statusCount, setStatusCount] = useState();
    const [streamStatusData, setStreamStatusData] = useState();
    const [healthStatus, sethealthStatus] = useState();
    const [splitIntervalMaxVal, setSplitIntervalMaxVal] = useState();
    const [openDownDrop, setOpenDropDown] = useState(false);
    const ref = useRef();

    const hostStyle = {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer',
        maxWidth: '350px'
      };

    useOnClickOutside(ref, () => setOpenDropDown(false));

    function viewStream(stack_name) {
        window.location.href = "./view-stream?stream=" + stack_name
    }

    function reloadStreamList(){
        history.push("./stream-viewer");
    }

    // function autoRefreshCallback(){
    //     getStreamList();
    // }


    useEffect(() => {
        getStreamList();
    }, []);

    function getStreamList(){
        axios.get(Constants.EDGE_API_ENDPOINT + '/stacks/all')
            .then(res => {
                if("device_id" in res.headers){
                    ls.set("device_id", res.headers["device_id"]);
                    document.getElementById("deviceId").textContent = res.headers["device_id"];
                }
                if("device_ip" in res.headers){
                    ls.set("device_ip", res.headers["device_ip"]);
                }
                const stacks = res.data["data"]["stacks"];
                Object.keys(stacks).map((stack_name) => {
                    if(stacks[stack_name]["action"] === "DeleteStack"){
                        delete stacks[stack_name];
                    }
                });
                setStackList(stacks);
            }).catch(error => {
                if(error.response){
                    var errorObj = error.response.data;
                    swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
                }else{
                    swal({text: "Unable to connect to the edge-api service" , icon: "error"});
                }
            });

        axios.get(Constants.EDGE_API_ENDPOINT + '/stacks/status/all')
            .then(res => {
                var runningCount = 0, recoveringCount = 0, disabledCount = 0, failedCount = 0, pausedCount = 0;
                const stacks = res.data["data"]["stacks"];
                Object.keys(stacks).map((stack_name) => {
                    let statckStatus = stacks[stack_name]["running_status"]["status"];
                    if(statckStatus === "running"){
                        runningCount++;
                    } else if(statckStatus === "disabled"){
                        disabledCount++;
                    } else if(statckStatus === "paused"){
                        pausedCount++;
                    } else if(statckStatus === "recovering" || statckStatus === "desired"){
                        recoveringCount++;
                    } else if(statckStatus === "failed"){
                        failedCount++;
                    }
                });
                var statusCountJson = {
                    "running_count": runningCount,
                    "disabled_count": disabledCount,
                    "recovering_count": recoveringCount,
                    "failed_count": failedCount,
                    "paused_count": pausedCount
                }
                setStatusCount(statusCountJson);
                setStreamStatusData(res.data.data)
            });
    }

    useEffect(() =>{
        axios.get(Constants.EDGE_API_ENDPOINT + '/system/status')
        .then(res =>{
            sethealthStatus(res.data['data']['services']['tmpfs_usage']['/opt/scorer/cache/videos']['size']);
            console.log(res.data['data']['services']['tmpfs_usage']['/opt/scorer/cache/videos']['size'], 'health Status');
        })
        .catch(err => {
            console.log(err)
        })
    }, []);

    useEffect(() =>{
        const interval = setInterval(() => {
            getStreamList();
        }, 3000);
        return () => clearInterval(interval);
    },[]);

    function getVideoDevice(stack_name, services){
        var video_device = "";
        Object.keys(services).map((service_name) => {
            if(services[service_name]["type"] == "recorder"){
                video_device = services[service_name]["video_device"];
            }
        });
        return video_device;
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
      }

      useEffect(() => {
        let obSize = formatBytes(healthStatus);
        let num = Math.floor(obSize);
        let val = num * 0.4;
        setSplitIntervalMaxVal(Math.floor(val));
      },[healthStatus]);

    function deleteStack(stackName){
        swal({text: "Are you sure you want to delete this stream?",
              buttons: ['No', 'Yes'],
              dangerMode: true
        })
        .then((isConfirm) => {
            if (isConfirm) {
                axios.delete(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName + "/all")
                    .then(res => {
                        swal("Stream delete request submitted successfully.", { icon: "success"});
                        setTimeout(function(){ window.location.reload() }, 2000);
                    })
                    .catch(error => {
                        if(error.response){
                            var errorObj = error.response.data;
                            swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
                        }else{
                            swal({text: "Unable to connect to the edge-api service" , icon: "error"});
                        }
                    });
            }
        });
    }

    const enableDisableStack = (stackName, actionLabel) => {
        var action = (actionLabel == "Enable" ? "start" : "stop")
        var jsonData = {
            "namespace": "default",
            "action": action,
            "source": "Edge UI"
        }
        var msg = "Are you sure you want to "+ actionLabel.toLowerCase() +" this stream?"

        swal({text: msg,
             buttons: ['No', 'Yes'],
             dangerMode: true,
            }).then(function(isConfirm) {
                if (isConfirm) {
                    axios.post(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName, jsonData)
                    .then(res => {
                        swal("Stream " + actionLabel.toLowerCase() + " request submitted successfully.", { icon: "success"});
                        setTimeout(function(){ window.location.reload() }, 2000);
                    })
                    .catch(error => {
                        if(error.response){
                            var errorObj = error.response.data;
                            swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
                        }else{
                            swal({text: "Unable to connect to the edge-api service" , icon: "error"});
                        }
                    });
                }
            });
    }

    function getStreamStatus(stackName){
        var streamStatus = "";
        try{
            if (streamStatusData && "stacks" in streamStatusData){
                streamStatus = streamStatusData["stacks"][stackName]["running_status"]["status"];
            }
        }catch(error){
            console.log(error);
        }
        return streamStatus;
    }

    const truncate = (input) =>{
        if (input.length > 28) {
           return input.substring(0, 28) + '...';
        }
        return input;
      };

      const handleDropDown = () => {
        if(openDownDrop) {
            setOpenDropDown(false);
        } else {
            setOpenDropDown(true);
        }
    }

    function useOnClickOutside(ref, handler) {
        useEffect(
          () => {
            const listener = (event) => {
              // Do nothing if clicking ref's element or descendent elements
              if (!ref.current || ref.current.contains(event.target)) {
                return;
              }
      
              handler(event);
            };
      
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
      
            return () => {
              document.removeEventListener("mousedown", listener);
              document.removeEventListener("touchstart", listener);
            };
          },
          [ref, handler]
        );
    }

    return (
        <ContentDiv style={{'overflow':'hidden', 'paddingBottom':'100px'}}>
            { stackList == undefined &&
                <div className="loading-msg">
                    <label>Loading...</label>
                    <CircularProgress/>
                </div>
            }
            { stackList &&

            <Wrapper>
                <SectionHeader>
                  <SectionLabel>Stream Viewer</SectionLabel>
                  { statusCount &&
                  <RightDiv>
                    {/* <div>
                      <AutoRefresh autoRefresh={autoRefreshCallback} pageName="stream_list"/>
                    </div> */}
                    <div style={{"clear": "both"}}>
                      <RunningLabel id="runningCount">Running: {statusCount.running_count}</RunningLabel>
                      <RecoveringLabel id="reconciliationCount">Reconciliation: {statusCount.recovering_count}</RecoveringLabel>
                      <PausedLabel id="pausedCount">Paused: {statusCount.paused_count}</PausedLabel>
                      <DisabledLabel id="disabledCount">Disabled: {statusCount.disabled_count}</DisabledLabel> 
                      <FailedLabel id="failedCount">Failed: {statusCount.failed_count}</FailedLabel>
                    </div>
                  </RightDiv>
                  }
                </SectionHeader>
                <div style={{ 'display': 'flex', 'justifyContent': 'flex-end', 'marginRight': '10px', 'position': 'relative'}} ref={ref}>
                <Button primary onClick={handleDropDown} style={{'width': '130px'}}>Add Stream <img src={openDownDrop ? uparrow : downarrow} style={{'width': '10px', 'marginLeft': '5px'}} /> </Button>  
                        {openDownDrop && 
                        <Dropdown>
                          <StyledLink href='/add-usb-stream'>Web Camera (UVC)</StyledLink>
                          <StyledLink href='/add-rtsp-stream'>IP Camera (RTSP)</StyledLink>
                          <StyledLink href='/add-ftp-stream'>Image (FTP)</StyledLink>
                          <StyledLink href='/add-safie-stream'>Safie Camera</StyledLink>
                          <StyledLink href='/add-rtmp-stream'>RTMP</StyledLink>
                          <StyledLink href='/add-rtp-stream'>RTP</StyledLink>
                        </Dropdown>}
                    </div>
                { Object.keys(stackList).length===0 &&
                    <div style={{"margin": "100px 20px"}}>Stream list is empty.<br/><br/>You can add a stream using <b>Add Stream</b> menu.</div>
                }
                <GridList size={window.innerWidth >= 1920 ? 4 : 6}>
                { Object.keys(stackList).map((stack_name) => {
                    const {description, enabled, services, action, action_status, snapshot_refresh_interval} = stackList[stack_name];
                    var streamStatus = getStreamStatus(stack_name)
                    const disableMenu = (action === "DeleteStack" && action_status.status === "desired") ? "disabled" : "";
                    const enabledLabel = (streamStatus === "disabled") ? "Enable" : "Disable";
                    var recorderService
                    var sourceType = "RTSP"
                    var refreshImage = (enabled === false || streamStatus == "paused" || streamStatus == "disabled") ? false : true
                    Object.keys(services).map((service_name) => {
                        if(services[service_name]["type"] == "recorder") {
                            recorderService = services[service_name]
                        }
                    })
                    const contentStyle = {
                        "width":" 85%",
                        "text-align": "center",
                        "height": "90%"
                    };
                    return(
                        <Card key={stack_name}>
                            {
                            (() => {
                            if (streamStatus === "paused" )
                                return <Media>
                                    <CameraImage stackName={stack_name} refreshInterval={refreshImage ? snapshot_refresh_interval : 0} streamStatus={streamStatus} recorder={recorderService} isListPage={true}/>
                                </Media>
                            if (streamStatus === "disabled" )
                                return <Media><img src={ require('../images/no_image.png')} className="no_img"/></Media>
                            else
                                return <Media>
                                            <CameraImage stackName={stack_name} refreshInterval={refreshImage ? snapshot_refresh_interval : 0} streamStatus={streamStatus} recorder={recorderService} isListPage={true}/>
                                        </Media>
                            })()
                            }
                            { streamStatusData &&
                                <div className={"stream_list_" + getStreamStatus('' + stack_name + '')}></div>
                            }
                            <StreamContentDiv>
                                <LeftDiv1>
                                    <Link href={'./view-stream?stream=' + stack_name} onClick={() => viewStream('' + stack_name + '')} ><b><div style={hostStyle} title={stack_name}>{stack_name}</div></b></Link>
                                    <p title={description} style={{cursor: "pointer"}}>{truncate(description)}</p>
                                </LeftDiv1>
                                <RightDiv>
                                    <MenuButton
                                      id="menu-button"
                                      icon
                                      menuItems={[
                                        <ListItem id="viewCtxMenu" key={1} primaryText="View" onClick={() => viewStream('' + stack_name + '')}/>,
                                        <ListItem id="enableCtxMenu" key={2} primaryText={enabledLabel} onClick={() => enableDisableStack('' + stack_name + '', enabledLabel)} disabled={disableMenu}/>,
                                        <ListItem id="deleteCtxMenu" key={3} primaryText="Delete" onClick={() => deleteStack('' + stack_name + '')}/>
                                      ]}
                                      centered
                                      anchor={{
                                        x: MenuButton.VerticalAnchors.CENTER,
                                        y: MenuButton.VerticalAnchors.CENTER,
                                      }}
                                      style={{"margin-top": "-9px"}}
                                    >
                                        <ThreeDotMenu />
                                    </MenuButton>
                                </RightDiv>
                            </StreamContentDiv>
                        </Card>
                    )
                })}
                </GridList>
            </Wrapper>
            }
            {/* {statusCount &&
                <RightDiv>
                    <div style={{'margin': '13px'}}>
                        <AutoRefresh autoRefresh={autoRefreshCallback} pageName="stream_list"/>
                    </div>
                </RightDiv>} */}
        </ContentDiv>
    );
}

export default StreamList;