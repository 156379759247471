import React, {useState} from 'react';
import { SelectionControl } from 'react-md';

function round5(value) {
  return (Math.round(value * 1e5) / 1e5).toFixed(5);
}

function ControlPanel(props) {
  const {events} = props;
  const [darkMode, setDarkMode] = useState(false);
  const [value, setValue] = useState();

  const handleToggle = (value, event) =>{
    setDarkMode(value);
    if(value) {
      props.mode('mapbox://styles/mapbox/dark-v9');
    } else {
      props.mode('mapbox://styles/mapbox/streets-v11');
    }
  }

  return (
    <div className="control-panel">
      <div>
        <b>Latitude: </b> <i>{round5(events.latitude)}</i><br/>
        <b>Longitude: </b> <i>{round5(events.longitude)}</i>
      </div>
      <SelectionControl id="mode" type="switch" label={darkMode ? "Light Mode" :"Dark Mode"} labelBefore='false' name="mode" checked={darkMode} value={darkMode} onChange={handleToggle} />
    </div>
  );
}

export default React.memo(ControlPanel);