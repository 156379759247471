import React, { useState, useEffect } from 'react'
import { Tabs, Tab, TabList } from 'react-web-tabs';
import axios from 'axios';
import { ContentDiv, Wrapper, Label, LeftDiv, Spacer, TextField, MandatoryLabelStream, TextArea, SelectField, Row, Column, RightDiv, SectionHeader, Button, ButtonWrapper, SectionLabel } from '../utils/Styles';
import SelectOptions from '../components/SelectOptions';
import RecordingTab from '../components/RecordingTab';
//import AnalysisTab from '../components/AnalysisTab';
import BroadcastingTab from '../components/BroadcastingTab';
import StreamForwardingTab from '../components/StreamForwardingTab';
import CaptureScheduleTab from '../components/CaptureScheduleTab';
import * as Constants from '../utils/Constants';
import "../css/react-tabs.css"
import { SelectionControl } from 'react-md';
import swal from "sweetalert";
import ls from 'local-storage';


const AddStream = (props) => {
    const source = getSourceType();
    const [isMobileBrowser, setIsMobileBrowser] = useState(false);

    useEffect(()=>{
        var isMobileBrowser = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if(isMobileBrowser) {
        setIsMobileBrowser(isMobileBrowser);
        } else {
        setIsMobileBrowser(isMobileBrowser);
        }
    },[]);

    const initialValues = Constants.STACK_ATTR_DEFAULT_VALUES;
    if(source === "FTP"){
        initialValues["snapshot_refresh_interval"] = "10";
    }
    if(source === "SAFIE"){
        initialValues["snapshot_refresh_interval"] = "3";
    }
    const [videoDevices, setVideoDevices] = useState();
    const [inputs, setInputs] = useState(initialValues);
    const [streamList, setStreamList] = useState({});
    const [videoSize, setVideoSize] = useState();
    const [splitIntervalMaxVal, setSplitIntervalMaxVal] = useState();
    const [videoDeviceInfo, setVideoDeviceInfo] = useState();
    var recorderState = getRecorderState();
    //var analysisState = Constants.ANALYSIS_DEFAULT_VALUES;
    var broadcastingState = source === "SAFIE" || source === "FTP" ? Constants.FOG_BROADCASTER_DEFAULT_VALUES : Constants.BROADCASTING_DEFAULT_VALUES;
    var streamForwardingState = Constants.STREAM_FORWARDER_DEFAULT_VALUES;
    const [selectedVideoDevice, setSelectedVideoDevice] = useState();
    var captureScheduleState;
    var captureDescreteState;
    const [configure, setConfigure] = useState(true);
    const [cameraOptions, setCameraOptions] = useState(false);

    useEffect(() =>{
        axios.get(Constants.EDGE_API_ENDPOINT + '/system/status')
        .then(res =>{
            setVideoSize(res.data['data']['services']['tmpfs_usage']['/opt/scorer/cache/videos']['size']);
        })
        .catch(err => {
            console.log(err)
        })
    }, []);

    useEffect(() => {
        var sizeInMB = (videoSize / (1024*1024)).toFixed(2);
        let num = Math.floor(sizeInMB);
        let finalSize = num * (1 - 60 / 100);
        setSplitIntervalMaxVal(Math.floor(finalSize));
      },[videoSize]);

    function getSourceType(){
        if(props.match.path.includes("add-rtmp-stream")){
            return "RTMP";
        } else if(props.match.path.includes("add-rtsp-stream")){
            return "RTSP";
        } else if(props.match.path.includes("add-usb-stream")){
            return "USB";
        } else if(props.match.path.includes("add-rtp-stream")){
            return "RTP";
        } else if(props.match.path.includes("add-ftp-stream")){
            return "FTP";
        } else if(props.match.path.includes("add-safie-stream")){
            return "SAFIE";
        }
    }

    function getRecorderState(){
        let recorderDefaultValue;
        if(source === "RTMP"){
            recorderDefaultValue = Constants.RECORDER_RTMP_DEFAULT_VALUES
        } else if(source === "RTSP"){
            recorderDefaultValue = Constants.RECORDER_RTSP_DEFAULT_VALUES;
        } else if(source === "USB"){
            recorderDefaultValue = Constants.RECORDER_USB_DEFAULT_VALUES;
        } else if(source === "RTP"){
            recorderDefaultValue = Constants.RECORDER_RTP_DEFAULT_VALUES;
        } else if(source === "FTP"){
            recorderDefaultValue = Constants.RECORDER_FTP_DEFAULT_VALUES;
        } else if(source === "SAFIE"){
            recorderDefaultValue = Constants.RECORDER_SAFIE_DEFAULT_VALUES;
        }
        recorderDefaultValue["camera_type"] = source;
        return recorderDefaultValue;
    }

    useEffect(() => {
        axios.get(Constants.EDGE_API_ENDPOINT + '/stacks/all')
            .then(res => {
                const stream_list = res.data
                setStreamList(stream_list['data']['stacks'])
            })
        if(source === "USB"){
            axios.get(Constants.EDGE_API_ENDPOINT + '/devices/videos')
                .then(res => {
                    let respData = res.data["data"];
                    if(respData){
                        if(respData["available_devices"].length > 0) {
                            setVideoDevices((respData["available_devices"]).sort());
                            setInputs(inputs => ({...inputs, ["video_device"]: respData["available_devices"][0].name}));
                            getVideoDeviceData(respData["available_devices"][0].name);
                        } else {
                            let noVideoDevice = [{"name": "No Video Device Available"}];
                            setVideoDevices(noVideoDevice);
                        }
                    }
                })
                .catch(error => {
                    setVideoDevices([])
                    setInputs({});
                });
        }
    }, []);
    
    function getVideoDeviceData(deviceName){
        axios.get(Constants.EDGE_API_ENDPOINT + "/devices/video/" + deviceName)
            .then(res => {
                if(res.data["data"]){
                    setVideoDeviceInfo(res.data);
                    setSelectedVideoDevice(deviceName)
                }
            })
    }

    const handleOnChange = (event) => {
        event.persist();
        if(event.target.name === "stack_name"){
            event.target.value = event.target.value.replace(/[^a-zA-Z0-9]/g, '');
        } else if(event.target.name === "description"){
            if(event.target.value.length > 255){
                return;
            }
        }
        setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
    }

    const handleVideoDeviceOnChange = (event) => {
        event.persist();
        setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
        getVideoDeviceData(event.target.value);
    }

    function isEmpty (val) {
        if ( val === undefined || val.trim() === "") {
            return true;
        } else {
            return false;
        }
    }

    function isIp(ip) {
        var arrIp = ip.split(".");
        if (arrIp.length !== 4) {
            return false;
        }
        for (let block of arrIp) {
            if (Number(block) < 0 && Number(block) > 255){
                return false;
            }
            if (block === "" || block === undefined || block.length > 3 ){
                return false
            }

        }
        return true
    }


    function validateStackName (stackName) {
        if (Object.keys(streamList).indexOf(stackName) !== -1) {
            swal({text:"Stream name already exist. Please try with another name.", icon: 'error'});
            return false;
        }

        if(isEmpty(stackName)) {
            swal({text:"Please enter stream name", icon: 'error'});
            return false;
        }
        return true;
    }

    function getDate(hours, minutes, seconds){
        let date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(seconds);
        return date;
    }

    function prepareRequestJson(jsonData, stackName) {
        if(source === "USB" && selectedVideoDevice === undefined){
            swal({text:"Video device not available.", icon: 'error'});
            return;
        }

        if (!validateStackName (stackName)){
            return;
        }

        delete jsonData["stack_name"];
        var services = {}
        jsonData["services"] = services;
        services["recorder1"] = Object.assign({}, recorderState);
        if(services["recorder1"].gpu_affinity === 'no_affinity' || services["recorder1"].gpu_affinity === 'system') {
            delete services["recorder1"].gpu_device;
        }
        if(!services["recorder1"].enable_hw_acceleration) {
            delete services["recorder1"].gpu_device;
            delete services["recorder1"].gpu_affinity;
        }
        if(source === "RTMP"){
            let streamKey = services["recorder1"]["rtmp_stream_key"];
            if((streamKey === "" || streamKey === undefined) && services["recorder1"]["rtmp_stream_key_type"] === "custom_rtmp_stream_key"){
                swal({text:"Please enter custom stream/Key", icon: 'error'})
                return;
            }
            if(isEmpty(services["recorder1"]["rtmp_source_url"])){
                swal({text:"Please enter server url", icon: 'error'});
                return;
            }
            let rtmpUrlFormat = /^(rtmp?):?\/\/./;
            if (!rtmpUrlFormat.test(services["recorder1"]["rtmp_source_url"])) {
                swal({text:"Server url is invalid", icon: 'error'});
                return;
            }
            if(services["recorder1"]["rtmp_server_type"] === "internal"){
                if(services["recorder1"]["rtmp_url_type"] === "mDNS"){
                    let mDnsName = ls.get("device_id") === undefined ? "" : ls.get("device_id") + ".local";
                    services["recorder1"]["rtmp_source_url"] = Constants.INTERNAL_RTMP_SERVER_ENDPOINT.replace("{}", mDnsName)
                } else {
                    let deviceIP = ls.get("device_ip") === undefined ? window.location.hostname : ls.get("device_ip");
                    services["recorder1"]["rtmp_source_url"] = Constants.INTERNAL_RTMP_SERVER_ENDPOINT.replace("{}", deviceIP)
                }
                if(services["recorder1"]["rtmp_stream_key"] === ""){
                    services["recorder1"]["rtmp_stream_key"] = stackName;
                }
            } else {
                delete services["recorder1"]["rtmp_stream_key_type"];
                delete services["recorder1"]["rtmp_url_type"];
            }
            delete jsonData["video_device"];
            services["recorder1"]["video_device"] = stackName.replace(/ /g, '_');
        } else if(source === "RTSP"){
            var rtspShortHeader = services["recorder1"]["rtsp_source_short_header"];
            var rtspUrlFormat = /^(rtsp|rtspt?):?\/\//
            if(recorderState["rtsp_source_url"] === undefined || recorderState["rtsp_source_url"] === '') {
                swal({text:"Please enter RTSP URL", icon: 'error'})
                return;
            } else {
                if(!rtspUrlFormat.test(recorderState["rtsp_source_url"])){
                    swal({text:"Invalid RTSP URL", icon: 'error'})
                    return;
                }
            }
            if(services["recorder1"]["rtsp_source_buffer_time"] === undefined || services["recorder1"]["rtsp_source_buffer_time"] ===""){
                swal({text:"Please enter buffer time", icon: 'error'})
                return;
            }

            if(rtspShortHeader === "true"){
                services["recorder1"]["rtsp_source_short_header"] = true;
            }else{
                services["recorder1"]["rtsp_source_short_header"] = false;
            }
            delete jsonData["video_device"];
            services["recorder1"]["video_device"] = stackName.replace(/ /g, '_');
        } else if(source === "USB"){
            if (services["recorder1"]["video_fps"] === "auto") {
                services["recorder1"]["video_fps"] = services["recorder1"]["video_fps"]
            } else {
                services["recorder1"]["video_fps"] = services["recorder1"]["video_fps"].replace("fps", "/1")
            }
            var resolution = services["recorder1"]["resolution"];
            var regEx = /\((.*?)\)/
            var arr = regEx.exec(resolution)[1].split("x")
            services["recorder1"]["video_width"] = arr[0];
            services["recorder1"]["video_height"] = arr[1];
            delete services["recorder1"]["resolution"];
            services["recorder1"]["video_device"] = jsonData["video_device"];
            if(Constants.AD_HW_VPU === 'NVIDIA' && services["recorder1"].video_source_type === 'UVC_H264') {
                delete services["recorder1"].gpu_device;
                delete services["recorder1"].gpu_affinity;
            }
            delete jsonData["video_device"];
        } else if(source === "RTP"){
            if(services["recorder1"]["enable_video_multicast"] === true){
                if(isEmpty(services["recorder1"]["video_multicast_address"])){
                swal({text:"Please enter video multicast address", icon: 'error'})
                return; 
                }
                if(!isIp(services["recorder1"]["video_multicast_address"])){
                    swal({text:"Please enter valid video multicast address", icon: 'error'})
                    return;
                }
            }
            if(isEmpty(services["recorder1"]["video_port_rtp"])){
                swal({text:"Please enter video RTP port", icon: 'error'})
                return;
            } 
            if(isEmpty(services["recorder1"]["video_port_rtcp"]) && services["recorder1"]["enable_video_rtcp_muxed"] === false){
                swal({text:"Please enter video RTCP port", icon: 'error'})
                return;
            }
            if(services["recorder1"]["enable_audio"] === true){
                if(services["recorder1"]["enable_audio_multicast"] === true){
                    if(isEmpty(services["recorder1"]["audio_multicast_address"])){
                        swal({text:"Please enter audio multicast address", icon: 'error'})
                        return; 
                        }
                    if(!isIp(services["recorder1"]["audio_multicast_address"])){
                        swal({text:"Please enter valid audio multicast address", icon: 'error'})
                        return;
                    }
                }
                if(isEmpty(services["recorder1"]["audio_port_rtp"])){
                    swal({text:"Please enter audio RTP port", icon: 'error'})
                    return;
                }
                if(isEmpty(services["recorder1"]["audio_port_rtcp"]) && services["recorder1"]["enable_audio_rtcp_muxed"] === false){
                    swal({text:"Please enter audio RTCP port", icon: 'error'})
                    return;
                }
            }

            let rtpPorts = []
            let video_port = services["recorder1"]["video_port_rtp"]
            let video_port_rtcp = services["recorder1"]["video_port_rtcp"]
            let audio_port = services["recorder1"]["audio_port_rtp"]
            let audio_port_rtcp = services["recorder1"]["audio_port_rtcp"]
            let inputPorts = [video_port, video_port_rtcp, audio_port, audio_port_rtcp]
            var port
            for (port of inputPorts) {
                if (port.length > 0){
                    rtpPorts.push(port)
                }
            }

            let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index)
            if (findDuplicates(rtpPorts).length > 0) {
                swal({text:"Duplicate ports entries not allowed", icon: 'error'});
                return;
            }
            if(Constants.AD_HW_VPU === 'NVIDIA' && services["recorder1"].transport_encapsulation === 'H264' || services["recorder1"].transport_encapsulation === 'MPEG2TS' || services["recorder1"].transport_encapsulation === 'MPEG2TTS') {
                delete services["recorder1"].gpu_device;
                delete services["recorder1"].gpu_affinity;
            }
            delete jsonData["video_device"];
            services["recorder1"]["video_device"] = stackName.replace(/ /g, '_');
        } else if(source === "FTP"){
            let port = services["recorder1"]["port"]
            if((port === "" || port === undefined) && services["recorder1"]["server_type"] === "external"){
                swal({text:"Please enter port", icon: 'error'})
                return;
            }
            let source_directory = services["recorder1"]["source_directory"];
            if((source_directory === "" || source_directory === undefined) && services["recorder1"]["source_directory_type"] === "custom_source_directory"){
                swal({text:"Please enter custom source directory", icon: 'error'})
                return;
            }
            let date_time_format = services["recorder1"]["date_time_format"]
            if((date_time_format === "" || date_time_format === undefined) && services["recorder1"]["ingest_time"] === "File Name"){
                swal({text:"Please enter date time format", icon: 'error'})
                return;
            }
            if(services["recorder1"]["server_type"] === "internal"){
                if(services["recorder1"]["url_type"] === "mDNS"){
                    let mDnsName = ls.get("device_id") === undefined ? "" : ls.get("device_id") + ".local";
                    services["recorder1"]["source_url"] = Constants.INTERNAL_FTP_SERVER_ENDPOINT.replace("{}", mDnsName)
                } else {
                    let deviceIP = ls.get("device_ip") === undefined ? window.location.hostname : ls.get("device_ip");
                    services["recorder1"]["source_url"] = Constants.INTERNAL_FTP_SERVER_ENDPOINT.replace("{}", deviceIP)
                }
                if(services["recorder1"]["source_directory"] === ""){
                    services["recorder1"]["source_directory"] = stackName;
                }
                services["recorder1"]["port"] = Constants.FTP_SERVER_PORT;
            } else {
                delete services["recorder1"]["source_directory_type"];
                delete services["recorder1"]["url_type"];
            }
            delete jsonData["video_device"];
            services["recorder1"]["video_device"] = stackName.replace(/ /g, '_');
 
            if(services["recorder1"]["enable_user_login"] === true){
                if(isEmpty(services["recorder1"]["username"])){
                    swal({text:`Please enter username`, icon: 'error'})
                    return;
                }
                if(isEmpty(services["recorder1"]["password"])){
                    swal({text:`Please enter password`, icon: 'error'})
                    return;
                }
            }
        } else if(source === "SAFIE"){
            services["recorder1"]["video_device"] = stackName;
            if(services["recorder1"]["capture_type"] === 'still_capture') {
                if(services["recorder1"]["still_capture_api"] === 'still') {
                    if(services["recorder1"]["image_pull_min"] === '00' && services["recorder1"]["image_pull_sec"] === '00' && services["recorder1"]["image_pull_hrs"] === '00') {
                        swal({text: "Please enter image pull interval >= 1 min" , icon: "error"});
                        return;
                    } else if(services["recorder1"]["image_pull_min"] === '00' && services["recorder1"]["image_pull_sec"] !== '00' && (services["recorder1"]["image_pull_hrs"] === '00' || services["recorder1"]["image_pull_hrs"] === '00' )) {
                        swal({text: "Please enter image pull interval >= 1 min" , icon: "error"});
                        return;
                    }
                } else if(services["recorder1"]["still_capture_api"] === 'thumbnail') {
                    if(services["recorder1"]["image_pull_min"] === '00' && services["recorder1"]["image_pull_sec"] === '00' && services["recorder1"]["image_pull_hrs"] === '00') {
                        swal({text: "Please enter image pull interval >= 30 sec" , icon: "error"});
                        return;
                    } else if(services["recorder1"]["image_pull_min"] === '00' && services["recorder1"]["image_pull_sec"] < '30' && (services["recorder1"]["image_pull_hrs"] === '00' || services["recorder1"]["image_pull_hrs"] === '00' )) {
                        swal({text: "Please enter image pull interval >= 30 sec" , icon: "error"});
                        return;
                    }
                } else if(services["recorder1"]["still_capture_api"] === 'live') {
                    if(services["recorder1"]["image_pull_min"] === '00' && services["recorder1"]["image_pull_sec"] === '00' && services["recorder1"]["image_pull_hrs"] === '00') {
                        swal({text: "Please enter image pull interval >= 30 sec" , icon: "error"});
                        return;
                    } else if(services["recorder1"]["image_pull_min"] === '00' && services["recorder1"]["image_pull_sec"] < '30' && (services["recorder1"]["image_pull_hrs"] === '00' || services["recorder1"]["image_pull_hrs"] === '00' )) {
                        swal({text: "Please enter image pull interval >= 30 sec" , icon: "error"});
                        return;
                    }
                }
            }
            
        } else {
            swal({text:"Unsupported source: " + source, icon: 'error'});
            return;
        }
        if(services["recorder1"]["enable_save_mp4"] === true){
            if(services["recorder1"]["video_split_mode"] === 'size'){
              if(services["recorder1"]["video_file_max_size"] > splitIntervalMaxVal) {
                swal({text:`Please enter split interval value less than ${splitIntervalMaxVal}MiB`, icon: 'error'})
                return;
              } else if(services["recorder1"]["video_file_max_size"] === "") {
                swal({text:"Please enter mp4 split interval value in MiB", icon: 'error'})
                return;
              } else {
                services["recorder1"]["video_file_max_size"] = services["recorder1"]["video_file_max_size"]*(1024*1024) + "";
              }
            } else if(services["recorder1"]["video_split_mode"] === 'time'){
              if(services["recorder1"]["video_duration"] === undefined || services["recorder1"]["video_duration"] ===""){
                swal({text:"Please enter mp4 split interval value in sec(s)", icon: 'error'})
                return;
              }
              // Save default video_file_max_size in bytes
              if("video_file_max_size" in services["recorder1"]){
                  if(services["recorder1"]["video_file_max_size"] === '') {
                      services["recorder1"]["video_file_max_size"] = 50*(1024*1024) + "";
                  } else  {
                      services["recorder1"]["video_file_max_size"] = services["recorder1"]["video_file_max_size"]*(1024*1024) + "";
                  }
              }
            }
          } else {
            // Save default video_file_max_size in bytes
              if("video_file_max_size" in services["recorder1"]){
                services["recorder1"]["video_file_max_size"] = services["recorder1"]["video_file_max_size"]*(1024*1024) + "";
              }
          }

        if(services["recorder1"]["save_interval"] === undefined || services["recorder1"]["save_interval"] ===""){
            swal({text:"Please enter snapshot save interval", icon: 'error'})
            return;
        }

        var videoDevice = services["recorder1"]["video_device"]
        if(isEmpty(videoDevice)){
            swal({text:"Video device is mandatory", icon: "error"});
            return;
        }

        /*if(analysisState != undefined){
            if(analysisState["inactivity_timeout"] === undefined || analysisState["inactivity_timeout"] ===""){
                swal({text:"Please enter inactivity timeout for algorithm")
                return;
            }
            if(analysisState["type"] == "cascade_tracker"){
                services["cascade_tracker1"] = analysisState;
            }else if(analysisState["type"] == "hog_tracker"){
                services["hog_tracker1"] = analysisState;
            }
        }*/

        if(broadcastingState !== undefined){
            if(broadcastingState["protocol"] === "rtmp"){
                let rtmpUrlFormat = /^(rtmp?):?\/\//
                if(isEmpty(broadcastingState["rtmp_url"])) {
                    swal({text:'Please enter stream url', icon: 'error'})
                    return;
                } else {
                    if(!rtmpUrlFormat.test(broadcastingState["rtmp_url"])){
                        swal({text:"Invalid stream url", icon: 'error'})
                        return;
                    }
                }
                if(isEmpty(broadcastingState["stream_key"])){
                    broadcastingState["stream_key"] = "";
                }
                services["rtmp_broadcaster1"] = {
                    "type": "rtmp_broadcaster",
                    "rtmp_url": broadcastingState["rtmp_url"],
                    "stream_key": broadcastingState["stream_key"]
                }
            } else if(broadcastingState["protocol"] === "rtp"){
                if(isEmpty(broadcastingState["rtp_server_ip"])){
                    swal({text:"Please enter server ip", icon: 'error'})
                    return;
                }
                if(isEmpty(broadcastingState["rtp_video_udp_port"])){
                    swal({text:"Please enter video port", icon: 'error'})
                    return;
                }
                services["rtp_broadcaster1"] = {
                    "type": "rtp_broadcaster",
                    "rtp_server_ip": broadcastingState["rtp_server_ip"],
                    "rtp_video_udp_port": broadcastingState["rtp_video_udp_port"],
                    "rtp_audio_udp_port": broadcastingState["rtp_audio_udp_port"]
                }
            } else if(broadcastingState["protocol"] === "kinesis"){
                if(isEmpty(broadcastingState["kinesis_streamer_stream_name"])) {
                    swal({text:"Please enter stream name", icon: 'error'})
                    return;
                }
                if(isEmpty(broadcastingState["kinesis_streamer_access_key_id"])) {
                    swal({text:"Please enter access key", icon: 'error'})
                    return;
                }
                if(isEmpty(broadcastingState["kinesis_streamer_secret_access_key"])) {
                    swal({text:"Please enter secret key", icon: 'error'})
                    return;
                }
                services["kinesis_streamer1"] = {
                    "type": "kinesis_streamer",
                    "kinesis_streamer_access_key_id": broadcastingState["kinesis_streamer_access_key_id"],
                    "kinesis_streamer_secret_access_key": broadcastingState["kinesis_streamer_secret_access_key"],
                    "kinesis_streamer_stream_name": broadcastingState["kinesis_streamer_stream_name"]
                }
            } else if(broadcastingState["protocol"] === "fog"){
                if(broadcastingState["destinations"].length === 0) {
                    swal({text:"Please add destination", icon: 'error'});
                    return;
                }
                if(isEmpty(broadcastingState["stream_id"])) {
                    swal({text:"Please enter stream id", icon: 'error'});
                    return;
                }
                if(broadcastingState["frame_rate_logging"] === true){
                    if(isEmpty(broadcastingState["frame_rate_logging_interval"])) {
                        swal({text:"Please enter frame rate logging interval", icon: 'error'});
                        return;
                    }
                    if(parseFloat(broadcastingState["frame_rate_logging_interval"])===0.0) {
                        swal({text:"frame rate logging interval should be > 0", icon: 'error'});
                        return;
                    }
                } else if(broadcastingState["frame_rate_logging"] === false){
                    if(parseFloat(broadcastingState["frame_rate_logging_interval"])===0.0){
                        broadcastingState["frame_rate_logging_interval"] = ""
                    }
                }
                if(isEmpty(broadcastingState["emit_interval"])) {
                    swal({text:"Please enter emit interval", icon: 'error'});
                    return;
                }
                if(isEmpty(broadcastingState["watchdog_timeout"])) {
                    swal({text:"Please enter watchdog timeout", icon: 'error'});
                    return;
                }
                if(parseInt(broadcastingState["watchdog_timeout"])< 0) {
                    swal({text:"Watchdog timeout should be >= 0", icon: 'error'});
                    return;
                }
                let fogBroadcasterService = Object.assign({}, broadcastingState);
                delete fogBroadcasterService["protocol"];
                delete fogBroadcasterService["destination_text"];
                if(fogBroadcasterService.stream_id === "auto"){
                    fogBroadcasterService["stream_id"] = stackName;
                }
                services["fog_broadcaster1"] = fogBroadcasterService;
            }
        }

        if(streamForwardingState !== undefined){
            if(streamForwardingState["destinations"].length === 0) {
                swal({text:"Please add destination", icon: 'error'});
                return;
            }
            if(streamForwardingState["sf_frame_rate_logging"] === true){
                if(isEmpty(streamForwardingState["frame_rate_logging_interval"])) {
                    swal({text:"Please enter frame rate logging interval", icon: 'error'});
                    return;
                }
                if(parseFloat(streamForwardingState["frame_rate_logging_interval"])===0.0) {
                    swal({text:"frame rate logging interval should be > 0", icon: 'error'});
                    return;
                }
            } else if(streamForwardingState["sf_frame_rate_logging"] === false){
                if(parseFloat(streamForwardingState["frame_rate_logging_interval"])===0.0){
                    streamForwardingState["frame_rate_logging_interval"] = ""
                }
            }
            if(isEmpty(streamForwardingState["emit_interval"])) {
                swal({text:"Please enter emit interval", icon: 'error'});
                return;
            }
            if(isEmpty(streamForwardingState["watchdog_timeout"])) {
                swal({text:"Please enter watchdog timeout", icon: 'error'});
                return;
            }
            if(parseInt(streamForwardingState["watchdog_timeout"])< 0) {
                swal({text:"Watchdog timeout should be >= 0", icon: 'error'});
                return;
            }
            let streamForwarderService = Object.assign({}, streamForwardingState);
            streamForwarderService["frame_rate_logging"] = streamForwarderService["sf_frame_rate_logging"];
            streamForwarderService["endpoint_type"] = streamForwarderService["sf_endpoint_type"];
            delete streamForwarderService["sf_frame_rate_logging"];
            delete streamForwarderService["sf_endpoint_type"];
            delete streamForwarderService["destination_text"];
            services["stream_forwarder1"] = streamForwarderService;
        }

        if(captureScheduleState !== undefined && captureDescreteState !== undefined){
            let weekdays = "";
            Object.keys(Constants.WEEK_DAYS).map((week_day) => {
                if(captureScheduleState[week_day] === true) {
                    weekdays += ((Constants.WEEK_DAYS[week_day]) + ",");
                }
            })
            weekdays = weekdays.replace(/,+$/, "");
            if(captureScheduleState.enabled){
                if(weekdays === ""){
                    swal({text:"Please select capture schedule day.", icon: 'error'});
                    return;
                }
            }

            let startTimeArr = captureScheduleState.start_time.split(':');
            let endTimeArr = captureScheduleState.end_time.split(':')
            let startTime = getDate(parseInt(startTimeArr[0]), parseInt(startTimeArr[1]), 0);
            let endTime = getDate(parseInt(endTimeArr[0]), parseInt(endTimeArr[1]), 0);
            let scheduleInSeconds = (endTime.getTime() - startTime.getTime())/1000;
            if(captureDescreteState.enabled){
                let intervalHours = parseInt(captureDescreteState.interval_hours);
                let intervalMinutes = parseInt(captureDescreteState.interval_minutes);
                let intervalSeconds = parseInt(captureDescreteState.interval_seconds);
                if( intervalHours === 0 && intervalMinutes === 0 && intervalSeconds === 0){
                    swal({text:"Please select discrete capture interval.", icon: 'error'});
                    return;
                }
                let intervalInSeconds = (intervalHours * 60 * 60) + (intervalMinutes * 60) + intervalSeconds;
                if(intervalInSeconds > scheduleInSeconds){
                    swal({text:"Discrete capture interval should be less than or equal to schedule duration.", icon: 'error'});
                    return;
                } else {
                    let captureHours = parseInt(captureDescreteState.capture_hours);
                    let captureMinutes = parseInt(captureDescreteState.capture_minutes);
                    let captureSeconds = parseInt(captureDescreteState.capture_seconds);
                    if( captureHours === 0 && captureMinutes === 0 && captureSeconds === 0){
                        swal({text:"Please select capture duration.", icon: 'error'});
                        return;
                    }
                    let captureDurationInSeconds = (captureHours * 60 * 60) + (captureMinutes * 60) + captureSeconds;
                    if(captureDurationInSeconds > intervalInSeconds){
                        swal({text:"Capture duration should be less than or equal to interval.", icon: 'error'});
                        return;
                    }
                }
            }

            jsonData["capture_schedule"] = {}
            jsonData["capture_schedule"]["enabled"] = captureScheduleState["enabled"]
            jsonData["capture_schedule"]["start_time"] = captureScheduleState["start_time"]
            jsonData["capture_schedule"]["end_time"] = captureScheduleState["end_time"]
            jsonData["capture_schedule"]["weekdays"] = weekdays
            jsonData["capture_schedule"]["discrete_capture"] = captureDescreteState
        }

        return jsonData
    }

    const cancelAddStream = (event) => {
        window.history.back();
    }

    const handleSubmit = (event) => {
        if(event)
            event.preventDefault();

        var jsonData = Object.assign({}, inputs)
        
        var stackName = jsonData["stack_name"];

        jsonData = prepareRequestJson(jsonData, stackName)
        if(jsonData===undefined){
            return;
        }
        axios.put(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName + "/all", jsonData)
        .then(res => {
            props.history.push("./stream-viewer");
        })
        .catch(error => {
            if(error.response){
                var errorObj = error.response.data;
                swal({text:"Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, icon: "error"});
            }else{
                swal({text: "Unable to connect to the edge-api service" , icon: "error"});
            }
        });
    }

    const snapshotIntervalCallback = (intervalTime) => {
        setInputs({...inputs, ["snapshot_refresh_interval"]: intervalTime});
    }

    const deviceListCheckCallBack = (isList) => {
        setCameraOptions(isList);
    }

    const isSafieConfigurCallBack = (configured) => {
        setConfigure(configured);
    }

    const recorderStateCallback = (stateData) => {
        recorderState = stateData;
    }

    /*const analysisStateCallback = (stateData) => {
        analysisState = stateData
    }*/

    const broadcastingStateCallback = (stateData) => {
        broadcastingState = stateData
    }

    const streamForwardingStateCallback = (stateData) => {
        streamForwardingState = stateData
    }

    const captureScheduleStateCallback = (stateData1, stateData2) => {
        captureScheduleState = stateData1
        captureDescreteState = stateData2
    }

    const handleToggleChange = (value, event) => {
        setInputs({...inputs, [event.target.id]: value});
    };

    return (
            <ContentDiv>
                <Wrapper>
                    <SectionHeader>
                        <SectionLabel>Stream Information</SectionLabel>
                        <RightDiv>
                            <SelectionControl id="enabled" type="switch" label={inputs.enabled? "Enabled" :"Disabled"} name="enabled" labelBefore="true" checked={inputs.enabled || false} onChange={handleToggleChange}/>
                        </RightDiv>
                    </SectionHeader>
                    <Row>
                        <Column>
                           <MandatoryLabelStream>Stream Name</MandatoryLabelStream>
                           <TextField name="stack_name" type="text" value={inputs.stack_name} onChange={handleOnChange} maxLength= '63' />
                            <Label style={{"padding": "10px 0px 0px 0px"}}>Description</Label>
                            <TextArea name="description" rows="1" value={inputs.description} onChange={handleOnChange}/>
                        </Column>
                    </Row>
                    { source === "USB" &&
                        <Column>
                            <div>
                            <Label>Video Device</Label>
                                <SelectField name="video_device" value={inputs.video_device} onChange={handleVideoDeviceOnChange}>
                                { videoDevices && videoDevices.map((item) => {
                                    let videoDescription = item.description ? ' (' + item.description + ')' : '';
                                    return (<option key={item.name} value={item.name}>{item.name + videoDescription}</option>)
                                    }
                                )}
                                </SelectField>
                            {isMobileBrowser ? 
                           <div style={{'display':'flex', 'justifyContent':'space-between', width:'100%'}}>
                                <SelectionControl style={{"padding": "50px 0px 0px 0px"}} id="rtsp_enabled" type="switch" label={inputs.rtsp_enabled? "RTSP Enabled" :"RTSP Disabled"} name="rtsp_enabled" labelBefore="true" checked={inputs.rtsp_enabled || false} onChange={handleToggleChange}/>
                                <SelectionControl style={{"padding": "50px 0px 0px 0px"}} id="webrtc_enabled" type="switch" label={inputs.webrtc_enabled? "WebRTC Enabled" :"WebRTC Disabled"} name="webrtc_enabled" labelBefore="true" checked={inputs.webrtc_enabled || false} onChange={handleToggleChange} disabled={true}/>
                           </div>:
                            <>
                            <LeftDiv>
                                <SelectionControl style={{"padding": "50px 0px 0px 0px"}} id="rtsp_enabled" type="switch" label={inputs.rtsp_enabled? "RTSP Enabled" :"RTSP Disabled"} name="rtsp_enabled" labelBefore="true" checked={inputs.rtsp_enabled || false} onChange={handleToggleChange}/>
                            </LeftDiv>
                            <RightDiv>
                                <SelectionControl style={{"padding": "50px 0px 0px 0px"}} id="webrtc_enabled" type="switch" label={inputs.webrtc_enabled? "WebRTC Enabled" :"WebRTC Disabled"} name="webrtc_enabled" labelBefore="true" checked={inputs.webrtc_enabled || false} onChange={handleToggleChange} disabled={true}/>
                            </RightDiv></>}
                        </div>
                        </Column>
                    }
                    { source === "RTSP" &&
                        <Column>
                        <Row>
                        <LeftDiv>
                            <SelectionControl style={{"padding": "40px 0px 0px 0px"}} id="rtsp_enabled" type="switch" label={inputs.rtsp_enabled? "RTSP Enabled" :"RTSP Disabled"} name="rtsp_enabled" labelBefore="true" checked={inputs.rtsp_enabled || false} onChange={handleToggleChange}/>
                        </LeftDiv>
                        </Row>
                        <Row>
                        <LeftDiv>
                            <SelectionControl style={{"padding": "80px 0px 0px 0px"}} id="webrtc_enabled" type="switch" label={inputs.webrtc_enabled? "WebRTC Enabled" :"WebRTC Disabled"} name="webrtc_enabled" labelBefore="true" checked={inputs.webrtc_enabled || false} onChange={handleToggleChange} disabled={true}/>
                        </LeftDiv>
                        </Row>
                        </Column>

                    }
                    { source === "RTMP" &&
                        <Column>
                        <Row>
                        <LeftDiv>
                            <SelectionControl style={{"padding": "40px 0px 0px 0px"}} id="rtsp_enabled" type="switch" label={inputs.rtsp_enabled? "RTSP Enabled" :"RTSP Disabled"} name="rtsp_enabled" labelBefore="true" checked={inputs.rtsp_enabled || false} onChange={handleToggleChange}/>
                        </LeftDiv>
                        </Row>
                        <Row>
                        <LeftDiv>
                            <SelectionControl style={{"padding": "80px 0px 0px 0px"}} id="webrtc_enabled" type="switch" label={inputs.webrtc_enabled? "WebRTC Enabled" :"WebRTC Disabled"} name="webrtc_enabled" labelBefore="true" checked={inputs.webrtc_enabled || false} onChange={handleToggleChange} disabled={true}/>
                        </LeftDiv>
                        </Row>
                        </Column>

                    }
                    { source === "RTP" &&
                        <Column>
                        <Row>
                        <LeftDiv>
                            <SelectionControl style={{"padding": "40px 0px 0px 0px"}} id="rtsp_enabled" type="switch" label={inputs.rtsp_enabled? "RTSP Enabled" :"RTSP Disabled"} name="rtsp_enabled" labelBefore="true" checked={inputs.rtsp_enabled || false} onChange={handleToggleChange}/>
                        </LeftDiv>
                        </Row>
                        <Row>
                        <LeftDiv>
                            <SelectionControl style={{"padding": "80px 0px 0px 0px"}} id="webrtc_enabled" type="switch" label={inputs.webrtc_enabled? "WebRTC Enabled" :"WebRTC Disabled"} name="webrtc_enabled" labelBefore="true" checked={inputs.webrtc_enabled || false} onChange={handleToggleChange} disabled={true}/>
                        </LeftDiv>
                        </Row>
                        </Column>

                    }
                </Wrapper>
                <Spacer/>
                <Wrapper>
                    <Tabs defaultTab="recordingTab">
                        <TabList>
                            <Tab tabFor="recordingTab" className={`rwt__tab`}>Recording</Tab>
                            {/* <Tab tabFor="analysisTab" className={`rwt__tab`}>Analysis</Tab> */}
                            <Tab tabFor="broadcastingTab" className={`rwt__tab`}>Broadcasting</Tab>
                            <Tab tabFor="streamForwardingTab" className={`rwt__tab`}>Stream Forwarding</Tab>
                            <Tab tabFor="captureScheduleTab" className={`rwt__tab`}>Capture Schedule</Tab>
                        </TabList>
                        { ((source ==="USB" && selectedVideoDevice !== undefined) || source === "RTSP" || source === "FTP" || source === "RTMP" || source === "RTP" || source === "SAFIE") &&
                        <div>
                        <RecordingTab parentCallback={recorderStateCallback} isList={deviceListCheckCallBack} isConfigue={isSafieConfigurCallBack} intervalCallback={snapshotIntervalCallback} stackName={inputs.stack_name} data={recorderState} data2={videoDeviceInfo} videoDevice={selectedVideoDevice} readOnly={false}/>
                        {/* <AnalysisTab parentCallback={analysisStateCallback} data={analysisState} readOnly={false}/> */}
                        <BroadcastingTab parentCallback={broadcastingStateCallback} data={broadcastingState} readOnly={false} source={source}/>
                        <StreamForwardingTab parentCallback={streamForwardingStateCallback} data={streamForwardingState} readOnly={false}/>
                        <CaptureScheduleTab parentCallback={captureScheduleStateCallback} data={captureScheduleState} readOnly={false}/>
                        </div>
                        }
                    </Tabs>
                </Wrapper>
                <ButtonWrapper>
                    <Button id='btndisabled' primary onClick={handleSubmit} disabled={source === "SAFIE" ? (!configure ? true : (cameraOptions ? false : true)) : false}>Submit</Button>
                    <Button id="cancelButton" onClick={cancelAddStream}>Cancel</Button>
                </ButtonWrapper>
            </ContentDiv>
            )
}

export default AddStream;
