import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { generateRandomPassword } from '../utils/Utils';
import styled from 'styled-components';
import * as Constants from '../utils/Constants';
import QRCode from 'qrcode.react';
import ls from 'local-storage';

const LabelBold = styled.label`
  margin-bottom:5px;
  color: #A8B5BD;
  line-height: 1.0em;
  font-size: 1.1em;
`;

const QRCodes = () => {
  const [value, setValue] = useState();
  const [collapase, setCollapse] = useState(false);
  const [showwifi, setShowWifi] = useState(false);

  function handleCollapse(){
    setCollapse(true);
    localStorage.setItem('collapase', 'true');
  }
  function showWiFiQRLabel(){
    setCollapse(false);
    localStorage.setItem('collapase', 'false');
  }
  useEffect(()=>{
    const isCollapase = localStorage.getItem('collapase');
    setCollapse(isCollapase === 'true');
  },[]);

  useEffect(() =>{
    axios.get(Constants.EDGE_API_ENDPOINT + '/system/network/brcm/all')
      .then(res =>{
        let brcmData = res.data.data;
        if(brcmData.brcm_enabled === true){
          if(Object.keys(brcmData.ap_wifi_type).length === 2) {
            setShowWifi(true);
          } else if(brcmData.ap_wifi_type[0] === 'wifi_client') {
            setShowWifi(false);
          }  else if(brcmData.ap_wifi_type[0] === 'access_point') {
            setShowWifi(true);
          }
        } else {
          setShowWifi(false);
        }
        if("device_id" in res.headers){
          ls.set("device_id", res.headers["device_id"]);
        }
        if('password' in brcmData && brcmData.password === ''){
          return;
        }
        if('ssid' in brcmData && brcmData.ssid === 'default'){
          brcmData.ssid = ls.get('device_id') === undefined ? '' : ls.get('device_id');
        }
        
        setValue(`WIFI:S:${brcmData.ssid};T:${brcmData.auth_mode};P:${brcmData.password};;`);
      })
      .catch(err => {
        console.log(err);
      });
    }, []);

    return(
      <div>
        {showwifi &&
        <>
        {value !== undefined ? 
          <div>
            {collapase && <div style={{'cursor': 'pointer', 'margin-left': window.innerWidth >= 1920 ? '25px' : (window.innerWidth >= 1366 ? '12px' : '5px')}} onClick={showWiFiQRLabel}> <QRCode value={value} includeMargin size={window.innerWidth >= 1920 ? 140 : (window.innerWidth >= 1366 ? 110 : 90)} /> </div>}
            {collapase || <LabelBold style={{'cursor': 'pointer', 'margin-left': window.innerWidth >= 1920 ? '60px' : (window.innerWidth >= 1366 ? '30px' : '20px') }} onClick={handleCollapse}>WiFi QR</LabelBold> }
          </div>
        : <div />}
        </>}
        
      </div>
    );
};

export default QRCodes;