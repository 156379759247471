import React, { Component, useState, useEffect } from 'react';
import { TabPanel } from 'react-web-tabs';
import axios from 'axios';
import { Label, Label3, Spacer, TextField, SelectField, Row, Column, LeftDiv, RightDiv, MandatoryLabelStream, MandatoryLabelBroadcaster, ImageIcon, TextFieldLabel, Button, ButtonSmall, ContentSpacer } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import SelectOptions from '../components/SelectOptions';
import swal from "sweetalert";
import { List, ListItem, SelectionControl, Slider } from 'react-md';
import styled from 'styled-components';

const HR = styled.hr`
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
`;

const BroadcastingTab = (props) => {
    const [readOnly, setReadOnly] = useState(props.readOnly);
    const [isMobileBrowser, setIsMobileBrowser] = useState(false);
    const [protocolDropDownReadOnly, setProtocolDropDownReadOnly] = useState(props.readOnly);
    var isViewPage = props.readOnly;
    const [inputs, setInputs] = useState();
    var initialValues = props.data.data;
    var stackName = props.stackName;
    var stackStatus = props.stackStatus
    var serviceName = props.data.service_name;
    const source = props.source;
    // Update parent with inputs value 
    props.parentCallback(inputs);

    useEffect(()=>{
        var isMobileBrowser = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if(isMobileBrowser) {
        setIsMobileBrowser(isMobileBrowser);
        } else {
        setIsMobileBrowser(isMobileBrowser);
        }
    },[]);

    useEffect(() => {
        if(isViewPage){
            setInputs(initialValues);
        }
    }, [initialValues != undefined]);

    function validateEndpoints(){
        let destArr = inputs["destinations"];
        for(var i=0; i<destArr.length;i++){
            if(destArr[i].includes("://*")){
                swal({text: "Endpoint type 'connect' can not have *"});
                return false;
            }
        }
        return true;
    }

    const handleOnChange = (event) => {
        if(event.target.name === "endpoint_type" && event.target.value === "connect"){
            if(!validateEndpoints()){
                return;
            }
        }
        event.persist();
        if(event.target.name === "rtmp_url"){
            let rtmpurl = event.target.value
            if (rtmpurl.length > 255){
                swal({text: "The text entered exceeds the maximum length"});
                return;
            }
        }

        if(event.target.name === "protocol"){
            if(event.target.value === "fog"){
                setInputs(Constants.FOG_BROADCASTER_DEFAULT_VALUES);
            } else if(event.target.value === "rtp"){
                setInputs(Constants.BROADCASTING_RTP_DEFAULT_VALUES);
            } else {
                let data = {...inputs, [event.target.name]: event.target.value};
                setInputs(data);
            }
        } else {
            if(event.target.name === "stream_id" || event.target.name === "stream_key" ||
                event.target.name === "kinesis_streamer_secret_access_key" ||
                event.target.name === "kinesis_streamer_access_key_id"){
                event.target.value = event.target.value.replace(" ", "")
            }
            let data = {...inputs, [event.target.name]: event.target.value};
            setInputs(data);
        }
    }

    const handleIntegerOnChange = (event) => {
        event.persist();
        let regEx = /^[1-9][0-9]*$/
        if(regEx.exec(event.target.value)){
            setInputs({...inputs, [event.target.name]: event.target.value});
        } else {
            if(event.target.value == "" || event.target.value == "0"){
                setInputs({...inputs, [event.target.name]: event.target.value});
            }
        }
    }

    const handleFloatOnChange = (event) => {
        event.persist();
        let regEx = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/
        if(regEx.exec(event.target.value)){
            setInputs({...inputs, [event.target.name]: event.target.value});
        } else {
            let dotIndex = event.target.value.indexOf(".");
            if(event.target.value === ""){
                setInputs({...inputs, [event.target.name]: event.target.value});
            } else if(dotIndex > 0 && dotIndex ===  event.target.value.length-1){
                setInputs({...inputs, [event.target.name]: event.target.value});
            } else if(dotIndex === 0 && event.target.value.length === 1){
                setInputs({...inputs, [event.target.name]: event.target.value});
            }
        }
    }

    const handlePortOnChange = (event) => {
        event.persist();
        let regEx = /^[1-9][0-9]*$/
        if(regEx.exec(event.target.value)){
            setInputs({...inputs, [event.target.name]: event.target.value});
        } else {
            if(event.target.value == "" || event.target.value == "0"){
                setInputs({...inputs, [event.target.name]: ""});
            }
        }
    }

    const addBroadcaster = (event) => {
        event.persist();
        if(source === 'FTP' || source === 'SAFIE') {
            setInputs(Constants.FOG_BROADCASTER_DEFAULT_VALUES);
        } else {
            setInputs(Constants.BROADCASTING_DEFAULT_VALUES.data);
        }
        setProtocolDropDownReadOnly(false);
        setReadOnly(false);
    }

    function deleteBroadcasterRequest () {
        axios.delete(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName + "/" + serviceName)
            .then(res => {
                setInputs(undefined);
                swal("Broadcaster deleted successfully.", { icon: "success"});
                setTimeout(function(){ window.location.reload() }, 2000);
            })
            .catch(error => {
                if(error.response){
                    var errorObj = error.response.data;
                    swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
                }else{
                    swal({text: "Unable to connect to the edge-api service" , icon: "error"});
                }
            });
    }

    const deleteBroadcaster = (event) => {
        event.persist();
        if(isViewPage && initialValues!=undefined){
            swal({
                text: "Are you sure you want to delete this broadcaster?",
                buttons: ['No', 'Yes'],
                dangerMode: true,
            })
            .then((willDelete) => {
                if (willDelete) {
                    deleteBroadcasterRequest ()
                }
            });

        } else {
            setInputs(undefined);
        }
    }

    const editBroadcaster = (event) => {
        setReadOnly(false);
        //setProtocolDropDownReadOnly(false);
    }

    const cancelBroadcaster = (event) => {
        window.location.reload()
    }

   const addDestination = (event) => {
        if(inputs["destination_text"] === undefined){
            return;
        }

        if(inputs["endpoint_type"] === "connect" && inputs["destination_text"].includes("://*")){
            swal({text: "Endpoint type 'connect' can not have *"});
            return;
        }

        let destinationsList = inputs["destinations"];
        if(destinationsList === undefined){
            destinationsList = [];
        }

        let destinationText = inputs["destination_text"].trim();
        if(destinationsList.indexOf(destinationText) !== -1){
            swal({text: "Destination already exists"});
            return;
        }

        if(destinationText.startsWith("tcp://") ||
           destinationText.startsWith("ipc://") ||
           destinationText.startsWith("pgm://") ||
           destinationText.startsWith("epgm://"))
       {
            destinationsList.push(destinationText);
            setInputs({...inputs, ["destinations"]: destinationsList});
            setInputs({...inputs, ["destination_text"]: ""});
       } else {
           swal({text: "Destination must be starts with tcp://, ipc://, pgm://, epgm://"});
       }
    }

    const deleteDestination = (event) => {
        if(!readOnly){
            let index = event.target.getAttribute("data");
            let destArr = inputs["destinations"];
            destArr.splice(index, 1);
            setInputs({...inputs, ["destinations"]: destArr});
        }
    }
    
    const handleToggleChange = (value, event) => {
        if(!readOnly){
            setInputs({...inputs, [event.target.id]: value});
            if(event.target.id == "network_mode") {
                if (value == true){
                    setInputs({...inputs, [event.target.id]: "host"});
                } else {
                    setInputs({...inputs, [event.target.id]: ""});
                }
            }
        }
    };

    const handleRadioChange = (event) => {
        event.persist();
        let data = { ...inputs };
        data[event.target.name] = event.target.value;
        setInputs(data);
      };

    const handleSliderChange = (value, event) => {
        setInputs({...inputs, ["compression_quality"]: value});
    };

    function isEmpty(val){
        if(val == undefined || val.trim() == "") {
            return true;
        }else{
            return false;
        }
    }

    function validIP(ip) {
        const re = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        return re.test(ip);
    }

    const saveBroadcaster = (event) => {
        var broadcasterJson = Object.assign({}, inputs);
        broadcasterJson["enabled"] = stackStatus
        if(broadcasterJson["protocol"] == "rtmp"){
            var rtmpUrlFormat = /^(rtmp?):?\/\//
            if (!rtmpUrlFormat.test(broadcasterJson["rtmp_url"])) {
                if(isEmpty(broadcasterJson["rtmp_url"])){
                    swal({text:"Please enter stream url", icon: "error"})
                }else{
                    swal({text:"Invalid stream url", icon: "error"})
                }
                return;
            }
            if(isEmpty(broadcasterJson["stream_key"])) {
                broadcasterJson["stream_key"] = "";
            }
            broadcasterJson["type"] = "rtmp_broadcaster";
        } else if(broadcasterJson["protocol"] == "rtp"){
            if(isEmpty(broadcasterJson["rtp_server_ip"])) {
                swal({text:"Please enter server ip", icon: "error"});
                return;
            } else if (!validIP(broadcasterJson["rtp_server_ip"])) {
                swal({text:"Please enter valid server ip", icon: "error"});
                return;
            }
            if(isEmpty(broadcasterJson["rtp_video_udp_port"])) {
                swal({text:"Please enter video port", icon: "error"});
                return;
            }
           broadcasterJson["type"] = "rtp_broadcaster";
        }else if(broadcasterJson["protocol"] == "kinesis"){
            if(isEmpty(broadcasterJson["kinesis_streamer_stream_name"])) {
                swal({text:"Please enter kinesis stream name", icon: "error"});
                return;
            }
            if(isEmpty(broadcasterJson["kinesis_streamer_access_key_id"])) {
                swal({text:"Please enter kinesis access key", icon: "error"});
                return;
            }
            if(isEmpty(broadcasterJson["kinesis_streamer_secret_access_key"])) {
                swal({text:"Please enter kinesis secret key", icon: "error"});
                return;
            }
           broadcasterJson["type"] = "kinesis_streamer";
        } else if(broadcasterJson["protocol"] == "fog"){
            if(broadcasterJson["destinations"].length === 0) {
                swal({text:"Please add destination", icon: "error"})
                return;
            }
            if(isEmpty(broadcasterJson["stream_id"])) {
                swal({text:"Please enter stream id", icon: "error"});
                return;
            }
            if(broadcasterJson["frame_rate_logging"] === true){
                if(isEmpty(broadcasterJson["frame_rate_logging_interval"])) {
                    swal({text:"Please enter frame rate logging interval", icon: "error"});
                    return;
                }
                if(parseFloat(broadcasterJson["frame_rate_logging_interval"])===0.0) {
                    swal({text:"frame rate logging interval should be > 0", icon: "error"});
                    return;
                }
            }
            else if(broadcasterJson["frame_rate_logging"] === false){
                if(parseFloat(broadcasterJson["frame_rate_logging_interval"])===0.0){
                    broadcasterJson["frame_rate_logging_interval"] = ""
                }

            }
            if(isEmpty(broadcasterJson["emit_interval"])) {
                swal({text:"Please enter emit interval", icon: "error"});
                return;
            }
            if(isEmpty(broadcasterJson["watchdog_timeout"])) {
                swal({text:"Please enter watchdog timeout", icon: "error"});
                return;
            }
            if(parseInt(broadcasterJson["watchdog_timeout"])< 0) {
                swal({text:"Watchdog timeout should be >= 0", icon: "error"});
                return;
            }
            if(broadcasterJson.stream_id === "auto"){
                broadcasterJson["stream_id"] = stackName;
            }
            delete broadcasterJson["destination_text"];
        }

        delete broadcasterJson["protocol"];

        serviceName = (serviceName == undefined) ? broadcasterJson["type"]+"1" : serviceName;
        axios.put(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName + "/" + serviceName , broadcasterJson)
        .then(res => {
            setProtocolDropDownReadOnly(true);
            setReadOnly(true);
            //swal("Broadcaster updated successfully.", { icon: "success"});
            setTimeout(function(){ window.location.reload() }, 500);
        })
        .catch(error => {
            if(error.response){
                var errorObj = error.response.data;
                swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
            }else{
                swal({text: "Unable to connect to the edge-api service" , icon: "error"});
            }
        });
    }

    return (
        <TabPanel tabId="broadcastingTab">
            { inputs == undefined &&
                <Column>
                    <ContentSpacer/>
                        <Button primary onClick={addBroadcaster}>Add Broadcaster</Button>
                    <ContentSpacer/>
                </Column>
            }
            { inputs &&
                <Row>
                    <Row>
                    <Column>
                        <Label>Select Broadcaster</Label>
                        <SelectField name="protocol" data="broadcaster0" value={inputs.protocol} onChange={handleOnChange} disabled={protocolDropDownReadOnly}>
                            <option value='rtmp' disabled={source === 'FTP' || source === 'SAFIE'}>RTMP Broadcaster</option>
                            <option value='rtp'  disabled={source === 'FTP' || source === 'SAFIE'}>RTP Broadcaster</option>
                            <option value='kinesis'  disabled={source === 'FTP' || source === 'SAFIE'}>Kinesis Broadcaster</option>
                            <option value='fog' disabled={false}>Fog Broadcaster</option>
                        </SelectField>
                        { inputs.protocol === "fog" &&
                            <Row style={{'marginTop':'15px'}}>
                                <Row>
                                    <Row>
                                        <fieldset >
                                            <legend>Network Access</legend>
                                            <Row style={{ 'marginTop': '15px' }}>
                                                <div style={{ 'display': 'flex', 'paddingBottom': '15px', 'flexDirection':isMobileBrowser ? 'column' : 'row' }}>
                                                    <div>
                                                        <input type="radio" id="network_access" name="network_access" value="deny_all" checked={inputs.network_access === 'deny_all' || false} onChange={handleRadioChange} disabled={readOnly} /> {Constants.PROJECT_NETWORK_ACCESS.deny_all}
                                                    </div>
                                                    <div style={{ 'marginLeft': isMobileBrowser ? '0':'15px'}}>
                                                        <input type="radio" id="network_access" name="network_access" value="allow_inside_device" checked={inputs.network_access === 'allow_inside_device' || false} onChange={handleRadioChange} disabled={readOnly} /> {Constants.PROJECT_NETWORK_ACCESS.allow_inside_device}
                                                    </div>
                                                    <div style={{ 'marginLeft': isMobileBrowser ? '0':'15px' }}>
                                                        <input type="radio" id="network_access" name="network_access" value="allow_all" checked={inputs.network_access === 'allow_all' || false} onChange={handleRadioChange} disabled={readOnly} /> {Constants.PROJECT_NETWORK_ACCESS.allow_all}
                                                    </div>
                                                </div>
                                            </Row>
                                        </fieldset>
                                    </Row>
                                    <Row>
                                        <fieldset>
                                            <legend>Endpoint Type</legend>
                                            <Row style={{ 'marginTop': '15px' }}>
                                            <div style={{ 'display': 'flex', 'paddingBottom': '15px', 'flexDirection':isMobileBrowser ? 'column' : 'row' }}>
                                                <div>
                                                    <input type="radio" id="bind" name="endpoint_type" value="bind" onChange={handleOnChange} checked={inputs.endpoint_type === "bind" || false} disabled={readOnly}></input> Bind
                                                </div>
                                                <div style={{ 'marginLeft': isMobileBrowser ? '0':'15px'}}>
                                                    <input type="radio" id="connect" name="endpoint_type" value="connect" onChange={handleOnChange} checked={inputs.endpoint_type === "connect" || false} disabled={readOnly}></input> Connect
                                                </div>
                                            </div>
                                            </Row>
                                        </fieldset>
                                    </Row>
                                    <Row style={{'width':'100%'}}>
                                        <Label>Destinations</Label>
                                        {isMobileBrowser ? 
                                            <div style={{'width':'100%', 'display':'flex'}}>
                                                <div style={{'width':'75%'}}>
                                                    <TextField name="destination_text" type="text" disabled={readOnly} onChange={handleOnChange} value={inputs.destination_text}/>
                                                </div>
                                                <div style={{'marginLeft':'auto'}}>
                                                    <Button primary style={{"float": "right",  'margin':'0'}} onClick={addDestination} disabled={readOnly}>Add</Button>
                                                </div>
                                            </div> :
                                            <div style={{'width':'100%'}}>
                                                <LeftDiv style={{"width": "85%", padding:'0'}}>
                                                    <TextField name="destination_text" type="text" disabled={readOnly} onChange={handleOnChange} value={inputs.destination_text} />
                                                </LeftDiv>
                                                <RightDiv style={{"width": "15%", "marginTop": "-1em"}}>
                                                    <Button primary style={{'float':'right'}} onClick={addDestination} disabled={readOnly}>Add</Button>
                                                </RightDiv>
                                            </div>}
                                    </Row>
                                    <Row style={{'width':'100%', 'marginBottom':(inputs.destinations === undefined || inputs.destinations.length === 0) ? '10px' :'40px'}}>
                                        <List id="destinations" name="destinations" style={{"marginTop": "10px", "marginBottom": "10px", 'width':'100%'}}>
                                            { inputs.destinations !== undefined && Object.keys(inputs.destinations).map((index) => {
                                                let isActive = (parseInt(index) === inputs.destinations.length-1) ? true : false;
                                                return(
                                                    <div>
                                                       {isMobileBrowser ? 
                                                            <div style={{'width':'100%', 'display':'flex', 'marginTop': '10px'}}>
                                                                <div>
                                                                    <Label style={{'fontSize': '13px', 'color': 'rgba(0,0,0,0.87)'}}>{inputs.destinations[index] +  " (" + inputs["endpoint_type"] + ")"}</Label>
                                                                </div>
                                                                <div style={{'marginLeft':'auto'}}>
                                                                    <ButtonSmall style={{"float": "right"}} data={index} onClick={deleteDestination}>Delete</ButtonSmall>
                                                                </div>
                                                            </div>:
                                                        <div>
                                                            <LeftDiv style={{"width": "85%", 'paddingTop':'5px', 'marginBottom':'10px'}}>
                                                                <Label style={{'fontSize': '13px', 'color': 'rgba(0,0,0,0.87)'}}>{inputs.destinations[index] +  " (" + inputs["endpoint_type"] + ")"}</Label>
                                                            </LeftDiv>
                                                            <ButtonSmall style={{"float": "right", 'marginRight':'0', 'padding': '0.3em 0.9em'}} data={index} onClick={deleteDestination}>Delete</ButtonSmall>
                                                        </div>}
                                                    </div>)
                                                })
                                            }
                                            { (inputs.destinations === undefined || inputs.destinations.length === 0)&&
                                                <Label style={{'fontSize': '13px', 'color': 'rgba(0,0,0,0.87)'}}>Destination list is empty</Label>                                   
                                            }
                                        </List>
                                    {isMobileBrowser && <HR /> }
                                    </Row>
                                    {!isMobileBrowser &&
                                    <Row>
                                        <HR />
                                    </Row>}
                                    <Row>
                                        <div style={{"paddingTop": "20px", "color": "gray", "fontSize": "0.9em"}}>
                                            <Label style={{"fontWeight": "bold", "marginRight": "10px"}}>Special Addresses</Label>
                                            {Object.keys(Constants.FOG_BROADCASTER_ALIASES).map((index)=> {
                                                return(
                                                    <div style={{"display": "flex"}}>
                                                        <Label> {Constants.FOG_BROADCASTER_ALIASES[index]}</Label>
                                                    </div>
                                                )})
                                            }
                                        </div>
                                    </Row>
                                </Row>
                            </Row>}
                            { inputs.protocol === "rtmp" &&
                                <Column style={{'width':'100%', 'padding':'0', 'paddingTop':'15px'}}>
                                    <MandatoryLabelStream>Stream URL</MandatoryLabelStream>
                                    <TextField name="rtmp_url" type="text" data="broadcaster0" value={inputs.rtmp_url} onChange={handleOnChange} disabled={readOnly} />
                                </Column>
                            }
                            { inputs.protocol === "rtp" &&
                                <Column style={{'width':'100%', 'padding':'0', 'paddingTop':'15px'}}>
                                    <MandatoryLabelStream>Server IP</MandatoryLabelStream>
                                    <TextField name="rtp_server_ip" type="text" data="broadcaster0" value={inputs.rtp_server_ip} onChange={handleOnChange} disabled={readOnly} maxLength="100"/>
                                </Column>}
                            { inputs.protocol === "kinesis" &&
                                    <Column style={{'width':'100%', 'padding':'0', 'paddingTop':'10px'}}>
                                    <MandatoryLabelBroadcaster>Stream Name</MandatoryLabelBroadcaster>
                                    <TextField name="kinesis_streamer_stream_name" type="text" data="broadcaster0" value={inputs.kinesis_streamer_stream_name} onChange={handleOnChange} disabled={readOnly} maxLength="100"/>
                                </Column>}
                                
                    </Column>
                    </Row>
                    { inputs.protocol === "rtmp" &&
                        <Column style={{'paddingTop':'5px'}}>
                            <Label3>Stream Key</Label3>
                            <TextField name="stream_key" type="text" data="broadcaster0" value={inputs.stream_key} onChange={handleOnChange} disabled={readOnly} maxLength="100"/>
                        </Column>
                    }
                    { inputs.protocol === "kinesis" &&
                        <>
                            <Column style={{'paddingTop':'5px'}}>
                                <MandatoryLabelStream style={{'marginTop':'10px'}}>Access Key</MandatoryLabelStream>
                                <TextField name="kinesis_streamer_access_key_id" type="text" data="broadcaster0" value={inputs.kinesis_streamer_access_key_id} onChange={handleOnChange} disabled={readOnly} maxLength="100"/>
                                <MandatoryLabelStream style={{'marginTop':'10px'}}>Secret Key</MandatoryLabelStream>
                                <TextField name="kinesis_streamer_secret_access_key" type="text" data="broadcaster0" value={inputs.kinesis_streamer_secret_access_key} onChange={handleOnChange} disabled={readOnly} maxLength="100"/>
                            </Column>
                        </>}

                    { inputs.protocol === "rtp" &&
                        <>
                            <Column style={{'paddingTop':'5px'}}>
                                <MandatoryLabelBroadcaster>Video Port</MandatoryLabelBroadcaster>
                                <TextField name="rtp_video_udp_port" type="text" data="broadcaster0" value={inputs.rtp_video_udp_port} onChange={handlePortOnChange} disabled={readOnly} maxLength="5"/>
                            
                                <Label3 style={{'marginTop':'10px'}}>Audio Port</Label3>
                                <TextField name="rtp_audio_udp_port" type="text" data="broadcaster0" value={inputs.rtp_audio_udp_port} onChange={handlePortOnChange} disabled={readOnly} maxLength="5"/>
                            </Column>
                        </>}
    
                    {inputs.protocol === "fog" && 
                    <Column>
                    <div>
                        <Label>Stream ID</Label>
                        <Label style={{"float": "right", "fontSize":"0.9em"}}>(auto - same as Stream Name)</Label>
                    </div>
                    <TextField name="stream_id" type="text" data="broadcaster0" disabled={readOnly} maxLength="100" onChange={handleOnChange} value={inputs.stream_id}/>
                    <fieldset style={{"marginTop": "15px"}}>
                        <legend>
                        JPEG Compression
                        </legend>
                    <div>
                        <LeftDiv style={{"width":"40%", "marginTop":"25px"}}>
                            <SelectionControl id="compression" name="compression" type="switch" label={inputs.compression === true ? "Enabled" : "Disabled"} labelBefore="true" checked={inputs.compression || false} onChange={handleToggleChange}/>
                        </LeftDiv>
                        <RightDiv style={{"width":"60%"}}>
                            <Slider name="compression_quality" label={"Quality "+ inputs.compression_quality + "%"} discrete onChange={handleSliderChange} value={parseInt(inputs.compression_quality)} disabled={readOnly || !inputs.compression}/>
                        </RightDiv>
                    </div>
                    </fieldset>
                    
                    <fieldset>
                    <legend>
                    Frame Rate Logging
                    </legend>
                    <div style={{"padding": "12px 0px", "width":"100%", "display":"inline-flex"}}>
                        <div style={{"width":"40%"}}>
                        <SelectionControl id="frame_rate_logging" name="frame_rate_logging" type="switch" label={inputs.frame_rate_logging === true ? "Enabled" : "Disabled"} labelBefore="true" checked={inputs.frame_rate_logging || false} onChange={handleToggleChange}/>
                        </div>
                        <div style={{"paddingTop": "10px", "width":"60%", "display":"inline-flex"}}>
                        <Label>Interval (Secs)&nbsp;</Label>
                        <div>
                            <TextField name="frame_rate_logging_interval" type="text" data="broadcaster0" disabled={readOnly || !inputs.frame_rate_logging} maxLength="4" onChange={handleFloatOnChange} value={inputs.frame_rate_logging_interval}/>
                        </div>
                        </div>
                    </div>
                    </fieldset>
                    <div>
                    <Label>Emit Interval (Secs)</Label>
                    <Label style={{"float": "right", "fontSize":"0.9em"}}>(0 - Emit all frames)</Label>
                    </div>
                    <TextField name="emit_interval" type="text" data="broadcaster0" disabled={readOnly} maxLength="5" onChange={handleFloatOnChange} value={inputs.emit_interval}/>
                    <Label style={{"paddingTop": "10px"}}>Watchdog Timeout (Secs)</Label>
                    <Label style={{"float": "right", "fontSize":"0.9em", "paddingTop": "10px"}}>(0 - Disabled)</Label>
                    <TextField name="watchdog_timeout" type="text" data="broadcaster0" disabled={readOnly} onChange={handleIntegerOnChange} value={inputs.watchdog_timeout}/>
                </Column>}
                    <Row>
                    <Column>
                    </Column>
                    <Column>
                    <RightDiv>
                        {isViewPage &&
                            <div style={{"float":"left"}}>
                                { readOnly == true &&
                                    <Button id="editBroadcastererButton" primary onClick={editBroadcaster}>Edit</Button>
                                }
                                { readOnly == false &&
                                    <div>
                                    <Button primary onClick={saveBroadcaster}>Save</Button>
                                    { initialValues != undefined &&
                                        <Button onClick={cancelBroadcaster}>Cancel</Button>
                                    }
                                    </div>
                                }
                            </div>
                        }
                        <Button onClick={deleteBroadcaster}>{(isViewPage && initialValues != undefined) ? "Delete" : "Cancel"}</Button>
                    </RightDiv>
                    </Column>
                    </Row>
                </Row>}
    </TabPanel>)
    }

export default BroadcastingTab;
