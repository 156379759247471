import React, { Component } from 'react';
import MetisMenu from 'react-metismenu';
import "../css/react-metismenu-standard.css";
import * as Constants from '../utils/Constants';


const menuContent=[
    {
        icon: 'stream-list',
        label: 'Stream Viewer',
        to: 'stream-viewer'
    },  
    {
        icon: 'monitoring',
        label: 'Monitoring',
        content: [
            {
                label: 'Current Status',
                to: 'current-status'
            },
            {
                label: 'Recent Activity',
                to: Constants.NETDATA_ENDPOINT,
                externalLink: true
            },
            {
                label: 'Long Term Activity',
                to: Constants.GRAFANA_ENDPOINT,
                externalLink: true
            }/*,
            {
                label: 'Storage',
                to: 'storage',
            }*/
        ]
    },
    {
        icon: 'upload-repo',
        label: 'SDK',
        content: [
            // {
            //     label: 'Project',
            //     to: 'projects-old'
            // },
            {
                label: 'Project',
                to: 'projects'
            },
            {
                label: 'Relational Database',
                to: 'rdb'
            },{
                label: 'Metabase',
                to: 'metabase'
            },
            {
                label: 'Email Client',
                to: 'email-client'
            },
            {
                label: 'Portainer',
                to: Constants.PORTAINER_ENDPOINT,
                externalLink: true
            }
        ]
    },
    {
        icon: 'setting',
        label: 'Settings',
        content: [
            {
                label: 'Network',
                to: 'network'
            },
            {
                label: 'Schedule',
                to: 'schedule'
            },
            {
                label: 'Date and Time',
                to: 'time-settings'
            },
            {
                label: 'Location',
                to: 'location'
            },
            {
                label: 'FTP Server',
                to: 'ftp-server'
            },
            {
                label: 'Safie Connector',
                to: 'safie-camera-settings'
            },
            {
                label: 'Cloud Uploader',
                to: 'cloud-uploader'
            },
            {
                label: 'Naming Rules',
                to: 'naming-rules'
            },
            {
                label: 'Web Proxy Rules',
                to: 'web-redirect'
            },
            {
                label: 'Monitoring',
                to: '/monitoring-settings'
            }
        ]
    },
    {
        icon: 'power',
        label: 'Power',
        content: [
            {
                label: 'Reboot',
                to: 'reboot'
            },
            {
                label: 'Shutdown',
                to: 'shutdown'
            }
        ]
    },
    {
        icon: 'help-menu',
        label: 'Help',
        content: [
            {
                label: 'Device Information',
                to: 'device-info'
            },
            {
                label: 'Device Log',
                to: 'device-log',
            }
        ]
    }
];


const Menus = (props) => {
    return (
        <MetisMenu  className={props.className} content={menuContent} activeLinkFromLocation  iconNamePrefix='icon-' />
    );
}

export default Menus;
