import format from 'date-fns/format';
import {convertToTimeZone} from 'date-fns-timezone';

export const generateRandomPassword = (length) => {
  let result = '';
  let characters = 'ABCDEFGHJKLMNPRSTUVWXYZadefhmnrt23456789:@.,/+-!=';
  let charLength = characters.length;
  for ( let i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charLength));
  }
  return result;
};

export const getFormattedDate = (strDate) => {
  try {
    const zonedTargetDate = convertToTimeZone(strDate, { timeZone: 'Asia/Tokyo' });
    return format(zonedTargetDate, 'yyyy/MM/dd HH:mm:ss') + ' JST';
  } catch (err) {
    console.error(err);
  }
  return '-';
};

export const dateFormat = (strDate) => {
  try {
    const zonedTargetDate = convertToTimeZone(strDate, { timeZone: 'Asia/Tokyo' });
    return format(zonedTargetDate, 'yyyy/MM/dd');
  } catch (err) {
    console.error(err);
  }
  return '-';
};

export const timeZone = (strDate) => {
  try {
    const zonedTargetTime = convertToTimeZone(strDate, { timeZone: 'Asia/Tokyo' });
    return format(zonedTargetTime, 'HH:mm:ss') + ' JST';
  } catch (err) {
    console.error(err);
  }
  return '-';
};

export const convertedDate = (date) => {
  console.log(date, 'date')
  if(date === '0' || date === '') {
    return '-';
  } else {
  try {
    let newStr = date.replace(/_/g, " ");
    const splitDate = newStr.split(' ');
    const splitWithPlus = splitDate[1].split('+');
    const dateformate = splitDate[0] + ' ' + splitWithPlus[0];
    let d1 = new Date(dateformate);
    console.log(format(d1, "yyyy/MM/dd "), 'date')
    return format(d1, "yyyy/MM/dd ");
  } catch (err) {
    console.log(err);
  }
  return '-';
}
}

export const convertedTime = (date) => {
  if(date === '0' || date === '') {
    return '';
  } else {
  try {
    let newStr = date.replace(/_/g, " ");
    const splitDate = newStr.split(' ');
    const splitWithPlus = splitDate[1].split('+');
    const dateformate = splitDate[0] + ' ' + splitWithPlus[0];
    let d1 = new Date(dateformate);
    let countryCode = splitWithPlus[1] === '0900' ? 'JST' : '';
    console.log(format(d1, 'HH:mm:ss') + ' ' + countryCode, 'date')
    return format(d1, 'HH:mm:ss') + ' ' + countryCode;
  } catch (err) {
    console.log(err);
  }
  return '';
  }
}

export const formattedDate = (date) => {
  console.log(date, 'date')
  if(date === '0' || date === '') {
    return '';
  } else {
  try {
    let newStr = date.replace(/_/g, " ");
    const splitDate = newStr.split(' ');
    const splitWithPlus = splitDate[1].split('+');
    const dateformate = splitDate[0] + ' ' + splitWithPlus[0];
    let d1 = new Date(dateformate);
    let countryCode = splitWithPlus[1] === '0900' ? 'JST' : '';
    return format(d1, 'yyyy/MM/dd HH:mm:ss') + ' ' + countryCode;
  } catch (err) {
    console.log(err);
  }
  return '';
  }
};

export const clickToCopy = (elementId) => {
  let copyElement = document.getElementById(elementId);
  const textarea = document.createElement('textarea');
  textarea.id = 'copyTextArea';
  textarea.value = copyElement.innerText;
  textarea.setAttribute('readonly', '');
  textarea.style.position = 'absolute';
  textarea.style.width = '0px';
  textarea.style.height = '0px';
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  copyElement.blur();
  document.getElementById('copyTextArea').remove();
};

export const isEmpty =(val) => {
  if(val === undefined || val.trim() === '') {
    return true;
  } else {
    return false;
  }
};

export const isEmptyNumber =(val) => {
  if(val === undefined || val === '') {
    return true;
  } else {
    return false;
  }
};

export const detectBrowser = () => { 
  if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
      return 'Opera';
  } else if(navigator.userAgent.indexOf("Chrome") != -1 ) {
      return 'Chrome';
  } else if(navigator.userAgent.indexOf("Safari") != -1) {
      return 'Safari';
  } else if(navigator.userAgent.indexOf("Firefox") != -1 ){
      return 'Firefox';
  } else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) {
      return 'IE';
  } else {
      return 'Unknown';
  }
}
  