import React , { useState, useEffect, useCallback } from 'react'
import ls from 'local-storage';
import styled from 'styled-components';
import axios from 'axios';
import * as Constants from '../utils/Constants';
import ReactTooltip from 'react-tooltip';

const DeviceIDContainer=styled.div`
    width: 100%;
    height: auto;
    background-color:#1E292F;
    padding-left: 15px;
    padding-top: 10px !important;
`;

const DeviceIDText=styled.p`
    text-align: left;
    font-size: 1.2em;
    font-weight: bold;
    color: #A8B5BD;
`;

const DeviceStatusContainer=styled.div`
    height: 36px;
    padding-right: 15px;
    margin-left:15px;

`;

const TooltipMessage=styled.span`
    font-weight: bold;
    font-size: 1.0em;
    color: black; 
`;

const ReconciliationStatus=styled.a`
    font-weight: bold;
    font-size: 1.2em;
    color: orange;
    cursor: pointer;
`;

const ResetDeviceStatus=styled.a`
    font-weight: bold;
    font-size: 1.2em;
    color: orange;
    cursor: pointer;
`;

const FailedStatus=styled.a`
    font-weight: bold;
    font-size: 1.2em;
    color: red;
    cursor: pointer;
`;

function DeviceHealthStatus() {
    const [healthStatus, sethealthStatus] = useState();
    const [devicereset, setDeviceReset] = useState(true);
    const [pageLoading, setPageloading] = useState(true);

    useEffect(() =>{
        axios.get(Constants.EDGE_API_ENDPOINT + '/system/status')
        .then(res =>{
            sethealthStatus(res.data['data']);
        })
        .catch(err => {
            console.log(err)
        })
    }, []);

    const getResetStatus = useCallback(() => {
        let interval;
        interval = setInterval(() => {
          axios.get(Constants.EDGE_API_ENDPOINT + '/system/device_reset')
          .then(res => {
            setPageloading(false);
            if(res.data.message === 'Not Running') {
                setDeviceReset(false);
                clearInterval(interval);
            } else {
                setDeviceReset(true);
            }
          })
          .catch(error => {
            console.error(error);
            setDeviceReset(false);
            setPageloading(false);
          });
        }, 3000);
      },[]);

      useEffect(() => {
        if (devicereset) {
          getResetStatus();
        }
      }, [devicereset]);
    
    return (
        <div>
             <DeviceIDContainer>
                <DeviceIDText>
                    <span id='deviceId'>{ls.get('device_id') === undefined ? '' : ls.get('device_id')}</span><br/>
                </DeviceIDText>
            </DeviceIDContainer>
            { (healthStatus && healthStatus.status === 'failed') &&
                <DeviceStatusContainer>
                    <FailedStatus data-tip data-for='tooltip'>{Constants.HEALTH_STATUS_TYPES['failed']}</FailedStatus>
                        <ReactTooltip id='tooltip' place="right" type='light' effect='solid' backgroundColor='#E0E0E0'>
                            <TooltipMessage>{healthStatus.message}</TooltipMessage>
                        </ReactTooltip>
                </DeviceStatusContainer>
            }
            { (healthStatus && healthStatus.status === 'recovering') && 
                <DeviceStatusContainer>
                    <ReconciliationStatus data-tip data-for='tooltip'>{Constants.HEALTH_STATUS_TYPES['recovering']}</ReconciliationStatus>
                        <ReactTooltip id='tooltip' place="right" type='light' effect='solid' backgroundColor='#E0E0E0'>
                            <TooltipMessage>{healthStatus.message}</TooltipMessage>
                        </ReactTooltip>
                </DeviceStatusContainer>
            }
            {!pageLoading && 
                <>
                    {devicereset && 
                        <DeviceStatusContainer>
                            <ResetDeviceStatus data-tip data-for='tooltip'>Device reset in progress</ResetDeviceStatus>
                            <ReactTooltip id='tooltip' place="right" type='light' effect='solid' backgroundColor='#E0E0E0'>
                                <TooltipMessage>Device Reset currently in progress</TooltipMessage>
                            </ReactTooltip>
                        </DeviceStatusContainer>}
                </>}
        </div>
    )
}

export default DeviceHealthStatus
