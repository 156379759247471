import React, { useState, useEffect} from 'react';
import { Label, SelectField } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import SelectOptions from '../components/SelectOptions';
import ls from 'local-storage';


const AutoRefresh = (props) => {
    const [refreshInterval, setRefreshInterval] = useState(15);
    const [intervalId, setIntervalId] = useState();
    const localStorageName = props.pageName + "_refresh_interval";

    useEffect(() => {
        if(ls.get(localStorageName) !== null){
            let refInterval = ls.get(localStorageName);
            setRefreshInterval(refInterval);
            refreshPage(refInterval);
        } else {
            refreshPage(refreshInterval);
        }
    }, []);

    function refreshPage(refInterval){
        if(refInterval !== "0"){
            const interval = setInterval(() => {
                props.autoRefresh();
            }, refInterval*1000);

            setIntervalId(interval)

            return () => {
                clearInterval(interval);
            };
        }
    }

    const handleOnChange = (event) => {
        clearInterval(intervalId);
        setRefreshInterval(event.target.value);
        ls.set(localStorageName, event.target.value);
        refreshPage(event.target.value)
    }

    return (
        <div className="refresh-interval">
            <Label>Refresh Interval</Label>
            <SelectField name="refreshInterval" value={refreshInterval} onChange={handleOnChange}>
                { props.pageName === "current_status" ?
                    <SelectOptions items={Constants.CS_AUTO_REFRESH_INTERVALS}/>
                :
                    <SelectOptions items={Constants.AUTO_REFRESH_INTERVALS}/>
                }
            </SelectField>
        </div>
    )
}

export default AutoRefresh;
