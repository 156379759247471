import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { ContentDiv, Wrapper, SectionHeader, SectionLabel, Label, Button, SelectField } from '../utils/Styles';
import SelectOptions from '../components/SelectOptions';
import * as Constants from '../utils/Constants';
import DataTable from 'react-data-table-component';
import AutoRefresh from '../components/AutoRefresh';
import { Tabs, Tab, TabList, TabPanel } from 'react-web-tabs';
import { CircularProgress } from 'react-md';
import swal from 'sweetalert';
import ls from 'local-storage';
import { useHistory, useLocation } from 'react-router-dom';


const DeviceLog = (props) => {
  const [currentLogsData, setCurrentLogsData] = useState({});
  const [loading, setLoading] = useState(false);
  const [logLevel, setLogLevel] = useState('All');
  var selectedTab = 'currentLogsTab';
  const params = useLocation().search;
  const [historyParams] = useState(params);
  const history = useHistory();

  const setParams = useCallback(() => {
    const params = '?' +
      `${logLevel === 'all' ? 'all' : '&logLevel=' + logLevel}`
      
    if (params === '?') {
      return '';
    }
    return params;
  }, [logLevel]);

  const fetchHistoryParams = useCallback(() => {
    const string = new URLSearchParams(historyParams);
    let newParams = {};
    newParams.logLevel = string.get('logLevel');

    if (newParams.logLevel) {
      setLogLevel(newParams.logLevel);
    }
  }, [historyParams]);

  useEffect(()=>{
    fetchHistoryParams();
  },[fetchHistoryParams]);

  useEffect(() => {
    history.push('/device-log' + setParams());
  }, [history, setParams]);

  const columns = [
    {
      name: 'Date',
      selector: 'date',
      sortable: true,
      width: '20vw'
    },
    {
      name: 'Level',
      selector: 'log_level',
      sortable: true,
      width: '6vw'
    },
    {
      name: 'Service',
      selector: 'service_name',
      sortable: true,
      width: '14vw'
    },
    {
      name: 'Message',
      selector: 'data',
      sortable: true,
      wrap: true,
      maxWidth: '40vw'
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: '40px',
        padding: '5px'
      }
    },
    headCells: {
      style: {
        fontSize: '1.1em',
        padding: '10px',
        backgroundColor: '#E5E5E5'
      },
    },
    cells: {
      style: {
        padding: '5px',
        fontSize: '1.1em'
      },
    },
  };

  function downloadLogs(){
    setLoading(true);
    let request_json = {
      'action': 'DownloadLogs',
      'source': 'Edge UI'
    };
    axios({
      url: Constants.EDGE_API_ENDPOINT + '/system/services/log_collector',
      method: 'POST',
      responseType: 'blob',
      data: request_json
    }).then((response) => {
      setLoading(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      let zipFile = 'Logs.zip';
      if('log_file_name' in response.headers){
        zipFile = response.headers.log_file_name;
      } else {
        let deviceId = ls.get('device_id');
        zipFile = (deviceId === undefined || deviceId === null) ? zipFile : deviceId + '.zip';
      }
      link.setAttribute('download', zipFile);
      document.body.appendChild(link);
      link.click();
    })
      .catch(error => {
        setLoading(false);
        swal({text: 'Unable to download logs.' , icon: 'error'});
      });
  }

  function populateCurrentLogsData(data){
    if(data === undefined || data === null){
      return;
    }
    let filteredList = [];
    Object.values(data).map((service) => {
      let serviceName = Object.keys(service)[0];
      let dataList = service[serviceName];
      let logLevel = document.getElementById('logLevelFilter').value;
      for( var i=0; i< dataList.length; i++){
        let service = dataList[i];
        if(logLevel === 'all' || service.log_level === logLevel){
          let data = service.data;
          let index = data.indexOf('[');
          service.service_name = data.substring(0, index);
          service.data = data.substring(index);
          filteredList.push(service);
        }
      }
      return service;
    });
    setCurrentLogsData(filteredList);
  }

  const getLogs = useCallback(() => {
    axios.get(Constants.EDGE_API_ENDPOINT + '/system/services/log_collector?current_logs=true')
      .then(res => {
        populateCurrentLogsData(res.data.data);
      })
      .catch(error => {
        console.log(error);
      });
  },[]);

  function autoRefreshCallback(){
    if(selectedTab === 'currentLogsTab'){
      getLogs();
    }
  }

  function onTabChange(tabId){
    selectedTab = tabId;
    if(selectedTab === 'currentLogsTab'){
      document.getElementsByClassName('refresh-interval')[0].style.display = 'block';
      document.getElementById('logLevelDiv').style.display = 'block';
    } else {
      document.getElementsByClassName('refresh-interval')[0].style.display = 'none';
      document.getElementById('logLevelDiv').style.display = 'none';
    }
  }

  const handleLogFilterOnChange = (event) => {
    setLogLevel(event.target.value);
    if(selectedTab === 'currentLogsTab'){
      getLogs();
    }
  };

  useEffect(() => {
    getLogs();
  }, [getLogs]);

  return (
    <ContentDiv>
      <Wrapper>
        <SectionHeader>
          <SectionLabel>Device Log</SectionLabel>
          <AutoRefresh id="logAutoRefresh" autoRefresh={autoRefreshCallback} pageName="logs"/>
        </SectionHeader>
        <Tabs defaultTab="currentLogsTab" onChange={onTabChange}>
          <TabList>
            <Tab tabFor="currentLogsTab" className={'rwt__tab'}>View</Tab>
            <Tab tabFor="downloadLogsTab" className={'rwt__tab'} >Download</Tab>
            <div id="logLevelDiv" style={{'float':'right'}}>
              <Label>Level</Label>
              <SelectField id="logLevelFilter" name="logLevelFilter" value={logLevel} style={{'width': '100px', 'margin': '7px 10px 0px 10px'}} onChange={handleLogFilterOnChange} >
                <SelectOptions items={Constants.LOG_LEVELS}/>
              </SelectField>
            </div>
          </TabList>
          <TabPanel tabId="currentLogsTab">
            { currentLogsData &&
              <DataTable
                defaultSortAsc={false}
                defaultSortField="date"
                noHeader={true}
                columns={columns}
                data={currentLogsData}
                customStyles={customStyles}
              />
            }
          </TabPanel>
          <TabPanel tabId="downloadLogsTab">
            <div>
              <Button primary style={{'margin':'50px 20px'}} onClick={downloadLogs}>Get the device logs</Button>
              {loading &&
                <div className="loading-msg">
                  <CircularProgress/>
                  <label>Downloading logs...<br/>It may take time, so please wait and do not refresh browser!</label>
                </div>
              }
            </div>
          </TabPanel>
        </Tabs>
      </Wrapper>
    </ContentDiv>
  );
};

export default DeviceLog;