import React from "react";
import { Label } from "../utils/Styles";
import styled from 'styled-components';

const TextFieldLabel = styled(Label)`
  width: 77px;
  margin-top: 0px;
  display: contents;
`;

const Span = styled.span`
  color: #ff6161;
  font-size: 11px;
`;


function FieldLabel({ children, required}) {
  return (
    <TextFieldLabel>
      {children}
      {required &&
        <Span>*</Span>}
    </TextFieldLabel>
  );
}
FieldLabel.defaultProps = {
  required: false
}

export default FieldLabel;
