import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Tabs, Tab, TabList } from 'react-web-tabs';
import axios from 'axios';
import { ContentDiv, Wrapper, Label, Label2, LeftDiv, Spacer, TextField, MandatoryLabelStream, TextArea, SelectField, Row, Column, RightDiv, SectionHeader, Button, ButtonWrapper, ImageIcon, ImageIconThin, ButtonSmall, SectionLabel } from '../utils/Styles';
import SelectOptions from '../components/SelectOptions';
import RecordingTab from '../components/RecordingTab';
import AnalysisTab from '../components/AnalysisTab';
import BroadcastingTab from '../components/BroadcastingTab';
import ProjectTab from '../components/ProjectTab';
import StreamForwardingTab from '../components/StreamForwardingTab';
import CaptureScheduleTab from '../components/CaptureScheduleTab';
import CameraImage from '../components/CameraImage';
import * as Constants from '../utils/Constants';
import "../css/react-tabs.css";
import { SelectionControl, CircularProgress, Media } from 'react-md';
import swal from "sweetalert";
import  '../images/no_image.png';
import Popup from "reactjs-popup";
import WebRTCPlayer from '../components/WebRTCPlayer';
import ls from 'local-storage';

const contentStyle = {
    "width":" 85%",
    "text-align": "center",
    "height": "90%"
};

const ViewStream = (props) => {
    const [readOnly, setReadOnly] = useState(true);
    const [isRefreshInterval, setRefreshInterval] = useState(true);
    const [videoDevices, setVideoDevices] = useState();
    const [inputs, setInputs] = useState();
    const [recorderState, setRecorderState] = useState({});
    //const [analysisState, setAnalysisState] = useState({});
    const [broadcastingState, setBroadcastingState] = useState({});
    const [webrtcState, setWebrtcState] = useState({});
    const [rtspState, setRtspState] = useState({});
    const [projectState, setProjectState] = useState({});
    const [streamForwardingState, setStreamForwardingState] = useState({});
    const [source, setSource] = useState({});
    const [videoDeviceInfo, setVideoDeviceInfo] = useState();
    const [pageStreamStatus, setPageStreamStatus] = useState()
    var stackName = props.location.search.substring(8);
    //var availableDevices = ([recorderState.video_device].concat(videoDevices)).sort()
    const [streamStatusData, setStreamStatusData] = useState();
    const [originalState, setOriginalState] = useState({});
    var mDnsRtspUrl;
    var deviceIPRtspUrl;
    var mDnsWebrtcUrl;
    var deviceIPWebrtcUrl;
    var refreshImage = true;
    var streamStatus = ""
    const switchBorderStyle = { borderRadius: 2 }
    const switchBackgroundStyle =   {
                                base: 'rgb(33,150,243)',
                                hover: 'rgb(33,150,243)'
                            }
    const [isMobileBrowser, setIsMobileBrowser] = useState(false);

    useEffect(()=>{
        var isMobileBrowser = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if(isMobileBrowser) {
        setIsMobileBrowser(isMobileBrowser);
        } else {
        setIsMobileBrowser(isMobileBrowser);
        }
    },[]);

    useEffect(() => {
        axios.get(Constants.EDGE_API_ENDPOINT + '/devices/videos')
            .then(res => {
                let respData = res.data["data"];
                if(respData){
                    if(respData["all_devices"].length > 0) {
                        setVideoDevices(respData["all_devices"]);
                    } else {
                        setVideoDevices([]);
                    }
                }
            })
            .catch(error => {
                setVideoDevices([])
            });

        axios.get(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName + "/all")
            .then(res => {
                let services = res.data["data"]["services"];
                res.data["data"]["services"]["recorder1"]["video_file_max_size"] = res.data["data"]["services"]["recorder1"]["video_file_max_size"]/1048576 + "";
                setInputs(res.data["data"]);
                setOriginalState(res.data["data"]);
                Object.keys(services).map((service_name) => {
                    if(services[service_name]["action"] !== "DeleteService"){
                    let jsonData = {
                        "service_name": service_name
                    }
                    if(services[service_name]["type"] === "recorder"){
                        let recorder = services[service_name];
                        setSource(recorder["camera_type"]);
                        if(recorder["camera_type"] === "USB"){
                            recorder["resolution"] = recorder["video_width"] + "x" + recorder["video_height"];
                        }
                        recorder["service_name"] = service_name;
                        setRecorderState(recorder);
                        if(recorder["camera_type"] === "USB"){
                            getVideoDeviceData(recorder["video_device"]);
                        }
                    }
                    /*else if (services[service_name]["type"] === "cascade_tracker"){
                        jsonData["data"] = services[service_name];
                        setAnalysisState(jsonData);
                    } else if (services[service_name]["type"] === "hog_tracker"){
                        jsonData["data"] = services[service_name];
                        setAnalysisState(jsonData)
                    }*/
                    else if (services[service_name]["type"] === "rtmp_broadcaster"){
                        let broadcaster = services[service_name]
                        broadcaster["protocol"] = "rtmp";
                        jsonData["data"] = broadcaster;
                        setBroadcastingState(jsonData);
                    } else if (services[service_name]["type"] === "rtp_broadcaster"){
                        let broadcaster = services[service_name]
                        broadcaster["protocol"] = "rtp";
                        jsonData["data"] = broadcaster;
                        setBroadcastingState(jsonData);
                    } else if (services[service_name]["type"] === "fog_broadcaster"){
                        let broadcaster = services[service_name]
                        broadcaster["protocol"] = "fog";
                        if (broadcaster["stream_id"] == stackName){
                            broadcaster["stream_id"] = "auto"
                        }
                        jsonData["data"] = broadcaster;
                        setBroadcastingState(jsonData);
                    } else if (services[service_name]["type"] === "kinesis_streamer"){
                        let broadcaster = services[service_name]
                        broadcaster["protocol"] = "kinesis";
                        jsonData["data"] = broadcaster;
                        setBroadcastingState(jsonData);
                    } else if (services[service_name]["type"] === "stream_forwarder"){
                        let streamForwarder = services[service_name]
                        streamForwarder["service_name"] = service_name;
                        streamForwarder["sf_frame_rate_logging"] = streamForwarder["frame_rate_logging"];
                        streamForwarder["sf_endpoint_type"] = streamForwarder["endpoint_type"];
                        delete streamForwarder["frame_rate_logging"];
                        delete streamForwarder["endpoint_type"];
                        setStreamForwardingState(streamForwarder);
                    }
                    }
                });
                setRtspAndWebrtcDetails(res.data["data"], isMobileBrowser)
            });
            getStreamStatus();
            const interval = setInterval(() => {
                getStreamStatus();
            }, 15000);
            return () => clearInterval(interval);

    }, [isMobileBrowser]);

    function setRtspAndWebrtcDetails(jsonData_, isMobileBrowser) {
        const mDnsName = ls.get("device_id") === undefined ? "" : ls.get("device_id") + ".local";
        const deviceIP = ls.get("device_ip") === undefined ? window.location.hostname : ls.get("device_ip");

        if (jsonData_["rtsp_port"] !== "" ){
            var check = jsonData_["rtsp_enabled"]
            var videoDevice = jsonData_["services"]["recorder1"]["video_device"]
            var rtspService = {}
            mDnsRtspUrl = "rtsp://" + mDnsName + ":" + jsonData_["rtsp_port"] + "/" + videoDevice
            deviceIPRtspUrl = "rtsp://" + deviceIP + ":" + jsonData_["rtsp_port"] + "/" + videoDevice
            rtspService["peer_address_mdns"] = mDnsRtspUrl
            rtspService["peer_address_ip"] = deviceIPRtspUrl
            rtspService["rtsp_url_type"] = "mDNS"
            setRtspState(rtspService);
        }

        var webrtcService = jsonData_["services"]["webrtc_broadcaster"];
        let mDnsWebrtcUrl = "ws://" + mDnsName + ":" + Constants.EDGE_UI_PORT + "/websocket-" + webrtcService.sender_port;
        let deviceIPWebrtcUrl = "ws://" + deviceIP + ":" + Constants.EDGE_UI_PORT + "/websocket-" + webrtcService.sender_port;
        webrtcService["peer_address_mdns"] = mDnsWebrtcUrl;
        webrtcService["peer_address_ip"] = deviceIPWebrtcUrl;
        webrtcService["webrtc_url_type"] = "mDNS";

        let services = jsonData_["services"];
        if(services["recorder1"]["camera_type"] === "USB"){
            if(services["recorder1"]["video_width"] < 1280){
                webrtcService["video_height"] = "240px";
            } else {
                if(isMobileBrowser) {
                    webrtcService["video_height"] = "210px";
                } else {
                    webrtcService["video_height"] = "270px";
                }
            }
        } else {
            webrtcService["video_height"] = "270px";
        }

        setWebrtcState(webrtcService);
    }

    const handleRtspLabelOnChange = (value) => {
        setRtspState({...rtspState, ["rtsp_url_type"]: value});
    }

    const handleWebrtcLabelOnChange = (value) => {
        setWebrtcState({...webrtcState, ["webrtc_url_type"]: value});
    }

    function getStreamStatus() {
        axios.get(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName + '/status/all')
            .then(res => {
                setStreamStatusData(res.data.data);
            });
    }

    function getVideoDeviceData(deviceName){
        axios.get(Constants.EDGE_API_ENDPOINT + "/devices/video/" + deviceName)
            .then(res => {
                if(res.data["data"]){
                    setVideoDeviceInfo(res.data);
                    // setSelectedVideoDevice(deviceName)
                }
            })
    }

    const handleVideoDeviceOnChange = (event) => {
        event.persist();
        setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
        getVideoDeviceData(event.target.value);
    }

    const handleOnChange = (event) => {
        event.persist();
        if(event.target.name === "description"){
            if(event.target.value.length > 255){
                return;
            }
        }
        setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
    }

    const handleNumberOnChange = (event) => {
        event.persist();
        event.target.value = event.target.value.replace(/[^0-9]/g, '');
        setInputs({...inputs, [event.target.name]: event.target.value});
    }

    const handleToggleChange = (value, event) => {
        if(!readOnly){
            setInputs({...inputs, [event.target.id]: value});
        }
    };

    const clickToCopy = (event) => {
        let webRTCAddressElement = document.getElementsByName("webRTCAddress")[0]
        webRTCAddressElement.select();
        document.execCommand("copy");
        webRTCAddressElement.blur();
    }


    const clickToCopyRtspUrl = (event) => {
        let rtspAddressElement = document.getElementsByName("rtspAddress")[0]
        rtspAddressElement.select();
        document.execCommand("copy");
        rtspAddressElement.blur();
    }

    const saveRefreshInterval = () => {
        var jsonData = Object.assign({}, inputs);
        console.log(jsonData, 'JSON');
        axios.patch(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName, jsonData)
        .then(res => {
            swal('Refresh interval saved successfully.', { icon: 'success' });
            setTimeout(function () { window.location.reload(); }, 2000);
        })
        .catch(error => {
            if(error.response){
                var errorObj = error.response.data;
                swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
            }else{
                swal({text: "Unable to connect to the edge-api service" , icon: "error"});
            }
        });
    }

    const updateCameraView = (value, event) => {
        var jsonData = {
            "namespace": "default",
            "rtsp_enabled": inputs.rtsp_enabled
        }
        var key = event.target.name
        jsonData[key] = value
        axios.patch(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName, jsonData)
        .then(res => {
            event.persist();
            setInputs(inputs => ({...inputs, [key]: value}));
        })
        .catch(error => {
            if(error.response){
                var errorObj = error.response.data;
                swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
            }else{
                swal({text: "Unable to connect to the edge-api service" , icon: "error"});
            }
        });
    }

    const deleteStack = (event) => {
        event.persist();
        swal({text: "Are you sure you want to delete this stream?",
              buttons: ['No', 'Yes'],
              dangerMode: true
        })
        .then((isConfirm) => {
            if (isConfirm) {
                axios.delete(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName + "/all")
                    .then(res => {
                        swal("Stream delete request submitted successfully.", { icon: "success"});
                        props.history.push("./stream-viewer");
                    })
                    .catch(error => {
                        if(error.response){
                            var errorObj = error.response.data;
                            swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
                        } else {
                            swal({text: "Unable to connect to the edge-api service" , icon: "error"});
                        }
                    });
            }
        });
    }

    const editDescription = (event) => {
        setReadOnly(readonly => (false));
    }

    const cancelDescription = (event) => {
        window.location.reload()
    }

    const saveDescription = (event) => {
       var jsonData = {
            "namespace": "default",
            "description": inputs.description,
            "rtsp_enabled": inputs.rtsp_enabled,
            "webrtc_enabled":inputs.webrtc_enabled
        }

        axios.patch(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName, jsonData)
        .then(res => {
            setReadOnly(readonly => (true));
            //swal("Stream information updated successfully.", { icon: "success"});
        })
        .catch(error => {
            if(error.response){
                var errorObj = error.response.data;
                swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
            }else{
                swal({text: "Unable to connect to the edge-api service" , icon: "error"});
            }
        });
        /*
        if (inputs.video_device != undefined) {
            jsonData = recorderState
            jsonData["video_device"] = inputs.video_device
            jsonData["enabled"] = inputs.enabled
            axios.put(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName + "/" + jsonData["service_name"] , jsonData)
            .then(res => {
                swal("Stream information updated successfully.", { icon: "success"});
                setReadOnly(true);
            })
            .catch(error => {
                if(error.response){
                    var errorObj = error.response.data;
                    swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
                }else{
                    swal({text: "The network connection is lost" , icon: "error"});
                }
            });
        } */
    }

    const enableStack = (value, event) => {
        var action = (value ? "start" : "stop")
        var actionLabel = value ? "enable" : "disable";
        var enabled = (actionLabel == "enable")? true : false


        var jsonData = {
            "namespace": "default",
            "action": action,
            "source": "Edge UI",
            "enabled": enabled
        }

        var msg = "Are you sure you want to "+ actionLabel +" this stream?"

        swal({text: msg,
             buttons: ['No', 'Yes'],
             dangerMode: true,
            }).then(function(isConfirm) {
                if (isConfirm) {
                    axios.post(Constants.EDGE_API_ENDPOINT + '/stacks/' + stackName, jsonData)
                        .then(res => {
                            setInputs({...inputs, ["enabled"]: value});
                            swal("Stream " + (value ? "enable" : "disable") +" request submitted successfully.", { icon: "success"});
                        })
                        .catch(error => {
                            if(error.response){
                                var errorObj = error.response.data;
                                swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
                            }else{
                                swal({text: "Unable to connect to the edge-api service" , icon: "error"});
                            }
                        });
                }
            });
    }

    const snapshotIntervalCallback = (intervalTime) => {
        setInputs({...inputs, ["snapshot_refresh_interval"]: intervalTime});
    }
    
    const recorderStateCallback = (stateData) => {
        //console.log(recorderState)
    }

    const deviceListCheckCallBack = (isList) => {
        //console.log(isList)
    }

    const isSafieConfigurCallBack = (configured) => {
        //console.log(configured)
    }

    /*const analysisStateCallback = (stateData) => {
        //console.log(analysisState)
    }*/

    const broadcastingStateCallback = (stateData) => {
        //console.log(broadcastingState)
    }

    const projectStateCallback = (stateData) => {
        //console.log(projectState)
    }

    const streamForwardingStateCallback = (stateData) => {
        //console.log(stateData)
    }

    const captureScheduleStateCallback = (stateData1, stateData2) => {
        //console.log(stateData1)
        //console.log(stateData2)
    }

    const editRefreshInterval = () => {
        setRefreshInterval(false);
    }

    function getDisplayLabel(data, key){
        return (data[key] !== undefined ? data[key] : key)
    }

    function getStreamStatusLabel(stackName){
        try{
            streamStatus = streamStatusData["running_status"]["status"]
            if(streamStatus == "paused" || streamStatus == "disabled"){
                refreshImage = false
            }
        }catch(error){
            console.log(error)
        }
        if(streamStatus !== "running" && streamStatus !== "disabled"){
            return <span className={streamStatus} style={{"fontWeight": "bold", "fontSize": "0.8em", "padding": "5px 0px 0px 50px"}}>{getDisplayLabel(Constants.STATUS_TYPES, streamStatus)}</span>
        } else {
            return ""
        }
    }

    return (
            <ContentDiv>
            { inputs == undefined &&
                <div className="loading-msg">
                    <label>Loading...</label>
                    <CircularProgress/>
                </div>
            }
            { inputs &&
                <div>
                <Wrapper>
                    <SectionHeader>
                        <SectionLabel>Stream Information
                            { streamStatusData &&
                                getStreamStatusLabel()
                            }
                        </SectionLabel>

                        <RightDiv>
                            <LeftDiv>
                                <ButtonSmall onClick={deleteStack}>Delete Stream</ButtonSmall>
                            </LeftDiv>
                            <SelectionControl id="enabled" type="switch" label={inputs.enabled? "Enabled" :"Disabled"} name="enabled" labelBefore="true" checked={inputs.enabled || false} onChange={enableStack} disabled={(inputs.action === "DeleteStack" && inputs.action_status.status === "desired") ? "disabled" : ""}/>
                        </RightDiv>
                    </SectionHeader>
                    <Row>
                        <Column>
                            <div>
                            <MandatoryLabelStream style={{"padding": (isMobileBrowser ? "21px 0px 0px 0px" : "12px 0px 0px 0px") }}>Stream Name</MandatoryLabelStream>
                            <TextField name="stack_name" type="text" value={stackName} disabled={true}/>
                            <Label style={{"padding": (isMobileBrowser ? "15px 0px 0px 0px" : "12px 0px 0px 0px" )}}>Description</Label>
                            <TextArea name="description" rows="1" value={inputs.description} onChange={handleOnChange} disabled={readOnly}/>
                            </div>
                        </Column>
                        {source !== "FTP" && source !== "SAFIE" && <Column>
                        <LeftDiv>
                            <SelectionControl id="rtsp_enabled" type="switch" label={inputs.rtsp_enabled? "RTSP Enabled" :"RTSP Disabled"} name="rtsp_enabled" labelBefore="true" style={{"margin-right":"15px"}} checked={inputs.rtsp_enabled || false} onChange={handleToggleChange}/>
                        </LeftDiv>
                        { rtspState != undefined &&
                        <div>
                            { inputs.rtsp_enabled &&
                            <RightDiv style={{"padding": "10px 0px 0px 0px"}}>
                            {rtspState.rtsp_url_type === "mDNS" &&
                                <Button primary name="rtsp_url_type" onClick={() => handleRtspLabelOnChange("IP")} className={"small-button"} style={{"paddingTop": "10px"}}>{rtspState.rtsp_url_type}</Button>
                            }
                            {rtspState.rtsp_url_type === "IP" &&
                                <Button primary name="rtsp_url_type" onClick={() => handleRtspLabelOnChange("mDNS")} className={"small-button"} style={{"paddingTop": "10px"}}> {rtspState.rtsp_url_type}</Button>
                            }
                                <Button primary onClick={clickToCopyRtspUrl} className={"small-button"}>Copy</Button>
                            </RightDiv>
                            }
                            <TextField name="rtspAddress" type="text" value={ rtspState.rtsp_url_type === "mDNS" ? rtspState.peer_address_mdns : rtspState.peer_address_ip} readOnly/>
                        </div>
                        }
                        <LeftDiv style={{"padding": "12px 0px 0px 0px"}}>
                            <SelectionControl id="webrtc_enabled" type="switch" label={inputs.webrtc_enabled? "WebRTC Enabled" :"WebRTC Disabled"} name="webrtc_enabled" labelBefore="true" style={{"margin-right":"15px"}}  checked={inputs.webrtc_enabled || false} onChange={handleToggleChange} disabled={true}/>
                        </LeftDiv>
                        { webrtcState != undefined &&  webrtcState.sender_port && inputs != undefined &&
                        <div>
                            <RightDiv style={{"padding": "22px 0px 0px 0px"}}>
                            {webrtcState.webrtc_url_type === "mDNS" &&
                                <Button primary name="webrtc_url_type" onClick={() => handleWebrtcLabelOnChange("IP")} className={"small-button"} style={{"paddingTop": "10px"}}>{webrtcState.webrtc_url_type}</Button>
                            }
                            {webrtcState.webrtc_url_type === "IP" &&
                                <Button primary name="webrtc_url_type" onClick={() => handleWebrtcLabelOnChange("mDNS")} className={"small-button"} style={{"paddingTop": "10px"}}>{webrtcState.webrtc_url_type}</Button>
                            }
                            <Button primary onClick={clickToCopy} className={"small-button"}>Copy</Button>
                            </RightDiv>
                            <TextField name="webRTCAddress" type="text" value={ webrtcState.webrtc_url_type === "mDNS" ? webrtcState.peer_address_mdns : webrtcState.peer_address_ip} readOnly/>
                        </div>
                        }
                        </Column>}

                    </Row>
                    { (source === "RTSP" || source === "RTMP" || source === "RTP" || source === "FTP" || source === "SAFIE") &&
                        <Column style={{'width': '100%'}}>
                            <RightDiv>
                                { readOnly == true &&
                                    <Button id="editDescrButton" primary onClick={editDescription}>Edit</Button>
                                }
                                { readOnly == false &&
                                    <div>
                                        <Button primary onClick={saveDescription}>Save</Button>
                                        <Button danger onClick={cancelDescription}>Cancel</Button>
                                    </div>
                                }
                            </RightDiv>
                        </Column>
                    }

                    { source == "USB" &&
                    <Row>
                        <Column>
                            <Label>Video Device</Label>
                            { (recorderState && videoDevices) &&
                                <SelectField name="video_device"  onChange={handleOnChange} disabled={true}>
                                { videoDevices.map((item) => {
                                    if(item.name === recorderState.video_device){
                                        let videoDescription = item.description ? ' (' + item.description + ')' : '';
                                        return (<option value={recorderState.video_device}>{recorderState.video_device + videoDescription}</option>)
                                    }
                                    })
                                }
                                </SelectField>
                            }
                        </Column>
                    </Row>
                    }
                    { source == "USB" &&
                    <Row>
                        <Column></Column>
                        <Column>
                            <RightDiv>
                                { readOnly == true &&
                                    <Button id="editDescrButton" primary onClick={editDescription}>Edit</Button>
                                }
                                { readOnly == false &&
                                    <div>
                                        <Button primary onClick={saveDescription}>Save</Button>
                                        <Button danger onClick={cancelDescription}>Cancel</Button>
                                    </div>
                                }
                            </RightDiv>
                        </Column>
                    </Row>
                    }
                </Wrapper>
                <Spacer/>
                { (recorderState.video_device) &&
                    <div>
                        <Wrapper>
                            <SectionHeader>
                            <SectionLabel>Camera View</SectionLabel>
                            <RightDiv>
                                <LeftDiv>
                                <SelectionControl id="camera_view_still_image" type="switch" label="Still Image" name="camera_view_still_image" labelBefore="true" checked={inputs.camera_view_still_image || false} onChange={updateCameraView}/>
                                </LeftDiv>
                                {( source === "RTP" || source === "RTMP" || source === "RTSP" || source === "USB" ) &&
                                <SelectionControl id="camera_view_movie" type="switch" label="Movie (WebRTC)" name="camera_view_movie" labelBefore="true" checked={inputs.camera_view_movie || false} onChange={updateCameraView}/>
                                }
                            </RightDiv>
                            </SectionHeader>
                            {/* <Row>
                                <Column>
                                    { inputs.camera_view_still_image === true && inputs.enabled === true &&
                                    <div>
                                        <label>Refresh Interval (Secs)</label>
                                        <TextField style={{"marginLeft":"20px", "width":"70px"}} name="snapshot_refresh_interval" type="text" value={inputs.snapshot_refresh_interval} onChange={handleNumberOnChange} onBlur={handleRefreshIntervalOnBlur} maxLength="4"/>
                                    </div>
                                    }
                                </Column>
                                <Column>
                                </Column>
                            </Row> */}
                            <Row>
                                { (inputs.camera_view_still_image === true && inputs.enabled === true) &&
                                <Column style={{ padding: '10px'}}>
                                    { streamStatus === "paused" ?
                                    <div>
                                        <CameraImage stackName={stackName} refreshInterval={refreshImage ? inputs.snapshot_refresh_interval : 0} streamStatus={streamStatus} recorder={recorderState}/>
                                    </div>
                                    :
                                    <div>
                                       <CameraImage stackName={stackName} detailPage={true} refreshInterval={refreshImage ? inputs.snapshot_refresh_interval : 0} streamStatus={streamStatus} recorder={recorderState}/>
                                    </div>
                                    }
                                </Column>
                                }
                                { (inputs.camera_view_still_image === true && inputs.enabled === false) &&
                                <Column>
                                    <img src={ require('../images/no_image.png')} className="no_img"/>
                                </Column>
                                }
                                { (source !== "FTP" && streamStatus !== "paused") &&
                                <Column>
                                { webrtcState != undefined &&  webrtcState.sender_port && inputs != undefined &&
                                <div>
                                    <div id="videoContainer" style={{"text-align": "center"}}>
                                        <div>
                                            <video id="stream" className='videoInsert' autoPlay muted controls width={((isMobileBrowser && webrtcState.video_width >= 1280) ? '480px': 'auto')} height={((isMobileBrowser && webrtcState.video_width >= 1280)? '190px': webrtcState.video_height)} style={{"border": "1px solid lightgray", "margin": "auto", "max-width": "38vw"}}>Your browser doesn't support video</video>
                                        </div>
                                        <div>Status: <span id="status"> { (streamStatusData !== undefined && streamStatusData["capture_schedule_status"] !== undefined && streamStatus === "paused") ? "paused since " + (streamStatusData["capture_schedule_status"]["last_capture_stop"]).replace('_', ' ') : "unknown" } </span></div>
                                    </div>
                                    { (streamStatus!== "" && streamStatus !== "paused") &&
                                    <WebRTCPlayer id={stackName} peerAddress={(window.location.protocol === "http:" ? "ws://" : "wss://") + window.location.host + "/websocket-" + webrtcState.sender_port + "/"} enabled={inputs.camera_view_movie && inputs.enabled} for="videoContainer"/>
                                    }
                                </div>
                                }
                                </Column>
                                }
                            </Row>
                            <Row>
                                <Column>
                                    { inputs.camera_view_still_image === true && inputs.enabled === true &&
                                    <>
                                    </>}
                                    <div>
                                        <label>Refresh Interval (Secs)</label>
                                        <SelectField name="snapshot_refresh_interval" value={inputs.snapshot_refresh_interval} onChange={handleNumberOnChange} style={{'width': '80px', 'marginLeft': '10px'}} disabled={isRefreshInterval}>
                                            <SelectOptions items={Constants.CS_AUTO_REFRESH_INTERVALS}/>
                                        </SelectField>
                                        { isRefreshInterval === true &&
                                            <ButtonSmall id="editRefreshButton" primary onClick={editRefreshInterval} style={{'marginLeft': '10px'}}>Edit</ButtonSmall>
                                        }
                                        { isRefreshInterval === false &&
                                            <>
                                                <ButtonSmall id="saveRefreshButton" primary onClick={saveRefreshInterval} style={{'marginLeft': '10px'}}>Save</ButtonSmall>
                                                <ButtonSmall id="cancelRefreshButton" danger onClick={() =>  window.location.reload()} style={{'marginLeft': '10px'}}>Cancel</ButtonSmall>
                                            </>
                                        }
                                    </div>
                                </Column>
                            </Row>
                        </Wrapper>
                        <Spacer/>
                    </div>
                }
                <Wrapper>
                    <Tabs defaultTab="recordingTab">
                        <TabList>
                            <Tab tabFor="recordingTab" className={`rwt__tab`}>Recording</Tab>
                            {/* <Tab tabFor="analysisTab" className={`rwt__tab`}>Analysis</Tab> */}
                            <Tab tabFor="broadcastingTab" className={`rwt__tab`}>Broadcasting</Tab>
                            <Tab tabFor="streamForwardingTab" className={`rwt__tab`}>Stream Forwarding</Tab>
                            <Tab tabFor="captureScheduleTab" className={`rwt__tab`}>Capture Schedule</Tab>
                        </TabList>
                        { (videoDeviceInfo != undefined || recorderState.camera_type === "RTSP" || recorderState.camera_type === "RTMP" || recorderState.camera_type === "RTP" || recorderState.camera_type === "FTP" || recorderState.camera_type === 'SAFIE') &&
                        <RecordingTab parentCallback={recorderStateCallback} isList={deviceListCheckCallBack} isConfigue={isSafieConfigurCallBack} intervalCallback={snapshotIntervalCallback} streamStatusData={streamStatusData} stackStatus={inputs.enabled} stackName={stackName} data={recorderState} data2={videoDeviceInfo} readOnly={true}/>
                        }
                        {/* <AnalysisTab parentCallback={analysisStateCallback} stackStatus={inputs.enabled} stackName={stackName} data={analysisState} readOnly={true}/> */}
                        <BroadcastingTab parentCallback={broadcastingStateCallback} stackStatus={inputs.enabled} stackName={stackName} data={broadcastingState} readOnly={true} source={source} />
                        <StreamForwardingTab parentCallback={streamForwardingStateCallback} stackStatus={inputs.enabled} stackName={stackName} data={streamForwardingState} readOnly={true}/>
                        <CaptureScheduleTab parentCallback={captureScheduleStateCallback} stackName={stackName} data={inputs.capture_schedule} captureStatus={streamStatusData!== undefined ? streamStatusData["capture_schedule_status"] : undefined} readOnly={true}/>
                    </Tabs>
                </Wrapper>
                </div>
            }
            </ContentDiv>
            )
}

export default ViewStream;