import React, { useState, useEffect, useCallback } from 'react';
import { SelectionControl, CircularProgress } from 'react-md';
import axios from 'axios';
import swal from 'sweetalert';
import { ContentDiv, Wrapper, Label, Spacer, Row, Column, SectionHeader, SectionLabel, RightDiv, TextField, SelectField } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import SelectOptions from '../components/SelectOptions';
import Accordion from './Accordion';
import AutoRefresh from '../components/AutoRefresh';


const CurrentStatus = (props) => {
  const [streamStatusData, setStreamStatusData] = useState();
  const [projectStatusData, setProjectStatusData] = useState();
  const [systemStatusData, setSystemStatusData] = useState();
  const [extensionData, setExtensionData] = useState();

  function getSystemStatus(){
    axios.get(Constants.EDGE_API_ENDPOINT + '/system/status/all')
      .then(res => {
        let systemStacks = res.data.data;
        if(systemStacks.stacks['system-services'] !== undefined){
          setSystemStatusData(systemStacks);
        } else {
          setSystemStatusData({'stacks':{ 'system-services': {'services':{}}}});
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  function getStreamStatus(){
    axios.get(Constants.EDGE_API_ENDPOINT + '/stacks/status/all')
      .then(res => {
        let respData = res.data.data;
        Object.entries(respData.stacks).map(([key, value]) => {
          // Do not show deleted stack
          if(value.running_status.status === 'deleted'){
            delete respData.stacks[key];
          } else {
            if(respData.running_status === undefined){
              respData.running_status = {};
              respData.running_status.status = respData.stacks[key].running_status.status;
            } else if(respData.running_status !== undefined){
              if(respData.running_status !== respData.stacks[key].running_status){
                let existingPriority = Constants.STATUS_PRIORITY[respData.running_status.status];
                let newPriority = Constants.STATUS_PRIORITY[respData.stacks[key].running_status.status];
                if(existingPriority < newPriority){
                  respData.running_status.status = respData.stacks[key].running_status.status;
                }
              }
            }
            // Do not show deleted services
            let servicesList = respData.stacks[key].services;
            Object.entries(servicesList).map(([key1, value1]) => {
              if(value1.running_status.status === 'deleted'){
                delete servicesList[key1];
              }
              return value1;
            });
          }
          return value;
        });
        setStreamStatusData(respData);
      })
      .catch(error => {
        console.log(error);
      });
  }

  const getExtension = async() => {
    await axios.get(Constants.EDGE_API_ENDPOINT + '/sdk/extensions/all')
     .then(res => {
      setExtensionData(res.data.data);
    }).catch((err) => {
      console.log(err)
      setExtensionData({});
    });
  }

  useEffect(() =>{
    getExtension();
  },[])

  const getSDKStatus = async() => {
    let extensionData = {}
    await axios.get(Constants.EDGE_API_ENDPOINT + '/sdk/extensions/all')
     .then(res => {
      extensionData = res.data.data;
    }).catch((err) => {
      console.log(err)
    });
    if(Object.keys(extensionData.stacks).length > 0) {
      axios.get(Constants.EDGE_API_ENDPOINT + '/sdk/status/all')
      .then(res => {
        let respData = res.data.data;
        Object.entries(respData.stacks).map(([key, value]) => {
          Object.entries(extensionData.stacks).map(([keyx, valuex]) => {
            if(key === keyx && Object.keys(valuex.meta).length > 0) {
              if(valuex.meta.hide_from_ui === 'true') {
                delete respData.stacks[key];
              }
            } else {
            if(value.running_status.status === 'deleted'){
              delete respData.stacks[key];
            } else {
              if(respData.running_status === undefined){
                respData.running_status = {};
                respData.running_status.status = respData.stacks[key].running_status.status;
                if(key == keyx && Object.keys(valuex.meta).length > 0 && valuex.meta.display_name) {
                  respData.stacks[key].running_status.display_name = valuex.meta.display_name;
                }
              } else if(respData.running_status !== undefined){
                if(Object.keys(valuex.meta).length > 0 && valuex.meta.display_name && key === keyx ) {
                  respData.stacks[key].running_status.display_name = valuex.meta.display_name;
                }
                if(respData.stacks[key] && respData.running_status !== respData.stacks[key].running_status){
                  let existingPriority = Constants.STATUS_PRIORITY[respData.running_status.status];
                  let newPriority = Constants.STATUS_PRIORITY[respData.stacks[key].running_status.status];
                  if(existingPriority < newPriority){
                    respData.running_status.status = respData.stacks[key].running_status.status;
                  }
                }
              }
              // Do not show deleted services
              if(respData.stacks[key]) {
                let servicesList = respData.stacks[key].services;
                Object.entries(servicesList).map(([key1, value1]) => {
                  if(value1.running_status.status === 'deleted'){
                    delete servicesList[key1];
                  }
                  return value1;
                });
              }
            }
            return valuex;
            }
          })
          return value;
        });
        setProjectStatusData(respData);
      })
      .catch(error => {
        console.log(error);
      });
    } else {
      axios.get(Constants.EDGE_API_ENDPOINT + '/sdk/status/all')
      .then(res => {
        let respData = res.data.data;
        Object.entries(respData.stacks).map(([key, value]) => {
            if(value.running_status.status === 'deleted'){
              delete respData.stacks[key];
            } else {
              if(respData.running_status === undefined){
                respData.running_status = {};
                respData.running_status.status = respData.stacks[key].running_status.status;
              } else if(respData.running_status !== undefined){
                if(respData.stacks[key] && respData.running_status !== respData.stacks[key].running_status){
                  let existingPriority = Constants.STATUS_PRIORITY[respData.running_status.status];
                  let newPriority = Constants.STATUS_PRIORITY[respData.stacks[key].running_status.status];
                  if(existingPriority < newPriority){
                    respData.running_status.status = respData.stacks[key].running_status.status;
                  }
                }
              }
              // Do not show deleted services
              if(respData.stacks[key]) {
                let servicesList = respData.stacks[key].services;
                Object.entries(servicesList).map(([key1, value1]) => {
                  if(value1.running_status.status === 'deleted'){
                    delete servicesList[key1];
                  }
                  return value1;
                });
              }
            }
          return value;
        });
        setProjectStatusData(respData);
      })
      .catch(error => {
        console.log(error);
      });
    }
    
  }

  function getDisplayLabel(data, key){
    return (data[key] !== undefined ? data[key] : key);
  }

  function showHideContent(spanId) {
    if(((document.getElementById(spanId).classList).value).includes('hide')){
      document.getElementById(spanId).classList.remove('hide');
      if(spanId === 'section_system' || spanId === 'section_streams' || spanId === 'section_projects'){
        document.getElementById(spanId + '_arrow').innerHTML = '&#9660;';
      }
    }
    else{
      document.getElementById(spanId).classList.add('hide');
      if(spanId === 'section_system' || spanId === 'section_streams' || spanId === 'section_projects'){
        document.getElementById(spanId + '_arrow').innerHTML = '&#9654;';
      }
    }
  }

  const autoRefreshCallback = useCallback(() => {
    getSystemStatus();
    getStreamStatus();
    getSDKStatus();
  }, []);

  useEffect(() => {
    autoRefreshCallback();
  }, [autoRefreshCallback]);

  return (
    <ContentDiv style={{'overflow':'hidden', 'paddingBottom':'65px'}}>
      { (streamStatusData === undefined && projectStatusData === undefined && systemStatusData === undefined) &&
        <div className="loading-msg">
          <label>Loading...</label>
          <CircularProgress id="circularProgress"/>
        </div>
      }

      <Wrapper>
        <SectionHeader>
          <SectionLabel>Current Status</SectionLabel>
          <AutoRefresh autoRefresh={autoRefreshCallback} pageName="current_status"/>
        </SectionHeader>
      </Wrapper>

      { systemStatusData &&
        <Wrapper>
          <SectionHeader onClick={() => showHideContent('section_system')} style={{'cursor':'pointer', 'backgroundColor': '#fbfbfb'}}>
            <div style={{'float':'left', 'padding':'5px 22px 0px 0px'}}>
              <span id="section_system_arrow" style={{'color':'#4bb5f4'}}>&#9654;</span>
            </div>
            <SectionLabel style={{'cursor':'pointer'}}>System </SectionLabel>
            { systemStatusData.stacks['system-services'].running_status &&
                <label className={systemStatusData.stacks['system-services'].running_status.status} style={{'float': 'right'}}>
                  {getDisplayLabel(Constants.STATUS_TYPES, systemStatusData.stacks['system-services'].running_status.status)}
                </label>
            }
          </SectionHeader>
          <Row id="section_system" className="hide">
            <Column style={{'width':'100%'}}>
              { Object.keys(systemStatusData.stacks['system-services'].services).length === 0 &&
                <Label>System services not available.</Label>
              }
              <Accordion allowMultipleOpen showMenu={false}>
                {
                  Object.entries(systemStatusData.stacks['system-services'].services).map(([key,value]) => {
                    return(
                      <div label={getDisplayLabel(Constants.SYSTEM_SERVICES_NAME_LIST, key)} stackName={key} status={value.running_status.status} stackType="system" stackSubType="" className="stackName">
                        {
                          Object.entries(value).map(([key1,value1]) => {
                            if(key1 === 'running_status'){
                              return(
                                <div className="stackServiceNamekey">
                                  <div style={{ 'paddingBottom': '10px'}}>
                                    <Row><b>Status: </b>{getDisplayLabel(Constants.STATUS_TYPES, value1.status)}</Row>
                                    <Row><b>Status Message: </b>{value1.status_message}</Row>
                                    <Row><b>Status Time: </b>{value1.status_time.replace('_', ' ')}</Row>
                                    <Row><b>Log: </b><br/><span style={{'white-space': 'pre-line'}}>{value.running_status.log}</span></Row>
                                  </div>
                                  <p onClick={() => showHideContent('system-services_' + key)} style={{'cursor':'pointer', 'color':'#551A8B'}}><b>Parameters</b><br /></p>
                                  <span id={'system-services_' + key} className="parameters hide">
                                    {value.env_variables === null ? <div /> : <>
                                      {
                                        Object.entries(value.env_variables).map(([key3, value3]) => {
                                          return(
                                            <span>{value3}<br/></span>
                                          );
                                        })
                                      }
                                    </>}
                                  </span>
                                </div>
                              );
                            }
                            return '';
                          })
                        }
                      </div>
                    );
                  })
                }
              </Accordion>
            </Column>
          </Row>
        </Wrapper>
      }

      { streamStatusData &&
        <Wrapper>
          <SectionHeader onClick={() => showHideContent('section_streams')} style={{'cursor':'pointer', 'backgroundColor': '#fbfbfb'}}>
            <div style={{'float':'left', 'padding':'5px 22px 0px 0px'}}>
              <span id="section_streams_arrow" style={{'color':'#4bb5f4'}}>&#9654;</span>
            </div>
            <SectionLabel id="streamStatus" style={{'cursor':'pointer'}}>Streams</SectionLabel>
            { streamStatusData.running_status &&
                    <label className={streamStatusData.running_status.status} style={{'float': 'right'}}>
                      {getDisplayLabel(Constants.STATUS_TYPES, streamStatusData.running_status.status)}
                    </label>
            }
          </SectionHeader>
          <Row id="section_streams" className="hide">
            <Column style={{'width':'100%'}}>
              { Object.keys(streamStatusData.stacks).length === 0 &&
                <Label>Stream not available.</Label>
              }
              <Accordion allowMultipleOpen showMenu={true}>
                {
                  Object.entries(streamStatusData.stacks).map(([key,value]) => {
                    let disableMenu = (value.running_status.status === 'desired' && value.running_status.status_message === 'DeleteStack') ? 'disabled' : '';
                    return(
                      <div label={key} status={value.running_status.status} stackName={key} stackType="streams" stackSubType="" disableMenu={disableMenu} className="stackName">
                        {
                          Object.entries(value).map(([key1,value1]) => {
                            if(key1 === 'running_status'){
                              return(
                                <div style={{'paddingBottom': '10px'}}>
                                  <Row><b>Status: </b>{getDisplayLabel(Constants.STATUS_TYPES, value1.status)}</Row>
                                  <Row><b>Status Message: </b>{value1.status_message}</Row>
                                  <Row><b>Status Time: </b>{value1.status_time.replace('_', ' ')}</Row>
                                </div>
                              );
                            }
                            else if(key1 === 'services'){
                              return(
                                <Accordion allowMultipleOpen showMenu={false}>
                                  {
                                    Object.entries(value1).map(([key2,value2]) => {
                                      let serviceName = key2.replace('1', '');
                                      return(
                                        <div label={serviceName} status={value2.running_status.status} className="stackServiceNamekey">
                                          <div style={{ 'paddingBottom': '10px'}}>
                                            <Row><b>Status: </b>{getDisplayLabel(Constants.STATUS_TYPES, value2.running_status.status)}</Row>
                                            <Row><b>Status Message: </b>{value2.running_status.status_message}</Row>
                                            <Row><b>Status Time: </b>{value2.running_status.status_time.replace('_', ' ')}</Row>
                                            <Row><b>Log: </b><br/><span style={{'white-space': 'pre-line'}}>{value2.running_status.log}</span ></Row>
                                          </div>
                                          <p onClick={() => showHideContent(key + '_' + key2)} style={{'cursor':'pointer', 'color':'#551A8B'}}><b>Parameters</b><br/></p>
                                          <span id={key + '_' + key2} className="parameters hide">
                                            { value2.env_variables &&
                                    Object.entries(value2.env_variables).map(([key3, value3]) => {
                                      return(
                                        <span>{value3}<br/></span>
                                      );
                                    })
                                            }
                                          </span>
                                        </div>
                                      );
                                    })
                                  }
                                </Accordion>
                              );
                            }
                            return value1;
                          })
                        }
                      </div>
                    );
                  })
                }
              </Accordion>
            </Column>
          </Row>
        </Wrapper>
      }

      { projectStatusData &&
        <Wrapper>
          <SectionHeader onClick={() => showHideContent('section_projects')} style={{'cursor':'pointer', 'backgroundColor': '#fbfbfb'}}>
            <div style={{'float':'left', 'padding':'5px 22px 0px 0px'}}>
              <span id="section_projects_arrow" style={{'color':'#4bb5f4'}}>&#9654;</span>
            </div>
            <SectionLabel style={{'cursor':'pointer'}}>SDK</SectionLabel>
            { projectStatusData.running_status &&
                    <label className={projectStatusData.running_status.status} style={{'float': 'right'}}>
                      {getDisplayLabel(Constants.STATUS_TYPES, projectStatusData.running_status.status)}
                    </label>
            }
          </SectionHeader>
          <Row id="section_projects" className="hide">
            <Column style={{'width':'100%'}}>
              { Object.keys(projectStatusData.stacks).length === 0 &&
                <Label>Instance not available</Label>
              }
              <Accordion allowMultipleOpen showMenu={true}>
                {
                  Object.entries(projectStatusData.stacks).map(([key, value]) => {
                    let deleteButtonDisable = value.type === 'extension' ? 'disabled' : '';
                    let keyName = (Object.keys(extensionData.stacks).includes(key) && Object.keys(extensionData.stacks[key].meta).length > 0 && extensionData.stacks[key].meta.display_name) ? extensionData.stacks[key].meta.display_name : (key.includes('custom_') ? key.slice(7) : key);
                    let disableMenu = ((value.running_status.status === 'desired' && value.running_status.status_message === 'DeleteStack') || (value.type === 'extension')) ? 'disabled' : '';
                    return(
                      <div label={Constants.SDK_TYPES[value.type] + ' - ' + keyName + ''} stackName={key} status={value.running_status.status} stackType="sdk" stackSubType={value.type} disableMenu={disableMenu} deleteButtonDisable={deleteButtonDisable} show className="stackName">
                        {
                          Object.entries(value).map(([key1,value1]) => {
                            if(key1 === 'running_status'){
                              return(
                                <div style={{'paddingBottom': '10px'}}>
                                  <Row><b>Status: </b>{getDisplayLabel(Constants.STATUS_TYPES, value1.status)}</Row>
                                  <Row><b>Status Message: </b>{value1.status_message}</Row>
                                  <Row><b>Status Time: </b>{value1.status_time.replace('_', ' ')}</Row>
                                </div>
                              );
                            }
                            else if(key1 === 'services'){
                              return(
                                <Accordion allowMultipleOpen showMenu={false}>
                                  {
                                    Object.entries(value1).map(([key2,value2]) => {
                                      return(
                                        <div label={key2.includes('custom_') ? key2.slice(7) : key2} status={value2.running_status.status} className="stackServiceNamekey">
                                          <div style={{'paddingBottom': '10px'}}>
                                            <Row><b>Status: </b>{getDisplayLabel(Constants.STATUS_TYPES, value2.running_status.status)}</Row>
                                            <Row><b>Status Message: </b>{value2.running_status.status_message}</Row>
                                            <Row><b>Status Time: </b>{value2.running_status.status_time.replace('_', ' ')}</Row>
                                            <Row><b>Log:</b><br/><span style={{'white-space': 'pre-line'}}>{value2.running_status.log}</span></Row>
                                          </div>
                                          <p onClick={() => showHideContent(key + '_' + key2)} style={{'cursor':'pointer', 'color':'#551A8B'}}><b>Parameters</b><br /></p>
                                          <span id={key + '_' + key2} className="parameters hide">
                                            { value2.env_variables &&
                                            Object.entries(value2.env_variables).map(([key3, value3]) => {
                                              return(
                                                <span>{value3}<br/></span>
                                              );
                                            })
                                            }
                                          </span>
                                        </div>
                                      );
                                    })
                                  }
                                </Accordion>
                              );
                            }
                            return '';
                          })
                        }
                      </div>
                    );
                  })
                }
              </Accordion>
            </Column>
          </Row>
        </Wrapper>
      }
    </ContentDiv>
  );
};

export default CurrentStatus;
