import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CircularProgress } from 'react-md';
import NoImageBackground from '../images/no_image.png';
import LoadingImage from '../images/loading_image.png';
import * as Constants from '../utils/Constants';
import Lightbox from "../library/react-awesome-lightbox/";
import "../library/react-awesome-lightbox/style.css";
import {Label} from '../utils/Styles';
import { Media } from 'react-md';

const CameraImage = (props) => {
    const [imageSizePercentage, setImageSizePercentage] = useState(1);
    const [thumbnailClicked, setThumbnailClicked] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [lbImgSrc, setLbImgSrc] = useState('');
    const [imageSize, setImageSize] = useState(undefined); 
    const [isMobileBrowser, setIsMobileBrowser] = useState(false);
    const [ingestTime, setIngestTime] = useState();
    var stackName = props.stackName;
    var isListPage = props.isListPage === undefined ? false : props.isListPage;
    var imageWidth = 1920;
    var imageHeight = 1080;
    var ftpParam = "";
    var snapshotWidth = "auto";
    var snapshotHeight = "270";
    var isError = false;
    var streamSourceType = props.recorder["camera_type"];
    if (streamSourceType == "USB"){
        imageWidth = props.recorder["video_width"];
        imageHeight = props.recorder["video_height"];
        if(imageWidth >= 1280){
            if(isMobileBrowser) {
                snapshotWidth =372;
                snapshotHeight = 210;
            } else {
                snapshotWidth = 480;
                snapshotHeight = 270;
            }      
        } else {
            snapshotWidth = 320;
            snapshotHeight = 240;
        }
    } else {
        snapshotWidth = "auto";
        snapshotHeight = 270;
    }

    if (streamSourceType === "FTP"){
        ftpParam = "ingest_time=true&save_original=true&fallback=true&"
    }

    if(streamSourceType === 'SAFIE') {
        ftpParam = "ingest_time=true&save_original=true&fallback=true&"
    }

    let snapshotApiUrl = Constants.EDGE_API_ENDPOINT + "/stacks/" + stackName + "/snapshot";
    let snapshotTumbnailApiUrl = snapshotApiUrl + "?width=" + snapshotWidth + "&height=" + snapshotHeight + "&" + ftpParam
    let snapshotFullImageApiUrl = snapshotApiUrl + "?" + ftpParam

    var topMargin = getTopMargin()
    var isLoading = true
    function getTopMargin(){
        let topMargin = 0
        if(imageHeight > 0){
            topMargin = (window.innerHeight - (imageHeight * 0.9 * imageSizePercentage) - 160)/2
            topMargin = (topMargin <= -1) ? 0 : topMargin;
        }
        return topMargin;
    }

    const increaseSize = (event) => {
        if(imageSizePercentage < 4.0){
            document.getElementById("camImage").style.width = (imageWidth * (imageSizePercentage + 0.1)) + "px";
            setImageSizePercentage(imageSizePercentage + 0.1);
        }
    }

    const decreaseSize = (event) => {
        if(imageSizePercentage > 0.2){
            document.getElementById("camImage").style.width = (imageWidth * (imageSizePercentage - 0.1)) + "px";
            setImageSizePercentage(imageSizePercentage - 0.1);
        }
    }

    function encodeImage(arrayBuffer) {
        let u8 = new Uint8Array(arrayBuffer)
        let b64encoded = btoa([].reduce.call(new Uint8Array(arrayBuffer),function(p,c){return p+String.fromCharCode(c)},''))
        let mimeType="image/jpg"
        return "data:"+mimeType+";base64," + b64encoded
    }

    function getThumbnailImage(){
        isLoading = true
        let imageElement = document.getElementById(props.recorder["video_device"] + "Img");
        let finalUrl = snapshotTumbnailApiUrl + "timestamp=" + Date.now();
        axios.get(finalUrl, {responseType: 'arraybuffer'})
            .then(res => {
                if("content-type" in res.headers && res.headers["content-type"] !== "image/jpg"){
                    if (streamSourceType === 'RTMP' || streamSourceType === 'RTP' || streamSourceType === 'USB' || streamSourceType === 'RTSP'){
                        imageElement.classList.add("no_img");
                        imageElement.classList.remove("running_img");
                        isError = true;
                    }
                    isLoading = false
                } else {
                    imageElement.src = encodeImage(res.data);
                    if("ingest_time" in res.headers){
                        document.getElementById("ingest_time_thumbnail_" + props.recorder["video_device"]).innerHTML = "Time Fetched: " + res.headers["ingest_time"];
                    }
                    if(("is_latest" in res.headers && res.headers["is_latest"] === "false") ||
                        (props.streamStatus != undefined && props.streamStatus === "paused") || isError){
                        imageElement.classList.add("no_img");
                        imageElement.classList.remove("running_img");
                    } else {
                        imageElement.classList.add("running_img");
                        imageElement.classList.remove("no_img");
                    }
                    isError = false;
                    isLoading = false;
                }
            })
            .catch(error => {
                if (streamSourceType === 'RTMP' || streamSourceType === 'RTP' || streamSourceType === 'USB' || streamSourceType === 'RTSP'){
                    imageElement.classList.add("no_img");
                    imageElement.classList.remove("running_img");
                    isError = true;
                }
                isLoading = false;
            });
    }

    function getThumbnailIngestTime(){
        let finalUrl = snapshotTumbnailApiUrl + "timestamp=" + Date.now();
        axios.get(finalUrl, {responseType: 'arraybuffer'})
            .then(res => {
                if (streamSourceType === 'SAFIE' || streamSourceType === 'FTP')
                setIngestTime(res.headers.ingest_time);
            })
            .catch(error => {
                console.log(error)
            });
    }

    useEffect(() => {
        if(props.refreshInterval !== undefined && props.refreshInterval !== "" && thumbnailClicked === false){
            if(parseInt(props.refreshInterval)===0){
                if(props.streamStatus != undefined && props.streamStatus === "paused"){
                    snapshotTumbnailApiUrl = snapshotTumbnailApiUrl + "latest=false&";
                }
                getThumbnailImage()
                getThumbnailIngestTime()
                return;
            } else {
                getThumbnailImage()
                const interval = setInterval(() => {
                    if(!isLoading && !thumbnailClicked){
                        getThumbnailImage()
                        getThumbnailIngestTime()
                    }
                }, parseInt(props.refreshInterval) * 1000);

                return () => {
                    clearInterval(interval);
                };
            }
        }
    }, [props.refreshInterval, thumbnailClicked]);

    const getFullImage = () => {
        let finalUrl = snapshotFullImageApiUrl + "timestamp=" + Date.now();
          axios.get(finalUrl, {responseType: 'arraybuffer'})
            .then(res => {
                if("content-type" in res.headers && res.headers["content-type"] == "image/jpg"){
                    let encodedImgData = encodeImage(res.data);
                    setLbImgSrc(encodedImgData);
                    let tmpImg = new Image();
                    tmpImg.src = encodedImgData;
                    tmpImg.onload = function(){
                        setImageSize({'width': tmpImg.width, 'height': tmpImg.height});
                    };
                }
            })
            .catch(error => {
              console.error(error);
            });
        }

    const thumbnailOnClick = () => {
        setThumbnailClicked(true);
        getFullImage();
    }

    useEffect(()=>{
        var isMobileBrowser = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if(isMobileBrowser) {
          setIsMobileBrowser(isMobileBrowser);
        } else {
          setIsMobileBrowser(isMobileBrowser);
        }
      },[]);

    return (
        <div>
            {thumbnailClicked === true && imageSize &&
            <>  
            { lbImgSrc === '' ||
            <>
             {imageSize.width < 1366 ? 
                <Lightbox
                    image={lbImgSrc === '' ? require('../images/loading.png') : lbImgSrc } 
                    onClose={() => setThumbnailClicked(false)}
                    title={props.stackName}
                    detailPage= {props.detailPage}
                    isMobile={isMobileBrowser}
                    keyboardInteraction
                    width={imageSize.width}
                    height={imageSize.height}
                    resetButton={[
                        <button title='Reset to original image' className='reload-button'>Reset</button>,
                    ]}
                    refreshButton={[
                        <button title='Get latest image' className='reload-button' onClick={getFullImage}>Refresh</button>,
                    ]}
                />
             :
             <>
             <Media>
                <Lightbox 
                    image={lbImgSrc === '' ? require('../images/loading.png') : lbImgSrc } 
                    onClose={() => setThumbnailClicked(false)}
                    title={props.stackName}
                    detailPage= {props.detailPage}
                    isMobile={isMobileBrowser}
                    keyboardInteraction
                    width={imageSize.width}
                    height={imageSize.height}
                    resetButton={[
                        <button title='Reset to original image' className='reload-button'>Reset</button>,
                    ]}
                    refreshButton={[
                        <button title='Get latest image' className='reload-button' onClick={getFullImage}>Refresh</button>,
                    ]}
                />
            </Media>
           </>}
             </>
            }
             </>}

            {!isListPage && 
            <div style={{"text-align": "center", "overflow": "auto", 'position': 'relative', alignItems:'center'}}>
                <img id={`${props.recorder["video_device"] + "Img"}`} src={imageUrl === '' ? require('../images/loading.png'): imageUrl } style={{ "width": snapshotWidth, "height": snapshotHeight, "display":"block", "margin": "auto", "backgroundImage": `url(${NoImageBackground})`, "max-width": "38vw", "cursor": "pointer"}} onClick={thumbnailOnClick} />
                { (streamSourceType === "SAFIE" || streamSourceType === "FTP") &&
                    <div>
                        {ingestTime === undefined || ingestTime === '' ? <Label style={{'marginTop':'10px'}}><p>Fetching ingest time...</p> </Label> : <Label style={{'marginTop':'10px'}}><p>Ingest Time: {ingestTime}</p></Label>}
                    </div>
                }
                {thumbnailClicked === true && 
                <>
                    { lbImgSrc === '' && 
                        <CircularProgress style={{'width': '100px', 'position': 'absolute', left: (window.innerWidth === 1920 ? '42%' : '40%'), top: '40%', height: '50px', 'transform': 'translate(0%, -100%)'}}/> 
                    }
                </>
                }
            </div>}
            
            { isListPage && 
                <div style={{"text-align": "center", "overflow": "auto"}}>
                <img id={`${props.recorder["video_device"] + "Img"}`} src={imageUrl === '' ? require('../images/loading.png'): imageUrl } style={{ "width": "auto", "height": snapshotHeight, "display":"block", "margin": "auto", "backgroundImage": `url(${NoImageBackground})`, "max-width": "38vw", "cursor": "pointer"}} onClick={thumbnailOnClick} />
                { (streamSourceType === "FTP" && isListPage === false) &&
                    <div>
                        <label id={`${"ingest_time_thumbnail_" + props.recorder["video_device"]}`}></label>
                    </div>
                }
                {thumbnailClicked === true &&
                <>
                    { lbImgSrc === '' && 
                        <div className="loading-msg" >
                            <CircularProgress style={{'width': '80px'}}/>
                        </div>
                    }
                </>
                }
            </div>}
        </div>
        )
}

export default CameraImage;
