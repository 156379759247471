import React, { useState, useEffect } from 'react'
import { Tabs, Tab, TabList } from 'react-web-tabs';
import axios from 'axios';
import { ContentDiv, Wrapper, Label, LeftDiv, Spacer, TextField, TextArea, SelectField, 
Row, Column, RightDiv, SectionHeader, Button, ButtonWrapper, SectionLabel } from '../utils/Styles';
import SelectOptions from '../components/SelectOptions';
import * as Constants from '../utils/Constants';
import "../css/react-tabs.css"
import { SelectionControl } from 'react-md';
import swal from "sweetalert";


const Power = (props) => {
    const [inputs, setInputs] = useState(Constants.DEVICE_CONTROL_DEFAULT_VALUES);
    const source = props.match.path.includes("reboot") ? "Reboot":"Shutdown";

    const handleNumberOnChange = (event) => {
        event.persist();
        event.target.value = event.target.value.replace(/[^0-9]/g, '');
        setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
    }

    function prepareRequestJson (jsonData) {
        if (jsonData["delay"] == "" || jsonData["delay"] == undefined) {
            swal("Please enter delay in seconds.")
            return;
        }

        jsonData ["action"] = source
        jsonData ["delay"] = jsonData["delay"]
        jsonData ["source"] = "Edge UI"
        
        return jsonData
    }


    function sendDeviceControlRequest (jsonData) {
        axios.post(Constants.EDGE_API_ENDPOINT + '/devices/power', jsonData)
        .then(res => {
            swal("Device " + source.toLowerCase() + " request submitted successfully.", { icon: "success" });
        })
        .catch(error => {
            if(error.response){
                var errorObj = error.response.data;
                swal("Error Code: " + errorObj.error.code +"\nError Message: " + errorObj.error.message, { icon: "error"});
            }else{
                swal({text: "Unable to connect to the edge-api service" , icon: "error"});
            }
        });

    }

    const handleSubmit = (event) => {
        if(event)
            event.preventDefault();

        var jsonData = Object.assign({}, inputs)
        jsonData = prepareRequestJson(jsonData)
        if (jsonData == undefined) {
            return;
        }

        swal({
                text: "Are you sure you want to " + source.toLowerCase() + " this device?",
                buttons: ['No', 'Yes'],
                dangerMode: true

            })
            .then((isConfirm) => {
                if (isConfirm) {
                    sendDeviceControlRequest(jsonData)
                }
            });
    }

    return (
            <ContentDiv>
                <Wrapper>
                    <SectionHeader>
                        <SectionLabel>{source} Device</SectionLabel>
                    </SectionHeader>
                    <Row>
                        <Column>
                          <Label style={{'marginRight': '10px'}}>{source} After</Label>
                          <TextField name="delay" type="text" value={inputs.delay} onChange={handleNumberOnChange} maxLength="5" style={{'width': '12%'}} />
                          <Label style={{'marginLeft': '10px', 'marginRight': '10px'}}>Seconds</Label>
                          <Button primary onClick={handleSubmit}>Submit</Button>
                        </Column>
                    </Row>

                </Wrapper>
            </ContentDiv>
            )
}

export default Power;