import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { SelectionControl, CircularProgress } from 'react-md';
import { Row, Button, SectionHeader, SectionLabel, CenterDiv, Label, Td, Th, overlayStyle, TableContainer, PopupButtons, PopupContainer, TextFieldLabel, TextFieldSmall, MandatoryLabel, Link1, LinkIcon } from '../utils/Styles';
import MetabaseLink from './MetaBaseLink'
import * as Constants from '../utils/Constants';
import { isEmpty } from '../utils/Utils';
import swal from 'sweetalert';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import format from 'date-fns/format';


const TableLayout = styled(TableContainer)`
  overflow: auto;
  overflow-y: hidden;
`;

const Wrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  text-align: left;
  display: inline-block;
`;

const ContentDiv = styled.div`
  width: 82vw;
  overflow-y: auto;
  padding-bottom: 10px;
`;

const Container = styled.div`
  padding: 0px 20px 20px 20px;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const OnRefreshContext = React.createContext({
  onRefresh: false
});

const Metabase = (props) => {
  const [isEditPopup, setEditPopup] = useState(true);
  const [metabaseData, setMetabaseData] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedInstance, setSelectedInstance] = useState({});
  const [loading, setLoading] = useState(false);
  const [onRefresh, setOnRefresh] = useState(false);
  const [isMobileBrowser, setIsMobileBrowser] = useState(false);

  const contentStyle = {
    margin: 'auto',
    background: 'rgb(255, 255, 255)',
    width: 'fit-content',
    height: 'auto',
    padding: '5px',
    minWidth: '25%',
    border: '2px solid #d7d7d7'
  };

  const instNameStyle = {
    textAlign: 'left',
    cursor: 'pointer',
    maxWidth: '100px'
  };

  const nickNameStyle = {
    textAlign: 'left',
    cursor: 'pointer'
  };

  useEffect(()=>{
    var isMobileBrowser = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if(isMobileBrowser) {
      setIsMobileBrowser(isMobileBrowser);
    } else {
      setIsMobileBrowser(isMobileBrowser);
    }
  },[]);

  const truncate = (input) =>{
    if(isMobileBrowser) {
      if (input.length > 12) {
        return input.substring(0, 12) + '...';
     }
    } else {
      if(window.innerWidth <= 1024) {
        if (input.length > 15) {
          return input.substring(0, 15) + '...';
       }
      } else {
        if (input.length > 28) {
          return input.substring(0, 28) + '...';
       }
      }
    }
    return input;
  };

  const editInstance = () => {
    setEditPopup(true);
    setModalOpen(true);
  };

  const closePopup = (event) => {
    setModalOpen(false);
  };

  const addNewInstance = (event) => {
    setEditPopup(false);
    setSelectedInstance(undefined);
    setModalOpen(true);
  };

  const radioButtonOnChange = (name) => {
    let instData = Object.assign({}, metabaseData.stacks[name].services.metabase1);
    instData.instance_id = name;
    instData.nickname = metabaseData.stacks[name].nickname;
    setSelectedInstance(instData);
    setEditPopup(true);
  };

  const enableToggle = (value, event) => {
    let metabase_name = event.target.name;

    var jsonData = {
      'namespace': Constants.NAMSPACES.SDK,
      'type': Constants.SDK_SERVICE_TYPES.BI_METABASE,
      'action': value ? Constants.SDK_ACTIONS.ENABLE : Constants.SDK_ACTIONS.DISABLE
    };

    axios.post(Constants.EDGE_API_ENDPOINT + '/sdk/metabase/' + metabase_name + '/all', jsonData)
      .then(res => {
        swal('Instance ' + (value ? 'enable' : 'disable') + ' request submitted successfully.', { icon: 'success' });
        setTimeout(function () { window.location.reload(); }, 2000);
      })
      .catch(error => {
        if (error.response) {
          var errorObj = error.response.data;
          swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
        } else {
          swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
        }
      });
  };

  const getMetabase = useCallback(() => {
    axios.get(Constants.EDGE_API_ENDPOINT + '/sdk/metabase/all')
      .then(res => {
        let data = res.data.data;
        if(Object.keys(res.data.data.stacks).length === 0) {
          setEditPopup(false);
        }
        let sortedData = [];
        if('stacks' in data){
          Object.keys(data.stacks).map((stackName) => {
            let stackData = data.stacks[stackName];
            stackData.name = stackName;
            sortedData.push(stackData);
            return stackName;
          });
          sortedData.sort(function(a,b){
            const dateFormatB = b.created_time.split(' ');
            const dateFormatA = a.created_time.split(' ');
            let dateA = dateFormatA[0] + ' ' + dateFormatA[1];
            let dateB = dateFormatB[0] + ' ' + dateFormatB[1];
            return new Date(dateB) - new Date(dateA);
          });
        }
        data["sorted_list"] = sortedData;
        if(Object.keys(data.sorted_list).length !== 0) {
          let name = data.sorted_list[0].name;
          let instData = Object.assign({}, data.stacks[name].services.metabase1);
          instData.instance_id = name;
          instData.nickname = data.sorted_list[0].nickname;
          setSelectedInstance(instData);
          setEditPopup(true);
        }

        axios.get(Constants.EDGE_API_ENDPOINT + '/sdk/metabase/status/all')
          .then(res => {
            let metaStatusData = res.data.data;
            if ('stacks' in metaStatusData) {
              let metaStatusList = metaStatusData.stacks;
              Object.keys(metaStatusList).map((mName) => {
                if (mName in data.stacks) {
                  data.stacks[mName].status = metaStatusList[mName];
                }
                return mName;
              });
              setMetabaseData(data);
            } else {
              setMetabaseData(data);
            }
            setLoading(false);
            setOnRefresh(false);
          })
          .catch(err => {
            console.log(err);
            setMetabaseData(data);
            setLoading(false);
            setOnRefresh(false);
          });
      }).catch(error => {
        console.log(error);
        setMetabaseData([]);
        setLoading(false);
      });
  }, []);

  function getDisplayLabel(data, key) {
    return (data[key] !== undefined ? data[key] : key);
  }

  const refreshInstances = (event) => {
    setLoading(true);
    setOnRefresh(true);
    document.getElementById('btnRefreshInstance').textContent = 'Refreshing...';
    getMetabase();
  };

  useEffect(() => {
    if (document.getElementById('btnRefreshInstance')) {
      document.getElementById('btnRefreshInstance').textContent = 'Refresh';
    }
  }, [metabaseData]);

  useEffect(() => {
    getMetabase();
  }, [getMetabase]);

  return (
    <ContentDiv>
      { (metabaseData === undefined) &&
        <div className="loading-msg">
          <label>Loading...</label>
          <CircularProgress />
        </div>
      }
      {metabaseData &&
        <Wrapper style={{ 'padding': '20px' }}>
          <SectionHeader>
            <SectionLabel>Metabase</SectionLabel>
          </SectionHeader>
          <Row>
            <ActionButtonContainer>
              { isEditPopup &&
                <Button primary id="btnEditInstance" name="btnEditInstance" onClick={() => editInstance()}>Edit</Button>
              }
              {metabaseData.stacks && Object.keys(metabaseData.stacks).length > 0 &&
                <Button primary id="btnRefreshInstance" name="btnRefreshInstance" disabled={loading} onClick={() => refreshInstances()}>Refresh</Button>
              }
              <Button primary id="btnAddInstance" name="btnAddInstance" onClick={() => addNewInstance()}>Add</Button>
              <Popup modal open={modalOpen} onClose={closePopup} {...{ contentStyle, overlayStyle }}>
                <MetabasePopup isEditPopup={isEditPopup} closePopup={closePopup} selected_metabase={selectedInstance} metabaseData={metabaseData} />
              </Popup>
            </ActionButtonContainer>
          </Row>
          {metabaseData.stacks && Object.keys(metabaseData.stacks).length > 0 &&
            <TableLayout>
              <table style={{ 'min-width': '100%', 'border-collapse': 'collapse' }}>
                <tr style={{ 'background-color': '#1f303a', 'color': 'white' }}>
                  <Th style={{'width': '50px'}}></Th>
                  <Th style={{'text-align': 'left', 'width': '300px'}}>Nickname <br /> (Instance ID)</Th>
                  <Th>Status</Th>
                  <Th>Created</Th>
                  <Th>Dashboard</Th>
                  <Th>Enabled</Th>
                </tr>

                { Object.keys(metabaseData.sorted_list).map((index) => {
                  let instName = metabaseData.sorted_list[index].name;
                  let mData = metabaseData.sorted_list[index].services.metabase1;
                  let nickname =  metabaseData.stacks[instName].nickname ?  metabaseData.stacks[instName].nickname : '';
                  let created = metabaseData.stacks[instName].created_time;
                  let mStatus = 'recovering';
                  if('status' in metabaseData.stacks[instName]){
                    mStatus = metabaseData.stacks[instName].status.running_status.status;
                  }

                  return (
                    <tr>
                      <Td><input type="radio" id="select_radio" style={{ 'cursor': 'pointer' }} defaultChecked={index==='0'} name="select_radio" value={mData.instance_id} onClick={() => radioButtonOnChange(mData.instance_id)}></input></Td>
                      <Td>
                        <div style={{'display': 'flex', 'flexDirection': 'column'}}>
                            <div style={nickNameStyle} title={nickname}>{truncate(nickname)}</div>
                            <div style={instNameStyle} title={instName}>({truncate(instName + ')')}</div>
                        </div>
                      </Td>
                      <Td>
                        {mStatus === '-' ?
                          '-' :
                          <label className={mStatus + '-sdk'}>
                            {getDisplayLabel(Constants.STATUS_TYPES, mStatus)}
                          </label>}
                      </Td>
                      <Td>{created}</Td>
                      <Td>
                        <OnRefreshContext.Provider value={{ onRefresh }}>
                         <MetabaseLink port= {mData.port} enabled={metabaseData.stacks[instName].enabled}/>
                        </OnRefreshContext.Provider>
                      </Td>
                      <Td><SelectionControl type="switch" id={mData.instance_id} name={mData.instance_id} value={metabaseData.stacks[instName].enabled} checked={metabaseData.stacks[instName].enabled} labelBefore="true" onChange={enableToggle} style={{ 'display': 'block', 'paddingTop': '10px' }} /></Td>
                    </tr>
                  );
                })}
              </table>
            </TableLayout>
          }
          {metabaseData.stacks && Object.keys(metabaseData.stacks).length === 0 &&
            <CenterDiv>
              <Label>No instance found.</Label>
            </CenterDiv>
          }
        </Wrapper>}

    </ContentDiv>
  );
};

function getDefaultMetaBaseData() {
  return Constants.METABASE_DEFAULT_VALUE;
}

const MetabasePopup = (props) => {
  const { isEditPopup, closePopup, selected_metabase, metabaseData } = props;
  const [metabaseSettings, setMetabaseSettings] = useState(selected_metabase === undefined ? getDefaultMetaBaseData() : selected_metabase);

  useEffect(()=>{
    if(metabaseSettings.instance_id === ''){
      if(metabaseData.sorted_list.length !== undefined){
        setMetabaseSettings({...metabaseSettings, instance_id:'metabase'+(metabaseData.sorted_list.length+1)});
      } else {
        setMetabaseSettings({...metabaseSettings, instance_id:'metabase1'});
      }
    }
  },[]);

  const handleOnChange = (event) => {
    if(event.target.name === 'instance_id'){
      if (event.target.value.charAt(0) === '_') {
        return event.target.value.replace(/[^\w]/g, '');
      } else {
        event.target.value = event.target.value.replace(/[^\w]/g, '');
      } 
    } else if(event.target.name === 'nickname') {
      if (event.target.value.charAt(0) === ' ') {
        return event.target.value.replace(/[^\w]/g, '');
      }
    }
    setMetabaseSettings({ ...metabaseSettings, [event.target.name]: event.target.value });
  };

  const saveInstance = (event) => {
    let jsonData = Object.assign({}, metabaseSettings);
    let metabaseName = jsonData.instance_id;
    let action = isEditPopup ? 'updated' : 'added';

    if (isEmpty(metabaseName)) {
      swal('Please enter instance id.', { icon: 'error' });
      return;
    }
    let rdbJson = Constants.RDB_SERVICE_VALUE;
    rdbJson.instance_id = jsonData.instance_id;

    let finalJson = {};
    finalJson.nickname = jsonData.nickname;
    finalJson.enabled =  metabaseData.stacks[metabaseName] === undefined ? true : metabaseData.stacks[metabaseName].enabled;
    finalJson.namespace = Constants.NAMSPACES.SDK;
    finalJson.type = Constants.SDK_SERVICE_TYPES.BI_METABASE;
    finalJson.services = {};
    delete jsonData.nickname;
    finalJson.services.metabase1 = Object.assign({}, jsonData);
    finalJson.services.rdb1 = Object.assign({}, rdbJson);

    if (action === 'added') {
      axios.put(Constants.EDGE_API_ENDPOINT + '/sdk/metabase/' + metabaseName + '/all', finalJson)
        .then(res => {
          swal('Instance ' + action + ' successfully.', { icon: 'success' });
          setTimeout(function () { window.location.reload(); }, 2000);
        })
        .catch(error => {
          if (error.response) {
            var errorObj = error.response.data;
            swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
          } else {
            swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
          }
        });
    } else {
      axios.patch(Constants.EDGE_API_ENDPOINT + '/sdk/metabase/' + metabaseName + '/all', finalJson)
        .then(res => {
          swal('Instance ' + action + ' successfully.', { icon: 'success' });
          setTimeout(function () { window.location.reload(); }, 2000);
        })
        .catch(error => {
          if (error.response) {
            var errorObj = error.response.data;
            swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
          } else {
            swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
          }
        });
    }
  };

  const deleteMetabase = (event) => {
    let metabaseName = metabaseSettings.instance_id;
    swal({
      text: 'Are you sure you want to delete this instance: ' + metabaseName + '?',
      buttons: ['No', 'Yes'],
      dangerMode: true,
      icon: 'warning'
    })
      .then((isConfirm) => {
        if (isConfirm) {
          axios.delete(Constants.EDGE_API_ENDPOINT + '/sdk/metabase/' + metabaseName + '/all')
            .then(res => {
              swal('Instance delete request submitted successfully.', { icon: 'success' });
              setTimeout(function () { window.location.reload(); }, 2000);
            })
            .catch(error => {
              if (error.response) {
                var errorObj = error.response.data;
                swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
              } else {
                swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
              }
            });
        }
      });

  };

  return (
    <div>
      {metabaseSettings !== undefined &&
        <PopupContainer>
          <SectionHeader style={{ 'display': 'flex' }}>
            <SectionLabel>{isEditPopup === true ? 'Edit' : 'Add'} Instance</SectionLabel>
            {isEditPopup &&
              <div style={{ 'marginLeft': 'auto' }}>
                <Button danger id="btnDeleteInstance" name="btnDeleteInstance" style={{ 'margin': '0px' }} onClick={deleteMetabase} >Delete</Button>
              </div>
            }
          </SectionHeader>
          <Container>
            <Row>
              <MandatoryLabel>Instance ID</MandatoryLabel>
              <TextFieldSmall id="instance_id" autoComplete="off" name="instance_id" value={metabaseSettings.instance_id} onChange={handleOnChange} disabled={isEditPopup} maxLength="63" />
            </Row>
            <Row>
              <TextFieldLabel>Nickname</TextFieldLabel>
              <TextFieldSmall id="nickname" autoComplete="off" name="nickname" value={metabaseSettings.nickname} onChange={handleOnChange} maxLength="100" />
            </Row>
          </Container>
          <Row>
            <PopupButtons>
              <Button onClick={saveInstance} primary >Save</Button>
              <Button id="btnCancelInstance" danger onClick={() => { closePopup(); }}>Cancel</Button>
            </PopupButtons>
          </Row>
        </PopupContainer>
      }
    </div>
  );
};

export default Metabase;