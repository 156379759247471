import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import * as Constants from './utils/Constants';

import ScheduleSetting from './components/ScheduleSetting';
import NetworkSettings from './components/NetworkSettings';
import Power from './components/Power';
import AddStream from './components/AddStream';
import ViewStream from './components/ViewStream';
import StreamList from './components/StreamList';
import FirewallSetting from './components/FirewallSetting';
import DeviceLog from './components/DeviceLog';
import CurrentStatus from './components/CurrentStatus';
import Menus from './components/Menus';
import DeviceInfo from './components/DeviceInfo';
import { BrowserRouter as Router, Route} from 'react-router-dom';
import { Redirect } from 'react-router';
import { ReactComponent as LogoSVG } from './images/logo.svg';
import FTPServerSetting from './components/FTPServerSetting';
import NamingRules from './components/NamingRules';
import ls from 'local-storage';
import UploadSettings from './components/UploadSettings';
import DeviceHealthStatus from './components/DeviceHealthStatus';
//import ProjectsOld from './components/ProjectsOld';
import Rdb from './components/Rdb';
import Metabase from './components/Metabase';
import EmailClient from './components/EmailClient';
import QRCode from './components/QRCode';
import WebRedirect from './components/WebRedirect';
import TimeSettings from './components/TimeSetting';
import Location from './components/Location';
import Project from './components/Project';
import DeviceReset from './components/DeviceReset';
import SafieCameraSettings from './components/SafieCameraSettings';
import MonitoringSettings from './components/MonitoringSettings';

export const Menu=styled(Menus)`
    width: 16vw;
    background-color: #1F303A;
    clear: both;
    float: left;
`;

export const DeviceIDContainer=styled.div`
    width: 100%;
    height: 60px;
    background-color:#1E292F;
    padding-left: 15px;
    padding-top: 10px !important;
    padding-bottom: 0px !important;
`;

export const HeaderTitle=styled.h3`
    margin: 0px;
    padding-top: 15px;
    color: #0097f1;
    text-align: center;
    font-size: 24px;
    height: 60px;
`;

export const ContentWrapper=styled.div`
    width: 86vw;
    height: 100%;
    background-color: #eef3fa;
    text-align: center;
    display: inline;
`;

const AppLogo = styled(LogoSVG)`
    height: 60px;
    object-fit: contain;
    cursor: pointer;
`;

const AppLogoDiv = styled.div`
    width: 16vw;
    object-fit: contain;
    background-color: #1E292F;
    float: left;
    /* fill or stroke: #fefefe; you could add styles here if you wanted but logos it doesn't make sense */
`;

export const MainWrapper=styled.div`
    width: 100%;
    background-color: #eef3fa;
`;

const Footer = styled.div`
  width: 10vw;
  margin-top: auto;
  margin-bottom:  ${props => props.isMobileBrowser ? '150px' : '10px'};
  margin-left: auto;
  margin-right: auto;
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  overflow-x: hidden;
`;

function App() {
  const [isMobileBrowser, setIsMobileBrowser] = useState(false);

  useEffect(()=>{
    var isMobileBrowser = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if(isMobileBrowser) {
      setIsMobileBrowser(isMobileBrowser);
    } else {
      setIsMobileBrowser(isMobileBrowser);
    }
  },[]);

  return (
    <div style={{'display': 'flex'}}>
      <MenuContainer className="menu">
        <AppLogoDiv>
          <AppLogo onClick={showStreamList} title=""/>
          <DeviceHealthStatus/>
        </AppLogoDiv>
        <Menu/>
          {Constants.AD_HW_VPU === 'MMAL' &&
          <Footer isMobileBrowser={isMobileBrowser}>
            <QRCode />
          </Footer>}
      </MenuContainer>
      <div className="contents">
        <Router>
          <Route exact path="/" render={() => (
            <Redirect to="/stream-viewer"/>
          )}/>
          <Route path="/stream-viewer" component={StreamList} />
          <Route path="/add-rtmp-stream" component={AddStream} />
          <Route path="/add-rtsp-stream" component={AddStream} />
          <Route path="/add-usb-stream" component={AddStream} />
          <Route path="/add-rtp-stream" component={AddStream} />
          <Route path="/add-ftp-stream" component={AddStream} />
          <Route path="/add-safie-stream" component={AddStream} />
          <Route path="/device-info" component={DeviceInfo} />
          <Route path="/current-status" component={CurrentStatus} />
          <Route path="/schedule" component={ScheduleSetting} />
          <Route path="/network" component={NetworkSettings} />
          <Route path="/device-log" component={DeviceLog} />
          <Route path="/reboot" component={Power} />
          <Route path="/shutdown" component={Power} />
          <Route path="/view-stream" component={ViewStream} />
          <Route path="/ftp-server" component={FTPServerSetting} />
          <Route path="/naming-rules" component={NamingRules} />
          <Route path="/cloud-uploader" component={UploadSettings} />
          <Route path="/rdb" component={Rdb} />
          <Route path="/metabase" component={Metabase} />
          <Route path="/email-client" component={EmailClient} />
          <Route path="/web-redirect" component={WebRedirect} />
          <Route path="/time-settings" component={TimeSettings} />
          <Route path="/location" component={Location} />
          <Route path='/projects' component={Project} />
          <Route path="/device-reset" component={DeviceReset} />
          <Route path="/safie-camera-settings" component={SafieCameraSettings} />
          <Route path="/monitoring-settings" component={MonitoringSettings} />
        </Router>
      </div>
    </div>
  );
}

function showStreamList() {
  window.location.href='/stream-viewer';
}

export default App;
