import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { CircularProgress } from 'react-md';
import { Row, Button, SectionHeader, SectionLabel, TextArea, CenterDiv, Label, Td, Th, overlayStyle, TableContainer, PopupButtons, PopupContainer, TextFieldLabel, TextFieldSmall, MandatoryLabel, Link1, LinkIcon } from '../utils/Styles';
import * as Constants from '../utils/Constants';
import { isEmpty } from '../utils/Utils';
import swal from 'sweetalert';
import Popup from 'reactjs-popup';
import styled from 'styled-components';

const TableLayout = styled(TableContainer)`
  overflow: auto;
  overflow-y: hidden;
`;

const Wrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  text-align: left;
  display: inline-block;
`;

const ContentDiv = styled.div`
  width: 82vw;
  overflow-y: auto;
  padding-bottom: 10px;
`;

const Container = styled.div`
  padding: 0px 20px 20px 20px;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const WebRedirect = (props) => {
  const [isEditPopup, setEditPopup] = useState(false);
  const [cameraData, setCameraData] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCamera, setSelectedCamera] = useState({});
  const [cameraNameList, setCameraNameList] = useState([]);

  const contentStyle = {
    margin: 'auto',
    background: 'rgb(255, 255, 255)',
    width: 'fit-content',
    height: 'auto',
    padding: '5px',
    minWidth: '25%',
    border: '2px solid #d7d7d7'
  };

  const instNameStyle = {
    textAlign: 'left',
    padding: '10px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    maxWidth: '200px'
  };

  const descriptionStyle = {
    padding: '10px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    maxWidth: '200px'
  };


  const urlStyle = {
    paddingLeft: '10px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    maxWidth: '200px'
  };

  const editCamera = () => {
    setEditPopup(true);
    setModalOpen(true);
  };

  const closePopup = (event) => {
    setModalOpen(false);
  };

  const addNewCamera = (event) => {
    setEditPopup(false);
    setSelectedCamera(undefined);
    setModalOpen(true);
  };

  const radioButtonOnChange = (name) => {
    let data = Object.assign({}, cameraData[0][name]);
    setSelectedCamera(data);
    setEditPopup(true);
  };

  const getCameraData = useCallback(() => {
    axios.get(Constants.EDGE_API_ENDPOINT + '/system/network/webproxies/all')
      .then(res => {
        let data = res.data.data;
        let list = [];
        Object.keys(data).map((stackName) => {
          const stackData = data[stackName];
          Object.keys(stackData).map((i) => {
            if(stackData[i].action === 'DeleteService'){
              delete stackData[i];
            } else {
              list.push(stackData[i].webredirect_id)
            }
            return i;
          });
          return stackName;
        });
        setCameraNameList(list);
        let sortedData = [];
        Object.keys(data).map((stackName) => {
          const stackData = data[stackName];
          Object.keys(stackData).map((i) => {
            if(stackData[i].action === 'DeleteService'){
              delete stackData[i];
            } else {
              sortedData.push(stackData[i]);
            }
            return i;
          });
          return stackName;
        });
        sortedData.sort(function (a, b) {
          const dateFormatB = b.created_time.split(' ');
          const dateFormatA = a.created_time.split(' ');
          let dateA = dateFormatA[0] + ' ' + dateFormatA[1];
          let dateB = dateFormatB[0] + ' ' + dateFormatB[1];
          return new Date(dateB) - new Date(dateA);
        });
        data.sorted_list = sortedData;
        if(Object.keys(data.sorted_list).length !== 0) {
          let name = data.sorted_list[0].webredirect_id;
          let instData = Object.assign({}, data[0][name]);
          setSelectedCamera(instData);
          setEditPopup(true);
        }
        setCameraData(data);
      }).catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getCameraData();
  }, [getCameraData]);

  return (
    <ContentDiv>
      { (cameraData === undefined) &&
        <div className="loading-msg">
          <label>Loading...</label>
          <CircularProgress />
        </div>}
      {cameraData &&
                    <Wrapper style={{ 'padding': '20px' }}>
                      <SectionHeader>
                        <SectionLabel>Web Proxy Rules</SectionLabel>
                      </SectionHeader>
                      <Row>
                        <ActionButtonContainer>
                          { isEditPopup &&
                            <Button primary id="btnEditCamera" name="btnEditCamera" onClick={() => editCamera()}>Edit</Button>
                          }
                          <Button primary id="btnAddCamera" name="btnAddCamera" onClick={() => addNewCamera()}>Add</Button>
                          <Popup modal open={modalOpen} onClose={closePopup} {...{ contentStyle, overlayStyle }}>
                            <WebRedirectPopup isEditPopup={isEditPopup} cameraNameList={cameraNameList} closePopup={closePopup} selected_camera={selectedCamera} cameraData={cameraData} />
                          </Popup>
                        </ActionButtonContainer>
                      </Row>
                      {cameraData.sorted_list && Object.keys(cameraData.sorted_list).length > 0 &&
            <TableLayout>
              <table style={{ 'min-width': '100%', 'border-collapse': 'collapse' }}>
                <tr style={{ 'background-color': '#1f303a', 'color': 'white' }}>
                  <Th style={{'width': '5%'}}></Th>
                  <Th style={{'text-align': 'left'}}>Web Proxy Rule ID</Th>
                  <Th>Created</Th>
                  <Th>Description</Th>
                  <Th>Link</Th>
                  <Th>Target URL</Th>
                </tr>
                { Object.keys(cameraData.sorted_list).map((index) => {
                  let cData = cameraData.sorted_list[index];
                  let name = cData.webredirect_id;
                  let url = cData.target_url;
                  let created = cData.created_time;
                  let description = cData.description;
                  return (
                    <tr>
                      <Td><input type="radio" id="select_radio" style={{ 'cursor': 'pointer' }} defaultChecked={index==='0'} name="select_radio" value={name} onClick={() => radioButtonOnChange(name)}></input></Td>
                      <Td title={name} style={instNameStyle}>
                        {name}
                      </Td>
                      <Td>{created}</Td>
                      <Td title={description} style={descriptionStyle}>{description ? description : '-'}</Td>
                      <Td>
                          <div>
                            <LinkIcon />
                          <Link1 target="_blank" href={window.location.protocol + '//' + window.location.host + '/' + name}>Open Link</Link1>
                          </div>
                      </Td>
                      <Td title={url} style={urlStyle}>{url}</Td>  
                    </tr>
                  );
                })}
              </table>
            </TableLayout>}
                      {cameraData && cameraData.sorted_list.length === 0 &&
            <CenterDiv>
              <Label>No web proxy rules found.</Label>
            </CenterDiv>}
                    </Wrapper>}
    </ContentDiv>
  );
};

function getDefaultCameraData() {
  return Constants.WEB_REDIRECT;
}

const WebRedirectPopup = (props) => {
  const { isEditPopup, closePopup, selected_camera, cameraData, cameraNameList} = props;
  const [cameraSetting, setCameraSetting] = useState(selected_camera === undefined ? getDefaultCameraData() : selected_camera);

  useEffect(()=>{
    if(cameraSetting.webredirect_id === ''){
      if(cameraData.sorted_list.length !== undefined){
        setCameraSetting({...cameraSetting, webredirect_id:'webproxyrule'+(cameraData.sorted_list.length+1)});
      } else {
        setCameraSetting({...cameraSetting, webredirect_id:'webproxyrule1'});
      }
    }
  },[cameraData.sorted_list.length]);

  const handleOnChange = (event) => {
    if(event.target.name === 'webredirect_id'){
      if (event.target.value.charAt(0) === '_') {
        event.target.value = event.target.value.replace('_','');
      } else {
        event.target.value = event.target.value.replace(/[^\w]/g, '');
      }
    } else  if(event.target.name === 'target_url' && event.target.value.includes('&')) {
      event.target.value = event.target.value.replace('&', ''); 
    } else if(event.target.name === 'target_url'  && event.target.value.includes('$')) {
      event.target.value = event.target.value.replace('$', ''); 
    }
    setCameraSetting({ ...cameraSetting, [event.target.name]: event.target.value });
  };

  const saveCameraIp = (event) => {
    let jsonData = Object.assign({}, cameraSetting);
    let webridirectId = jsonData.webredirect_id;
    let targetUrl = jsonData.target_url;
    let action = isEditPopup ? 'updated' : 'added';

    if (isEmpty(webridirectId)) {
      swal('Please enter web proxy rule id.', { icon: 'error' });
      return;
    }
    if (isEmpty(targetUrl)) {
      swal('Please enter target url.', { icon: 'error' });
      return;
    }
    if(cameraNameList.includes(webridirectId) && action === 'added') {
      swal('Web proxy rule id already exist. Please try with another id.', { icon: 'error' });
      return;
    }
    if(cameraNameList.length >= 10 && action === 'added') {
      swal('Maximum 10 numbers of web proxy rules can be added', { icon: 'error' });
      return;
    }
    var targetUrlFormat = /^(http|https?):?\/\/./;
      if (!targetUrlFormat.test(targetUrl)) {
          swal('Target url is invalid', { icon: 'error' });
          return;
      }
    
    jsonData.source = 'edge_ui';

    if (action === 'added') {
      axios.put(Constants.EDGE_API_ENDPOINT + '/system/network/webproxies/' + webridirectId, jsonData)
        .then(res => {
          swal('Web proxy rule ' + action + ' successfully.', { icon: 'success' });
          setTimeout(function () { window.location.reload(); }, 2000);
        })
        .catch(error => {
          if (error.response) {
            var errorObj = error.response.data;
            swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
          } else {
            swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
          }
        });
    } else {
      axios.patch(Constants.EDGE_API_ENDPOINT + '/system/network/webproxies/' + webridirectId, jsonData)
        .then(res => {
          swal('Web proxy rule ' + action + ' successfully.', { icon: 'success' });
          setTimeout(function () { window.location.reload(); }, 2000);
        })
        .catch(error => {
          if (error.response) {
            var errorObj = error.response.data;
            swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
          } else {
            swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
          }
        });
    }
  };

  const deleteCameraIp = (event) => {
    let WebRedirectName = cameraSetting.webredirect_id;
    swal({
      text: 'Are you sure you want to delete this web proxy rule?',
      buttons: ['No', 'Yes'],
      dangerMode: true,
      icon: 'warning'
    })
      .then((isConfirm) => {
        if (isConfirm) {
          axios.delete(Constants.EDGE_API_ENDPOINT + '/system/network/webproxies/' + WebRedirectName)
            .then(res => {
              swal({text:'Web proxy rule delete request submitted successfully.', icon: 'success' });
              setTimeout(function () { window.location.reload(); }, 2000);
            })
            .catch(error => {
              if (error.response) {
                var errorObj = error.response.data;
                swal('Error Code: ' + errorObj.error.code + '\nError Message: ' + errorObj.error.message, { icon: 'error' });
              } else {
                swal({ text: 'Unable to connect to the edge-api service', icon: 'error' });
              }
            });
        }
      });
  };

  return (
    <div>
      {cameraSetting !== undefined &&
        <PopupContainer>
          <SectionHeader style={{ 'display': 'flex' }}>
            <SectionLabel>{isEditPopup === true ? 'Edit' : 'Add'} Web Proxy Rule</SectionLabel>
            {isEditPopup &&
              <div style={{ 'marginLeft': 'auto' }}>
                <Button danger id="btnDeleteCameraIP" name="btnDeleteCameraIP" style={{ 'margin': '0px' }} onClick={deleteCameraIp} >Delete</Button>
              </div>
            }
          </SectionHeader>
          <Container>
            <Row>
              <MandatoryLabel>Web Proxy Rule ID</MandatoryLabel>
              <TextFieldSmall id="webredirect_id" autoComplete="off" name="webredirect_id" value={cameraSetting.webredirect_id} onChange={handleOnChange} disabled={isEditPopup} maxLength="100" style={{'width': '250px'}} />
            </Row>
            <Row>
              <MandatoryLabel>Target URL</MandatoryLabel>
              <TextFieldSmall id="target_url" autoComplete="off" name="target_url" placeholder='http://192.168.10.32:80' value={cameraSetting.target_url} onChange={handleOnChange} maxLength="100" style={{'width': '250px'}} />
            </Row>
            <Row style={{'display': 'flex'}}>
              <TextFieldLabel>Description</TextFieldLabel>
              <div style={{'marginTop':'20px', width: '250px'}}>
                <TextArea id="description" autoComplete="off" name="description" maxLength="255" value={cameraSetting.description} onChange={handleOnChange} />    
              </div>
            </Row>
          </Container>
          <Row>
            <PopupButtons>
              <Button onClick={saveCameraIp} primary >Save</Button>
              <Button id="btnCancel" danger onClick={() => { closePopup(); }}>Cancel</Button>
            </PopupButtons>
          </Row>
        </PopupContainer>
      }
    </div>
  );
};
export default WebRedirect;