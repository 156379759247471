export const EDGE_API_ENDPOINT      = window.location.protocol + '//' + window.location.host + '/api/v1';
export const EDGE_METABASE          = window.location.protocol + '//' + window.location.host + '/metabase-';
export const GRAFANA_ENDPOINT       = window.location.protocol + '//' + window.location.host + '/grafana';
export const NETDATA_ENDPOINT       = window.location.protocol + '//' + window.location.host + '/recent_activity';
export const PORTAINER_ENDPOINT     = window.location.protocol + '//' + window.location.host + '/portainer';
export const FTP_SERVER_PORT = '21';
export const INTERNAL_FTP_SERVER_ENDPOINT  = '{}';
export const INTERNAL_RTMP_SERVER   = 'rtmp://{}:1935';
export const EDGE_UI_PORT = '20001';
export const INTERNAL_RTMP_SERVER_ENDPOINT  = INTERNAL_RTMP_SERVER + '/live';
export const AD_HW_VPU = 'VAAPI'; // RP4 --> MMAL, Intel/NUC --> VAAPI, GPU --> NVIDIA
export const RESOLUTIONS            = {'1920x1080':'FHD (1920x1080)', '1280x720': 'HD (1280x720)', '640x480': 'VGA (640x480)', '320x240': 'QVGA (320x240)'};
export const SNAPSHOT_RESOLUTIONS = [{'name':'Same as video input', 'value': 'auto'},
  {'name':'1920x1080 (FHD) ', 'value': '1920x1080'},
  {'name':'1920x1440', 'value': '1920x1440'},
  {'name':'1280x720 (HD)','value': '1280x720'},
  {'name':'1280x960','value': '1280x960'},
  {'name':'640x480 (VGA)', 'value': '640x480'},
  {'name':'640x360 (nHD)', 'value': '640x360'},
  {'name':'320x240 (QVGA)', 'value':'320x240'},
  {'name':'320x180', 'value':'320x180'},
];
export const VIDEO_FPS_LIST         = [{'name': 'auto', 'value': 'auto'},
  {'name': '1fps', 'value': '1/1'},
  {'name': '5fps', 'value': '5/1'},
  {'name': '7fps', 'value': '7/1'},
  {'name': '10fps', 'value': '10/1'},
  {'name': '12fps', 'value': '12/1'},
  {'name': '15fps', 'value': '15/1'},
  {'name': '20fps', 'value': '20/1'},
  {'name': '25fps', 'value': '25/1'},
  {'name': '30fps', 'value': '30/1'},
  {'name': '1/5fps', 'value': '1/5'},
  {'name': '1/15fps', 'value': '1/15'},
  {'name': '1/30fps', 'value': '1/30'},
  {'name': '1/60fps', 'value': '1/60'},
  {'name': '1/180fps', 'value': '1/180'},
  {'name': '1/600fps', 'value': '1/600'}
];
export const VIDEO_QUALITIES        = ['low', 'middle', 'high'];
export const VIDEO_FLIP_METHODS     = [{'name': 'none', 'value': 'none'},
  {'name': 'Rotate-180', 'value': 'rotate-180'},
  {'name': 'Clockwise', 'value': 'clockwise'},
  {'name': 'Counterclockwise', 'value': 'counterclockwise'},
  {'name': 'Horizontal-flip', 'value': 'horizontal-flip'},
  {'name': 'Vertical-flip', 'value': 'vertical-flip'},
  {'name': 'Upper-left-diagonal', 'value': 'upper-left-diagonal'},
  {'name': 'Upper-right-diagonal', 'value': 'upper-right-diagonal'}];
export const AUDIO_DEVICES          = ['audio0', 'audio1'];

export const BROADCASTING_PROTOCOLS = [{'name': 'RTMP Broadcaster', 'value': 'rtmp'},
  {'name': 'RTP Broadcaster', 'value': 'rtp'},
  {'name': 'Kinesis Broadcaster', 'value': 'kinesis'},
  {'name': 'Fog Broadcaster', 'value': 'fog'}];

export const RTSP_SOURCE_SHORT_HEADERS = [{'name': 'Yes', 'value': 'true'},
{'name': 'No', 'value': 'false'}];

export const HEALTH_STATUS_TYPES = { 'running': 'Running', 'failed': 'Failed', 'recovering': 'Reconciliation' };

export const PORT_TYPES = {'ether':'Ethernet','wlan':'Wi-Fi','bond':'Bond'};

export const VIDEO_CODEC_TYPE = [{'name': 'H-264', 'value': 'H264'}];

export const VIDEO_CODEC_TYPE_MPEG2PS= [{'name': 'MPEG-2', 'value': 'MPEG2'}];

export const IMAGE_FETCH_TYPE= [{'name': 'From Still Image', 'value': 'still'},
  {'name': 'From Live Stream', 'value': 'live'},
  {'name': 'From Recordered Movie', 'value': 'recordered'}];

export const IMAGE_SOURCE_TYPE = ['FTP'];

export const IMAGE_FILE_EXTENSIONS = ['bmp','jpeg','jpg','png'];

export const DATE_TIME_FORMAT_EXAMPLE ='Date Time Format Example: ' +
                                        '\n yyyy-MM-dd_hh:mm:ss+z' +
                                        '\n yyyy-MM-dd-hh:mm:ss+z' +
                                        '\n yyyy-MM-dd-hh:mm:ss' +
                                        '\n yyyy_MM_dd_hh:mm:ss+z' +
                                        '\n yyyy_MM_dd-hh:mm:ss+z' +
                                        '\n yyyy_MM_dd-hh:mm:ss' +
                                        '\n dd_MM_yyyy_hh:mm:ss+z' +
                                        '\n dd_MM_yyyy-hh:mm:ss+z' +
                                        '\n dd_MM_yyyy-hh:mm:ss' +
                                        '\n yyyyMMddhhmmss' +
                                        '\n yyyyMMddhhmmss+z';

export const FOG_BROADCASTER_ALIASES = [
  '1. Use \'tcp://host_ip\' for dynamic detection of host\'s IP address (default interface).',
  '2. Use \'tcp://gateway_ip\' for dynamic detection of docker gateway\'s IP address.',
  '3. Use \'tcp://stream_ip\' for stream stack\'s network container ip.'];

export const AUDIO_SOURCE_TYPE = ['AAC','MP3', 'MPGA'];

export const ALGORITHMS  = [{'name': 'Face Detection', 'value': 'cascade_tracker'},
  {'name': 'Upper Body Detection', 'value': 'hog_tracker'}];

export const RTP_TRANSPORT_ENCAPSULATION_TYPE = (AD_HW_VPU === 'MMAL') ? [{'name': 'H-264', 'value': 'H264'},
  {'name': 'MPEG-2 TS', 'value': 'MPEG2TS'},
  {'name': 'MPEG-2 TTS', 'value': 'MPEG2TTS'}] :
  [{'name': 'H-264', 'value': 'H264'},
  {'name': 'MPEG-2 TS', 'value': 'MPEG2TS'},
  {'name': 'MPEG-2 TTS', 'value': 'MPEG2TTS'},
  {'name': 'MPEG-2 PS', 'value': 'MPEG2PS'}];

export const PIXEL_ASPECT_RATIO = [{'name': 'auto', 'value': 'auto'}, 
{'name': '1:1', 'value': '1:1'},
{'name': '4:3', 'value': '4:3'}]

export const RTP_H264_PAYLOAD_TYPE = 96;
export const RTP_MPEG_PAYLOAD_TYPE = 33;
export const RTP_MPEGTTS_PAYLOAD_TYPE = 103;
export const RTP_MPEG_PS_PAYLOAD_TYPE = 96;

export const STACK_ATTR_DEFAULT_VALUES = {
  'type': 'stream',
  'namespace': 'default',
  'stack_name': '',
  'enabled': true,
  'description': '',
  'video_device': 'video0',
  'camera_view_movie': false,
  'camera_view_still_image': true,
  'snapshot_refresh_interval': '3',
  'rtsp_enabled': false,
  'webrtc_enabled': true
};

export const RECORDER_FTP_DEFAULT_VALUES = {
  'type': 'recorder',
  'recorder_type': 'image_recorder',
  'enabled': true,
  'enable_local_streams': true,
  'image_source_type': 'FTP',
  'port': '',
  'camera_type': 'FTP',
  'enable_hw_acceleration': true,
  'enable_passive_mode':false,
  'save_interval': '120',
  'periodic_snapshot': false,
  'server_type': 'internal',
  'source_directory_type': 'default_source_directory',
  'source_directory': '',
  'url_type': 'mDNS',
  'source_url': '',
  'file_extension':'jpg',
  'ingest_time':'System Clock',
  'date_time_format':'',
  'enable_snapshot_time_overlay': false,
  'username': '',
  'password': '',
  'enable_anonymous_access': false,
  'enable_user_login':false
}; 
export const RECORDER_SAFIE_DEFAULT_VALUES = {
  'type': 'recorder',
  'recorder_type': 'image_recorder',
  'enabled': true,
  'save_interval': '120',
  'periodic_snapshot': false,
  'camera_name':'',
  'camera_type': 'safie',
  'capture_type': 'still_capture',
  "still_capture_api": "live",
  "image_pull_hrs": '00',
  "image_pull_min": '00',
  "image_pull_sec": '30',
  "enable_snapshot_time_overlay": false
}; 
export const FTP_SERVER_DEFAULT_VALUES = {
  'listen_port': '',
  'data_port': '',
  'enable_passive_mode': false,
  'port_from': '',
  'port_to': '',
  'username': '',
  'password': '',
  'enable_anonymous_access': false,
  'enable_user_login':false
};

export const RECORDER_USB_DEFAULT_VALUES = (AD_HW_VPU === 'NVIDIA') ?
{
  'gpu_affinity': 'no_affinity',
  'type': 'recorder',
  'recorder_type':'stream_recorder',
  'enabled': true,
  'video_duration': '600',
  'enable_local_streams': true,
  'enable_save_mp4': true,
  'audio_stream': false,
  'video_source_type': 'UVC_I420',
  'camera_type': 'USB',
  'resolution': 'QVGA (320x240)',
  'video_fps': 'auto',
  'recording_quality': 'low',
  'video_flip_method': 'none',
  'enable_hw_acceleration': true,
  'save_interval': '120',
  'periodic_snapshot': true,
  'enable_snapshot_time_overlay': true,
  'enable_mp4_time_overlay': true,
  'specify_snapshot_resolution': false,
  'periodic_snapshot_resolution': 'auto',
  'save_original_snapshot': false,
  'video_split_mode': 'time',
  'video_file_max_size':'50',
  'h264_profile': 'auto'
} : {
  'type': 'recorder',
  'recorder_type':'stream_recorder',
  'enabled': true,
  'video_duration': '600',
  'enable_local_streams': true,
  'enable_save_mp4': true,
  'audio_stream': false,
  'video_source_type': 'UVC_I420',
  'camera_type': 'USB',
  'resolution': 'QVGA (320x240)',
  'video_fps': 'auto',
  'recording_quality': 'low',
  'video_flip_method': 'none',
  'enable_hw_acceleration': true,
  'save_interval': '120',
  'periodic_snapshot': true,
  'enable_snapshot_time_overlay': true,
  'enable_mp4_time_overlay': true,
  'specify_snapshot_resolution': false,
  'periodic_snapshot_resolution': 'auto',
  'save_original_snapshot': false,
  'video_split_mode': 'time',
  'video_file_max_size':'50',
  'h264_profile': 'auto'
};

export const RECORDER_RTSP_DEFAULT_VALUES = {
  'type': 'recorder',
  'recorder_type':'stream_recorder',
  'enabled': true,
  'video_duration': '600',
  'enable_local_streams': true,
  'enable_save_mp4': true,
  'audio_stream': false,
  'video_source_type': 'RTSP',
  'camera_type': 'RTSP',
  'rtsp_source_short_header': false,
  'enable_hw_acceleration': true,
  'save_interval': '120',
  'rtsp_source_buffer_time': '1',
  'periodic_snapshot': true,
  'enable_snapshot_time_overlay': false,
  'video_fps': 'auto',
  'specify_snapshot_resolution': false,
  'periodic_snapshot_resolution': 'auto',
  'save_original_snapshot': false,
  'video_split_mode': 'time',
  'video_file_max_size':'50',
  'h264_profile': 'auto'
};
export const RECORDER_RTMP_DEFAULT_VALUES = (AD_HW_VPU === 'NVIDIA') ?
{
  'gpu_affinity': 'no_affinity',
  'type': 'recorder',
  'recorder_type':'stream_recorder',
  'enabled': true,
  'video_duration': '600',
  'enable_local_streams': true,
  'enable_save_mp4': true,
  'audio_stream': false,
  'video_source_type': 'RTMP',
  'camera_type': 'RTMP',
  'enable_hw_acceleration': true,
  'save_interval': '120',
  'periodic_snapshot': true,
  'rtmp_server_type': 'internal',
  'rtmp_stream_key_type': 'default_rtmp_stream_key',
  'rtmp_url_type': 'mDNS',
  'rtmp_source_url': '',
  'enable_snapshot_time_overlay': false,
  'video_fps': 'auto',
  'specify_snapshot_resolution': false,
  'periodic_snapshot_resolution': 'auto',
  'save_original_snapshot': false,
  'video_split_mode': 'time',
  'video_file_max_size':'50',
  'h264_profile': 'auto'
} : {
  'type': 'recorder',
  'recorder_type':'stream_recorder',
  'enabled': true,
  'video_duration': '600',
  'enable_local_streams': true,
  'enable_save_mp4': true,
  'audio_stream': false,
  'video_source_type': 'RTMP',
  'camera_type': 'RTMP',
  'enable_hw_acceleration': true,
  'save_interval': '120',
  'periodic_snapshot': true,
  'rtmp_server_type': 'internal',
  'rtmp_stream_key_type': 'default_rtmp_stream_key',
  'rtmp_url_type': 'mDNS',
  'rtmp_source_url': '',
  'enable_snapshot_time_overlay': false,
  'video_fps': 'auto',
  'specify_snapshot_resolution': false,
  'periodic_snapshot_resolution': 'auto',
  'save_original_snapshot': false,
  'video_split_mode': 'time',
  'video_file_max_size':'50',
  'h264_profile': 'auto'
};

export const RECORDER_RTP_DEFAULT_VALUES =(AD_HW_VPU === 'NVIDIA') ? {
  'gpu_affinity': 'no_affinity',
  'type': 'recorder',
  'recorder_type':'stream_recorder',
  'enabled': true,
  'video_duration': '600',
  'enable_local_streams': true,
  'enable_save_mp4': true,
  'video_source_type': 'RTP',
  'camera_type': 'RTP',
  'enable_hw_acceleration': true,
  'save_interval': '120',
  'periodic_snapshot': true,
  'enable_snapshot_time_overlay': false,
  'transport_encapsulation': 'H264',
  'video_codec': 'H264',
  'video_payload_type': RTP_H264_PAYLOAD_TYPE,
  'enable_video_multicast': true,
  'video_multicast_address': '',
  'video_port_rtp': '',
  'enable_video_rtcp_muxed': false,
  'video_port_rtcp': '',
  'video_fps': 'auto',
  'rtp_audio_source_type': 'AAC',
  'enable_audio': false,
  'audio_payload_type': RTP_H264_PAYLOAD_TYPE,
  'enable_audio_multicast': true,
  'audio_multicast_address': '',
  'audio_port_rtp': '',
  'enable_audio_rtcp_muxed': false,
  'audio_port_rtcp': '',
  'specify_snapshot_resolution': false,
  'periodic_snapshot_resolution': 'auto',
  'save_original_snapshot': false,
  'video_split_mode': 'time',
  'video_file_max_size':'50',
  'enable_mp4_time_overlay': false
} : {
  'type': 'recorder',
  'recorder_type':'stream_recorder',
  'pixel_aspect_ratio' : 'auto',
  'enabled': true,
  'video_duration': '600',
  'enable_local_streams': true,
  'enable_save_mp4': true,
  'video_source_type': 'RTP',
  'camera_type': 'RTP',
  'enable_hw_acceleration': true,
  'save_interval': '120',
  'periodic_snapshot': true,
  'enable_snapshot_time_overlay': false,
  'transport_encapsulation': 'H264',
  'video_codec': 'H264',
  'video_payload_type': RTP_H264_PAYLOAD_TYPE,
  'enable_video_multicast': true,
  'video_multicast_address': '',
  'video_port_rtp': '',
  'enable_video_rtcp_muxed': false,
  'video_port_rtcp': '',
  'video_fps': 'auto',
  'rtp_audio_source_type': 'AAC',
  'enable_audio': false,
  'audio_payload_type': RTP_H264_PAYLOAD_TYPE,
  'enable_audio_multicast': true,
  'audio_multicast_address': '',
  'audio_port_rtp': '',
  'enable_audio_rtcp_muxed': false,
  'audio_port_rtcp': '',
  'specify_snapshot_resolution': false,
  'periodic_snapshot_resolution': 'auto',
  'save_original_snapshot': false,
  'video_split_mode': 'time',
  'video_file_max_size':'50',
  'enable_mp4_time_overlay': false
};


export const BROADCASTING_DEFAULT_VALUES = {
  'data': {
    'protocol': 'rtmp'
  }
};


export const BROADCASTING_RTP_DEFAULT_VALUES = {
  'protocol': 'rtp',
  'rtp_server_ip':'',
  'rtp_video_udp_port':'',
  'rtp_audio_udp_port':'',
  'enabled': true
};

export const ANALYSIS_DEFAULT_VALUES = {
  'data': {
    'type': 'cascade_tracker',
    'detection_snapshot': true,
    'inactivity_timeout': '30',
    'enabled': true
  }
};

export const FOG_BROADCASTER_DEFAULT_VALUES = {
  'protocol': 'fog',
  'type': 'fog_broadcaster',
  'enabled': true,
  'endpoint_type': 'bind',
  'destinations': [],
  'stream_id': 'auto',
  'compression': true,
  'compression_quality': '85',
  'frame_rate_logging': false,
  'frame_rate_logging_interval': '300',
  'emit_interval': '0',
  'network_access': 'allow_inside_device',
  'watchdog_timeout': '0'
};

export const STREAM_FORWARDER_DEFAULT_VALUES = {
  'type': 'stream_forwarder',
  'enabled': true,
  'sf_endpoint_type': 'bind',
  'destinations': [],
  'sf_frame_rate_logging': false,
  'frame_rate_logging_interval': '300',
  'emit_interval': '0',
  'network_access': 'allow_inside_device',
  'watchdog_timeout': '0'
};

export const CAPTURE_SCHEDULE_DEFAULT_VALUES = {
  'enabled': true,
  'start_time':'00:00:00',
  'end_time':'23:59:00',
  'weekdays': '0,1,2,3,4,5,6'
};

export const DISCRETE_CAPTURE_DEFAULT_VALUES = {
  'enabled': false,
  'interval_hours':'01',
  'interval_minutes':'00',
  'interval_seconds':'00',
  'capture_hours':'00',
  'capture_minutes':'01',
  'capture_seconds':'00',
  'optimize': false
};

export const DEVICE_CONTROL_DEFAULT_VALUES = {
  'action': '',
  'delay': '0',
  'source': 'Edge UI'
};

export const CREATE_LINK_DEFAULT_VALUE = {
  'type': 'link',
  'auto_dns' : true,
  'dhcp' : true,
  'enabled' : true,
  'gateway' : '',
  'ip' : '',
  'link_type' : 'ethernet',
  'nickname' : '',
  'preferred_dns' : '8.8.8.8',
  'route_metric' : '',
  'subnet_mask' : '',
  'alternate_dns': ''
};

export const H264_PROFILE = 
    [{'name': 'auto', 'value': 'auto'},
    {'name': 'baseline', 'value': 'baseline'},
    {'name': 'constrained-baseline', 'value': 'constrained-baseline'},
    {'name': 'main', 'value': 'main'}];

export const WPA_SECURITY_TYPES = [{'name': 'WPA-PSK', 'value': 'WPA-PSK'},
  {'name': 'WPA2-PSK', 'value': 'WPA2-PSK'},
  {'name': 'WEP', 'value': 'WPA-EAP'},
  {'name': 'None', 'value': 'WPA-NONE'}];

export const AUTHENTICATION_MODE_TYPES = [{'name': 'WPA-PSK', 'value': 'WPA-PSK'},
  {'name': 'WPA2-PSK', 'value': 'WPA2-PSK'},
  {'name': 'WPA-EAP', 'value': 'WPA-EAP'}];

export const FREQUENCY  = [{'name': '2.4GHz', 'value': '2.4GHz'},
  {'name': '5GHz', 'value': '5GHz'}];
                                      
export const SSID = [{'name': 'Default', 'value': 'default'},
  {'name': 'Custom', 'value': 'custom'}];

export const CREATE_WIFI_DEFAULT_VALUE = {
  'type': 'wpa',
  'security_type':'WPA2-PSK',
  'security_key': '',
  'priority': '',
  'link_name': '',
  'connect_automatically': false
};

export const WIFI_CONNECT_STATUS = {
  'wifi_enabled': false
};
export const TIME_ZONE = ['Asia/Tokiyo','Asia/Kolkata'];
export const SCHEDULE_REBOOT_DEFAULT_VALUES = {'ENABLE_SCHEDULE_REBOOT': '',
  'SCHEDULE_REBOOT_WDAY': '',
  'SCHEDULE_REBOOT_HOUR': '',
  'SCHEDULE_REBOOT_MIN': ''
};

export const WEEK_DAYS = {
  'sun': '6',
  'mon': '0',
  'tue': '1',
  'wed': '2',
  'thu': '3',
  'fri': '4',
  'sat': '5'
};

export const HOURS = ['00','01', '02', '03', '04', '05', '06', '07', '08','09', '10', '11', '12',
  '13', '14', '15', '16', '17', '18', '19', '20','21', '22', '23'];

export const MINUTES = ['00','01', '02', '03', '04', '05', '06', '07', '08','09', '10', '11', '12',
  '13', '14', '15', '16', '17', '18', '19', '20','21', '22', '23', '24',
  '25', '26', '27', '28', '29', '30', '31', '32','33', '34', '35', '36',
  '37', '38', '39', '40', '41', '42', '43', '44','45', '46', '47', '48',
  '49', '50', '51', '52', '53', '54', '55', '56','57', '58', '59'];

export const SECONDS = ['00','01', '02', '03', '04', '05', '06', '07', '08','09', '10', '11', '12',
  '13', '14', '15', '16', '17', '18', '19', '20','21', '22', '23', '24',
  '25', '26', '27', '28', '29', '30', '31', '32','33', '34', '35', '36',
  '37', '38', '39', '40', '41', '42', '43', '44','45', '46', '47', '48',
  '49', '50', '51', '52', '53', '54', '55', '56','57', '58', '59'];
export const FIREWALL_DEFAULT_OPTIONS = ['allow', 'deny', 'reject'];

export const DEVICE_INFO_LABLE = {'cpu':'CPU', 'ram':'RAM', 'disk':'Disk', 'graphics_card':'GPU/VPU',
  'operating_system':'Operating System', 'scorer_release':'Release',
  'interface':'Interface', 'mac_address':'MAC Address', 'internet connectivity':'Internet Connectivity', 'log':'Log', 
  'uptime':'Uptime', 'last_boot':'Last Boot', 'system':'System', 'software':'Software',
  'network':'Network', 'device_uptime':'Device Uptime', 'root':'System', 'docker':'Docker', 'status': 'Status'};

export const STATUS_TYPES = { 'running': 'Running', 'failed': 'Failed', 'recovering': 'Reconciliation', 'disabled': 'Disabled', 'deleted': 'Deleted', 'desired': 'Reconciliation', 'paused': 'Paused' };

export const STATUS_PRIORITY = { 'failed': 5, 'recovering': 4, 'running': 3, 'disabled': 2, 'deleted': 1, 'paused': 0 };

export const SYSTEM_SERVICES_NAME_LIST = { 'edge_ui': 'Edge UI', 'edge_api': 'Edge API', 'config': 'Config Service', 'device_registration': 'Device Registration', 'mosquitto': 'Mosquitto', 'mqtt_publish': 'MQTT Publisher', 'mqtt_subscribe': 'MQTT Subscriber', 'status_collector': 'Status Collector', 'vpn_connector': 'VPN Connector', 'uploader': 'Cloud Uploader', 'rr_monitor': 'RR Monitor', 'stack_mgr': 'Stack Manager', 'stack_mon': 'Stack Monitor', 'rtmp_server': 'RTMP Server', 'ftp_server': 'FTP Server', 'cloud_config_sync': 'Cloud Config Sync', 'app_shadow_handler': 'Application Shadow Handler' };

export const AUTO_REFRESH_INTERVALS = [{'name': 'none', 'value': '0'},
  {'name': '15s', 'value': '15'},
  {'name': '30s', 'value': '30'},
  {'name': '45s', 'value': '45'},
  {'name': '60s', 'value': '60'}];
export const CS_AUTO_REFRESH_INTERVALS = [{'name': 'none', 'value': '0'},
  {'name': '3s', 'value': '3'},
  {'name': '5s', 'value': '5'},
  {'name': '10s', 'value': '10'},
  {'name': '15s', 'value': '15'},
  {'name': '30s', 'value': '30'},
  {'name': '45s', 'value': '45'},
  {'name': '60s', 'value': '60'}];
export const DEVICE_NAME_RULE = [{'name': 'Sequence (Default)', 'value': 'default'},
  {'name': 'By USB Port Location', 'value': 'by_position'},
  {'name': 'By Product Model', 'value': 'by_product'}];

export const LOG_LEVELS = [{'name': 'All', 'value': 'all'},
  {'name': 'info', 'value': 'info'},
  {'name': 'debug', 'value': 'debug'},
  {'name': 'error', 'value': 'error'},
  {'name': 'warning', 'value': 'warning'}];

export const MAP_SERVICES = [{'name': 'none', 'value': 'default'},
{'name': 'mapbox', 'value': 'map-box'}]

export const ADD_NAMING_RULES_INSTRUCTIONS = [
  'To get product name go to Help > Device Information > USB Devices > Name'
];
//export const ENV_LIST = [/* 'opencv', 'tensorFlow', 'pytorch', */ 'gstreamer', 'reactjs', /* 'caffe', 'pcl' */];
export const ENV_LIST = [{'name': 'Video Analysis (GStreamer: 1.16.2, OpenCV: 4.5.4)', 'value': 'gstreamer'}, {'name': 'Web Server (node.js:  v16.13.0, npm: 8.1.0)', 'value': 'reactjs'}];

export const POPUP_DEFAULT_STATUS = {
  'project_name': '',
  'enabled': true,
  'type': 'jupyter_lab',
  'environment' : 'gstreamer',
  'network_access': 'allow_inside_device',
  'env_vars': []
};
export const RDB_VERSION = [{'name': 'MySQL 5.7', 'value': 'mysql-5.7.34-0ubuntu0.18.04.1'}];
export const RDB_VERSION_RP4 = [{'name': 'MariaDB 10.3.27', 'value': 'mariadb-10.3.27-armv7l-debain10'}];

export const VPN_DEFAULT_VALUE = {
  'enabled': true,
  'host_name': '',
  'virtual_hub_name': '',
  'username': '',
  'password': '',
}

export const RDB_DEFAULT_VALUE = {
  'version': AD_HW_VPU === 'MMAL' ? 'mariadb-10.3.27-armv7l-debain10' : 'mysql-5.7.34-0ubuntu0.18.04.1',
  'instance_id': '',
  'nickname': '',
  'root_username': 'root',
  'root_password': '',
  'network_access': 'allow_inside_device',
  'query_cache_limit': '1',
  'query_cache_size':'1024',
  'query_cache_min_res_unit':'4096',
  'query_cache_type': '0',
  'query_cache_wlock_invalidate':'0',
  'host_cache_size':'1024',
  'metadata_locks_cache_size': '1024',
  'enable_adminer': true,
  'log_retention': '90',
  'enabled': true,
  'type': 'mysql',
  "metabase_rdb" : false
};

export const METABASE_DEFAULT_VALUE = {
  'instance_id': '',
  'nickname': '',
  'enabled': true,
  'type': 'metabase'
};

export const RDB_SERVICE_VALUE = {
  "enabled" : true,
  "host_cache_size" : "1024",
  "log_retention" : "90",
  "metadata_locks_cache_size" : "1024",
  "network_access" : "allow_inside_device",
  "port" : 3306,
  "query_cache_limit" : "1",
  "query_cache_min_res_unit" : "4096",
  "query_cache_size" : "1024",
  "query_cache_type" : "0",
  "query_cache_wlock_invalidate" : "0",
  "root_password" : "root",
  "root_username" : "root",
  "metabase_rdb" : true,
  "type" : "mysql",
  "version" : "mysql-5.7.34-0ubuntu0.18.04.1"
};

export const DNS_DEFAULT_VALUE = {
  'alternate_dns': '',
  'preffered_dns': '',
  'type': 'network'
};

export const WEB_REDIRECT = {
  'webredirect_id': '',
  'nickname': '',
  'target_url': ''
};

export const EMAIL_CLIENT_DEFAULT_VALUE = {
  'instance_id': '',
  'nickname': '',
  'aws_region': 'ap-northeast-1',
  'aws_access_key': '',
  'aws_secret_key': '',
  'days': '03',
  'hours': '00',
  'minutes': '00',
  'enabled': true,
  'type': 'email_sender'
}

export const EMAIL_DATA_DIR = 'email';

export const  TIMEZONE_DEFAULT_VALUE = {
  'ntp_enabled': true,
  'ntp_servers':'',
  'ntp_type': 'default',
  'fallback_ntp_servers': '',
  'auto_timezone': false,
  'timezone': 'Asia/Tokyo (UTC+09:00)',
  'type': 'time'
}

export const AWS_REGIONS = [
  { 'name': 'US East (Ohio)', 'value': 'us-east-2'},
  { 'name': 'US East (N. Virginia)', 'value': 'us-east-1'},
  { 'name': 'US West (N. California)', 'value': 'us-west-1'},
  { 'name': 'US West (Oregon)', 'value': 'us-west-2'},
  { 'name': 'Africa (Cape Town)', 'value': 'af-south-1'},
  { 'name': 'Asia Pacific (Hong Kong)', 'value': 'ap-east-1'},
  { 'name': 'Asia Pacific (Mumbai)', 'value': 'ap-south-1'},
  { 'name': 'Asia Pacific (Osaka)', 'value': 'ap-northeast-3'},
  { 'name': 'Asia Pacific (Seoul)', 'value': 'ap-northeast-2'},
  { 'name': 'Asia Pacific (Singapore)', 'value': 'ap-southeast-1'},
  { 'name': 'Asia Pacific (Sydney)', 'value': 'ap-southeast-2'},
  { 'name': 'Asia Pacific (Tokyo)', 'value': 'ap-northeast-1'},
  { 'name': 'Canada (Central)', 'value': 'ca-central-1'},
  { 'name': 'China (Beijing)', 'value': 'cn-north-1'},
  { 'name': 'China (Ningxia)', 'value': 'cn-northwest-1'},
  { 'name': 'Europe (Frankfurt)', 'value': 'eu-central-1'},
  { 'name': 'Europe (Ireland)', 'value': 'eu-west-1'},
  { 'name': 'Europe (London)', 'value': 'eu-west-2'},
  { 'name': 'Europe (Milan)', 'value': 'eu-south-1'},
  { 'name': 'Europe (Paris)', 'value': 'eu-west-3'},
  { 'name': 'Europe (Stockholm)', 'value': 'eu-north-1'},
  { 'name': 'Middle East (Bahrain)', 'value': 'me-south-1'},
  { 'name': 'South America (São Paulo)', 'value': 'sa-east-1'}
]

export const NETWORK_CONTAINER_SUFFIX = '_network';

export const NAMSPACES = {'SDK':'sdk', 'SYSTEM':'system', 'DEFAULT':'default'};

export const SDK_SERVICE_TYPES = {'RDB_MYSQL':'rdb-mysql', 'BI_METABASE': 'bi-metabase', 'EMAIL_CLIENT': 'email-client', 'PROJECT':'project'};

export const RDB_NETWORK_ACCESS = {'deny_all':'Deny all', 'allow_inside_device': 'Allow from this device', 'allow_all':'Allow all'};

export const PROJECT_NETWORK_ACCESS = {'deny_all':'Deny all', 'allow_inside_device': 'Allow from this device', 'allow_all':'Allow all'};

export const SDK_TYPES = {'rdb-mysql':'Relational Database', 'bi-metabase': 'Metabase', 'email-client': 'Email Client', 'project':'Project', 'extension': 'Extension'};

export const SDK_ACTIONS = {'ENABLE': 'enable', 'DISABLE': 'disable', 'CLEAR_OUTBOX':'clear_outbox', 'SEND_TEST_EMAIL': 'send_test_email'};

export const RDB_DEFAULT_HOST = '127.0.0.1';

export const RDB_DEFAULT_PORT = '3306';
